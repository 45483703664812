import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DetailProduct.scss';
import * as yup from 'yup';
import Config from '../../../Config';
import { ToastTypes } from '../../../models/ToastTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import { GetProduct, ProductSave, TransportProductType } from '../../../models/Product';
import useToast from '../../../hooks/useToast';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { SelectProductType, SelectProductTypeOption } from '../../forms/SelectProductType';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import Switch from 'react-switch';
import { SelectProductUnit } from '../../forms/SelectProductUnit';
import stringUtils from '../../../utils/stringUtils';
import { ErrorLine } from '../../../stories/dune/atoms/ErrorLine';
import { SelectVatRate } from '../../forms/SelectVatRates';
import { Title } from '../../../stories/dune/atoms/Title';
import {
  CustomerType,
  CustomerTypeProductPrice,
  CustomerTypeProductPriceSave,
  GetCustomerTypeProductPrice,
} from '../../../models/CustomerType';
import { ProductPrices } from '../../forms/ProductPrices';
import { roundNumber } from '../../../utils/number';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { t } from 'i18next';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { createProduct, updateProduct } from '../../../services/product';
import {
  updateCustomerTypeProductPrice,
  createCustomerTypeProductPrice,
  fetchCustomerTypeProductPrices,
} from '../../../services/customerTypeProductPrice';

interface GeneralProductProps {
  productId?: string;
  productData: GetProduct | undefined;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  mode: string;
}

interface IFormInputs {
  label: string;
  unit: {
    label: string;
    value: string;
  };
  weightByUnit: number;
  actualStockQuantity: number;
  externalReference: string;
  multiProductPriority: boolean;
  productType: {
    label: string;
    value: string;
  };
  taricCode: string;
  genericPrice: number;
  vatRate: {
    label: string;
    value: string;
  };
  repCode?: string;
  repUnit?: {
    label: string;
    value: string;
  };
  repValue?: number;
  isSubjectToTgap?: boolean;
}

const GeneralProduct = React.memo(({ productId, productData, onCancel, onConfirm, mode }: GeneralProductProps) => {
  const history = useNavigate();
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const isEditMode = mode === 'update';

  const [saveProduct, setSaveProduct] = useState<ProductSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [externalReference, setExternalReference] = useState<string>();
  const [label, setLabel] = useState<string>();

  const [productTypeOption, setProductTypeOption] = useState<SelectProductTypeOption | null>(
    productData?.productType
      ? {
          value: productData.productTypeId,
          label: productData.productType,
        }
      : null,
  );

  const [weightByUnit, setWeightByUnit] = useState<number>();
  const [multiProductPriority, setMultiProductPriority] = useState<boolean>(false);
  const [selectedOptionUnit, setSelectedOptionUnit] = useState<any>();
  const [selectedOptionVatRate, setSelectedOptionVatRate] = useState<any>();
  const [actualStockQuantity, setActualStockQuantity] = useState<number>();
  const [taricCode, setTaricCode] = useState<string>();
  const [genericPrice, setGenericPrice] = useState<number>();

  const [repCode, setRepCode] = useState<string>();
  const [selectedOptionRepUnit, setSelectedOptionRepUnit] = useState<any>();
  const [repValue, setRepValue] = useState<number>();
  const [isSubjectToTgap, setIsSubjectToTgap] = useState<boolean>();

  const [definePricesByCustomerType, setDefinePricesByCustomerType] = useState<boolean>(false);
  const [isTransport, setIsTransport] = useState<boolean>(false);

  const [productDataCustom, setProductDataCutom] = useState<DataCustomItem>({});

  const [customerTypeProductPrices, setCustomerTypeProductPrices] = useState<GetCustomerTypeProductPrice[]>([]);
  const [customerTypeProductPricesToSave, setCustomerTypeProductPricesToSave] = useState<string[]>([]);

  const schema = yup.object().shape({
    productType: yup.object().shape({
      value: yup.string().required(t('common.requiredProductType')),
      label: yup.string().required(t('common.requiredProductType')),
    }),
    label: yup.string().required(t('common.requiredLabel')).min(0),
    unit: yup.object().shape({
      value: yup.string().required(t('common.requiredProductUnit')),
      label: yup.string().required(t('common.requiredProductUnit')),
    }),
  });

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (productData) {
      // on initialise SaveProduct pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveProduct({
        ...productData,
      });

      setIsActive(productData?.isActive ?? true);

      setValue('label', productData?.label ?? '');
      setLabel(productData?.label);
      setValue('externalReference', productData?.externalReference ?? '');
      setExternalReference(productData?.externalReference);
      setValue('taricCode', productData?.taricCode ?? '');
      setTaricCode(productData?.taricCode);
      setValue('multiProductPriority', productData?.multiProductPriority ?? false);
      setMultiProductPriority(productData?.multiProductPriority ?? false);
      setValue('weightByUnit', productData?.weightByUnit ?? 0);
      setWeightByUnit(productData?.weightByUnit);
      setValue('actualStockQuantity', productData?.actualStockQuantity ?? 0);
      setActualStockQuantity(productData?.actualStockQuantity);
      setValue('genericPrice', productData?.genericPrice ?? 0);
      setGenericPrice(productData?.genericPrice);

      if (productData.productType) {
        setValue('productType', { value: productData?.productTypeId, label: productData?.productType });
        setIsTransport(TransportProductType === productData?.productTypeId);
      }

      if (productData.unit) {
        setValue('unit', {
          value: productData?.unit,
          label: productData?.unit ? t('unit.' + productData?.unit) : '',
        });
        setSelectedOptionUnit({
          value: productData?.unit,
          label: productData?.unit ? t('unit.' + productData?.unit) : '',
        });
      }

      if (productData.vatRate) {
        setValue('vatRate', {
          value: productData?.vatRate.id,
          label: productData?.vatRate.label,
        });
        setSelectedOptionVatRate({
          value: productData?.vatRate.id,
          label: productData?.vatRate.label + ' (' + productData?.vatRate.vatRate * 100 + '%)',
        });
      }

      setValue('isSubjectToTgap', productData?.isSubjectToTgap ?? false);
      setIsSubjectToTgap(productData?.isSubjectToTgap);
      setValue('repCode', productData?.repCode ?? undefined);
      setRepCode(productData?.repCode);
      setValue('repValue', productData?.repValue ?? 0);
      setRepValue(productData?.repValue);
      if (productData.repUnit) {
        setValue('repUnit', {
          value: productData?.repUnit,
          label: productData?.repUnit ? t('unit.' + productData?.repUnit) : '',
        });
        setSelectedOptionRepUnit({
          value: productData?.repUnit,
          label: productData?.repUnit ? t('unit.' + productData?.repUnit) : '',
        });
      }

      setProductDataCutom(productData?.dataCustom as any);

      fetchProductPrices(productData.id);
    }
  }, [productData]);

  const onSubmit = async () => {
    try {
      if (!token || !orgid) {
        return;
      }

      const payload: ProductSave = stringUtils.formatFieldsForPost({
        ...saveProduct,
        label,
        externalReference,
        weightByUnit,
        multiProductPriority,
        actualStockQuantity,
        taricCode,
        genericPrice,
        repCode,
        repValue,
        isSubjectToTgap,
        isActive,
      });

      const { uuid } = await (isEditMode && productId
        ? updateProduct({ orgid, accessToken: token, productId, payload })
        : createProduct({ orgid, accessToken: token, payload }));

      const promises: Promise<CustomerTypeProductPrice[]>[] = customerTypeProductPricesToSave.map((customerTypeId) => {
        const priceData = customerTypeProductPrices.find((x) => x.customerType.id === customerTypeId);

        const payload: CustomerTypeProductPriceSave = {
          price: priceData?.price ?? 0,
          customerTypeId,
          productId: uuid,
          vatRatId: '6566abec-95a2-4eae-b014-35add27108c7', // VAT rate always 0% because not managed
          isActive: priceData?.isActive ?? true,
        };

        return priceData?.id
          ? updateCustomerTypeProductPrice({ accessToken: token, orgid, priceId: priceData.id, payload })
          : createCustomerTypeProductPrice({ accessToken: token, orgid, payload });
      });

      await Promise.all(promises);
      onConfirm(uuid);

      addToast(t('common.product' + (mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  };

  const fetchProductPrices = async (id: string) => {
    if (!token || !orgid || !isEditMode) {
      return;
    }

    try {
      const prices = await fetchCustomerTypeProductPrices({
        accessToken: token,
        orgid,
        productId: id,
        customerTypeId: undefined,
      });

      setCustomerTypeProductPrices(prices as GetCustomerTypeProductPrice[]);
      const hasActiveCustomerTypeProductPrice = prices.length > 0 && prices.some((price) => price.isActive);

      if (hasActiveCustomerTypeProductPrice) {
        setDefinePricesByCustomerType(true);
      }
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  };

  function handleProductPriceChanged(customerType: CustomerType, newPrice: number) {
    if (customerTypeProductPrices) {
      // edit existing price
      const newPrices = customerTypeProductPrices.map((x) => {
        if (x.customerType.id === customerType.id) {
          return { ...x, price: newPrice };
        }
        return x;
      });

      // add new price if not exists
      if (!newPrices.find((x) => x.customerType.id === customerType.id)) {
        newPrices.push({
          id: '',
          customerType: customerType,
          product: {
            id: productId ?? '',
            label: saveProduct?.label ?? '',
            unit: saveProduct?.unit ?? '',
          },
          price: newPrice,
          vatRate: { label: '', vatRate: 0 },
          isActive: true,
        });
      }

      if (customerTypeProductPricesToSave.indexOf(customerType.id) === -1)
        setCustomerTypeProductPricesToSave([...customerTypeProductPricesToSave, customerType.id]);

      setCustomerTypeProductPrices(newPrices);
    }
  }

  function handleDefinePricesByCustomerType(checked: boolean) {
    setDefinePricesByCustomerType(checked);

    // disable all prices if unchecked, enable all prices if checked
    if (customerTypeProductPrices && customerTypeProductPrices.length > 0) {
      let pricesToSave = customerTypeProductPricesToSave;
      const disabledPrices = customerTypeProductPrices.map((x) => {
        if (x.isActive !== checked) {
          if (
            customerTypeProductPricesToSave.indexOf(x.customerType.id) === -1 &&
            pricesToSave.indexOf(x.customerType.id) === -1
          ) {
            pricesToSave = [...pricesToSave, x.customerType.id];
          }
          return { ...x, isActive: checked };
        }
        return x;
      });
      setCustomerTypeProductPrices(disabledPrices);
      setCustomerTypeProductPricesToSave(pricesToSave);
    }
  }

  return !productData && mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.generalInformations')} type='title1' />

            {mode === 'update' && (
              <FormRow align='right'>
                <h3 className='base2' style={{ margin: '0px 10px' }}>
                  {t('common.active')}
                </h3>
                <Switch
                  className='base2'
                  type='text'
                  onChange={function () {
                    setIsActive((prevValue) => !prevValue);
                  }}
                  checked={isActive ?? false}
                  onColor={'#2a85ff'}
                />
              </FormRow>
            )}
          </FormRow>
          <FormRow>
            <Input
              {...register('label')}
              error={errors.label?.message ?? ''}
              label={t('common.label')}
              type='text'
              value={label ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('label', e.toString());
                setLabel(e.toString());
              }}
            />

            <SelectProductType
              titleSize='normal'
              register={register}
              registerName='productType'
              setValue={setValue}
              error={errors.productType?.value?.message ?? errors.productType?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectProductTypeOption) => {
                if (e && e.value) {
                  setValue('productType', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, productTypeId: e?.value };
                  });

                  setIsTransport(TransportProductType === e?.value);
                } else {
                  setValue('productType', {
                    value: '',
                    label: '',
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, productTypeId: undefined };
                  });

                  setIsTransport(false);
                }
              }}
              forceSelectedOption={productTypeOption}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('externalReference')}
              error={errors.externalReference?.message ?? ''}
              label={t('common.yourReference')}
              type='text'
              value={externalReference ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('externalReference', e.toString());
                setExternalReference(e.toString());
              }}
            />
            <SelectProductUnit
              titleSize='normal'
              titleOverride={t('common.unit')}
              register={register}
              registerName='unit'
              setValue={setValue}
              error={errors.unit?.value?.message ?? errors.unit?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: any) => {
                if (e && e.value) {
                  setValue('unit', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, unit: e?.value };
                  });

                  setSelectedOptionUnit(e);
                } else {
                  setValue('unit', {
                    value: '',
                    label: '',
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, unit: undefined };
                  });
                }
              }}
              forceSelectedOption={selectedOptionUnit}
            />
          </FormRow>
          {!isTransport && (
            <FormRow>
              <Input
                {...register('actualStockQuantity')}
                error={errors.actualStockQuantity?.message ?? ''}
                label={t('common.actualStockQuantity')}
                type='number'
                value={actualStockQuantity ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (newValue: string | number): void {
                  setValue('actualStockQuantity', newValue as number);
                  setActualStockQuantity(newValue as number);
                }}
              />
              {selectedOptionUnit?.value !== 'kg' && selectedOptionUnit?.value !== 'ton' && (
                <Input
                  {...register('weightByUnit')}
                  error={errors.weightByUnit?.message ?? ''}
                  label={t('common.weightPerUnitDesc')}
                  hoverDescription={t('common.weightPerUnitDesc')}
                  type='number'
                  value={weightByUnit ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (newValue: string | number): void {
                    setValue('weightByUnit', newValue as number);
                    setWeightByUnit(newValue as number);
                  }}
                />
              )}
            </FormRow>
          )}
        </div>

        <div className='form-section'>
          <FormRow>
            <Title label={t('common.prices')} type='title1' />
          </FormRow>

          <FormRow>
            <Input
              {...register('genericPrice')}
              error={errors.genericPrice?.message ?? ''}
              label={t('common.genericPrice') + ' / ' + selectedOptionUnit?.label}
              hoverDescription={t('common.genericPriceDesc')}
              type='number'
              value={roundNumber(genericPrice ?? 0, 2)}
              placeholder=''
              disabled={false}
              onChange={function (newValue: string | number): void {
                setValue('genericPrice', roundNumber((newValue as number) ?? 0, 2));
                setGenericPrice(roundNumber((newValue as number) ?? 0, 2));
              }}
              suffix={'€'}
              number={{ min: 0, max: 100000, step: 0.1 }}
            />
            <SelectVatRate
              titleSize='normal'
              titleOverride={t('common.defaultVatRate')}
              hoverDescription={t('common.defaultVatRateDesc')}
              register={register}
              registerName='vatRate'
              setValue={setValue}
              error={errors.vatRate?.value?.message ?? errors.vatRate?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: any) => {
                if (e && e.value) {
                  setValue('vatRate', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, vatRateId: e?.value };
                  });
                } else {
                  setValue('vatRate', {
                    value: '',
                    label: '',
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, vatRateId: undefined };
                  });
                }
              }}
              forceSelectedOption={selectedOptionVatRate}
              dependsOnSearchParent={false}
            />
          </FormRow>
          <FormRow>
            {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
            <div className='panelInput' title={t('common.definePricesByCustomerTypeDesc')}>
              <Switch
                className='base2'
                type='text'
                checked={definePricesByCustomerType ?? false}
                onChange={(checked: boolean) => handleDefinePricesByCustomerType(checked)}
                onColor={'#2a85ff'}
              />
              <h3 className='base2' style={{ margin: '0px 10px' }}>
                {t('common.definePricesByCustomerType')}
              </h3>
            </div>
          </FormRow>
          {definePricesByCustomerType && (
            <FormRow>
              <ProductPrices
                product={{
                  id: productId ?? '',
                  label: label ?? '',
                  unit: selectedOptionUnit?.label ?? '',
                }}
                priceData={customerTypeProductPrices}
                onChangePrice={handleProductPriceChanged}
              />
            </FormRow>
          )}
        </div>

        {!isTransport && (
          <>
            <div className='form-section'>
              <FormRow>
                <Title label={t('common.taxes')} type='title1' />
              </FormRow>
              <FormRow alignVert='center'>
                <Input
                  {...register('repCode')}
                  error={errors.repCode?.message ?? ''}
                  label={t('common.repCode')}
                  hoverDescription={t('common.repCodeDesc')}
                  type='text'
                  value={repCode ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (e): void {
                    setValue('repCode', e.toString());
                    setRepCode(e.toString());
                  }}
                />
                <SelectProductUnit
                  titleSize='normal'
                  titleOverride={t('common.repUnit')}
                  hoverDescription={t('common.repUnitDesc')}
                  register={register}
                  registerName='repUnit'
                  setValue={setValue}
                  error={errors.repUnit?.value?.message ?? errors.repUnit?.label?.message ?? ''}
                  isSelectable={true}
                  selectedOptionChanged={(e: any) => {
                    if (e && e.value) {
                      setValue('repUnit', {
                        value: e?.value,
                        label: e?.label,
                      });

                      setSaveProduct((prevValue) => {
                        return { ...prevValue, repUnit: e?.value };
                      });

                      setSelectedOptionRepUnit(e);
                    } else {
                      setValue('repUnit', {
                        value: '',
                        label: '',
                      });

                      setSaveProduct((prevValue) => {
                        return { ...prevValue, repUnit: undefined };
                      });
                    }
                  }}
                  forceSelectedOption={selectedOptionRepUnit}
                />
                <Input
                  {...register('repValue')}
                  error={errors.repValue?.message ?? ''}
                  label={t('common.repValue') + ' / ' + selectedOptionUnit?.label}
                  hoverDescription={t('common.repValueDesc')}
                  type='number'
                  value={roundNumber(repValue ?? 0, 2)}
                  placeholder=''
                  disabled={false}
                  onChange={function (newValue: string | number): void {
                    setValue('repValue', roundNumber((newValue as number) ?? 0, 2));
                    setRepValue(roundNumber((newValue as number) ?? 0, 2));
                  }}
                  suffix={'€'}
                  number={{ min: 0, max: 100, step: 0.1 }}
                />
              </FormRow>
              <FormRow align='left'>
                <div
                  title={t('common.repProductListLinkDesc') + ' Écominéro'}
                  style={{
                    margin: '10px 20px',
                  }}
                >
                  <a
                    href={'https://www.ecominero.fr/download/2811/?tmstv=1706727945'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t('common.repProductListLink') + ' Écominéro'}
                  </a>
                </div>

                <div
                  title={t('common.repProductListLinkDesc') + ' Valobat'}
                  style={{
                    margin: '10px 20px',
                  }}
                >
                  <a
                    href={'https://www.valobat.fr/wp-content/uploads/2024/03/Valobat_Codification_PMCB_20240313-1.xlsx'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t('common.repProductListLink') + ' Valobat'}
                  </a>
                </div>
              </FormRow>
              <FormRow>
                {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
                <div className='panelInput' title={t('common.isSubjectToTgapDesc')}>
                  <Switch
                    className='base2'
                    type='text'
                    checked={isSubjectToTgap ?? false}
                    onChange={(checked: boolean) => setIsSubjectToTgap(checked)}
                    onColor={'#2a85ff'}
                  />
                  <h3 className='base2' style={{ margin: '0px 10px' }}>
                    {t('common.isSubjectToTgap')}
                  </h3>
                </div>
              </FormRow>
            </div>

            <div className='form-section'>
              <FormRow>
                <Title label={t('common.other')} type='title1' />
              </FormRow>
              <FormRow>
                <Input
                  {...register('taricCode')}
                  error={errors.taricCode?.message ?? ''}
                  label={t('common.taricCode')}
                  hoverDescription={t('common.taricCodeDesc')}
                  toolTipMode={'question'}
                  type='text'
                  value={taricCode ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (newValue: string | number): void {
                    setValue('taricCode', newValue as string);
                    setTaricCode(newValue as string);
                  }}
                />
              </FormRow>
              <FormRow>
                {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
                <div className='panelInput' title={t('common.multiProductPriorityDesc')}>
                  <h3 className='base2' style={{ margin: '0px 10px' }}>
                    {t('common.multiProductPriority')}
                  </h3>
                  <Switch
                    className='base2'
                    type='text'
                    checked={multiProductPriority ?? false}
                    onChange={(checked: boolean) => {
                      setValue('multiProductPriority', checked);
                      setMultiProductPriority(checked);
                    }}
                    onColor={'#2a85ff'}
                  />
                </div>
              </FormRow>
              <ErrorLine label={errors.multiProductPriority?.message ?? ''} align='left' type='label' />
            </div>
          </>
        )}

        <div className='form-section'>
          <BuildDataCustom
            dataCustomType={'Product'}
            dataCustom={productDataCustom}
            dataCustomUpdated={(data: DataCustomItem) => {
              setSaveProduct((prevValue) => {
                return { ...prevValue, dataCustom: data };
              });
            }}
          />
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralProduct.displayName = 'GeneralProduct';
export default GeneralProduct;
