import { createInstance } from '../Config';
import {
  GetVehicle,
  GetVehicleInfo,
  GetVehicleParams,
  GetVehiclePayload,
  VehicleInfoSave,
  VehicleSave,
} from '../models/Vehicle';

const instance = createInstance({ isExtranet: true });

export const fetchVehicles = async ({
  accessToken,
  orgid,
  params,
  payload,
}: {
  accessToken: string;
  orgid: string;
  params: GetVehicleParams;
  payload: GetVehiclePayload;
}): Promise<{ items: GetVehicle[]; total: number }> => {
  const response = await instance.post(
    `${orgid}/vehicle/get` + (payload.search ? `?search=${payload.search}&isActive=${payload.isActive}` : ''),
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    },
  );

  return { items: response.data.content.items, total: response.data.meta.total };
};

export const fetchVehicleDetails = async ({
  accessToken,
  orgid,
  vehicleId,
}: {
  accessToken: string;
  orgid: string;
  vehicleId: string;
}): Promise<GetVehicle> => {
  const response = await instance.get(`${orgid}/vehicle/detail/${vehicleId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data.content;
};

export const fetchVehicleDetailsByRegistrationPlate = async ({
  accessToken,
  orgid,
  registrationPlate,
}: {
  accessToken: string;
  orgid: string;
  registrationPlate: string;
}): Promise<GetVehicleInfo> => {
  const response = await instance.get(`${orgid}/vehicle-info/get-by-registration-plate/${registrationPlate}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data.content.items;
};

export const changeVehicleStatus = async ({
  accessToken,
  orgid,
  vehicleId,
}: {
  accessToken: string;
  orgid: string;
  vehicleId: string;
}): Promise<void> => {
  await instance.get(`${orgid}/vehicle/change-activation/${vehicleId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const createVehicle = async ({
  accessToken,
  orgid,
  payload,
}: {
  accessToken: string;
  orgid: string;
  payload: VehicleSave;
}): Promise<{ uuid: string }> => {
  const response = await instance.post(
    `${orgid}/vehicle/add`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};

export const updateVehicle = async ({
  accessToken,
  orgid,
  vehicleId,
  payload,
}: {
  accessToken: string;
  orgid: string;
  vehicleId: string;
  payload: VehicleSave;
}): Promise<void> => {
  await instance.post(
    `${orgid}/vehicle/edit/${vehicleId}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const createVehicleInfo = async ({
  accessToken,
  orgid,
  payload,
}: {
  accessToken: string;
  orgid: string;
  payload: VehicleInfoSave;
}): Promise<{ uuid: string }> => {
  const response = await instance.post(
    `${orgid}/vehicle-info/add`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};

export const updateVehicleInfo = async ({
  accessToken,
  orgid,
  vehicleInfoId,
  payload,
}: {
  accessToken: string;
  orgid: string;
  vehicleInfoId: string;
  payload: VehicleInfoSave;
}): Promise<void> => {
  await instance.post(
    `${orgid}/vehicle-info/edit/${vehicleInfoId}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};
