import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../styles/tableStyles';
import axios from 'axios';
import Config from '../Config';
import './Voyages.scss';
import '../styles/table.scss';
import { Link, useNavigate } from 'react-router-dom';
import { ToastTypes } from '../models/ToastTypes';
import useToast from '../hooks/useToast';
import Menu from './Menu';
import { Trip } from '@dune-manager/backend-core/dist/models';
import moment from 'moment-timezone';
import Select from 'react-select';
import TabFilter from './lists/TabFilter';
import { TripStatusTranslate, TripStatusTranslateView } from '../models';
import fileDownload from 'js-file-download';
import eventBus from '../utils/EventBus';
import { ZoneType } from '@dune-manager/backend-core/dist/models/zone';
import { DateRange, DayPicker } from 'react-day-picker';
import { fr, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Language } from '../i18n';
import { GlobalParameter } from '../models/GlobalParameter';
import { SelectCustomer } from './forms/SelectCustomer';
import { Head } from '../stories/dune/molecules/Head';
import { Icon } from '../stories/dune/atoms/Icon';
import { usePagination } from '../hooks/usePagination';
import { useTabFilters } from '../hooks/useTabFilters';
import { GenericTripType } from '../models/TripType';
import { TripFilters, TripStatus } from '../models/Trip';

interface reportData {
  status: string;
  count: number;
}

const Trips = React.memo(() => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem('language') ?? Language.FR;

  const { addToast } = useToast();
  const { currentPage, perPage, paginationSetValues, changePaginationTabValue } = usePagination();
  const defaultTab = TripFilters.IN_PROGRESS;
  const { filtersTab } = useTabFilters(defaultTab.toString());

  const [localPicker, setlocalPicker] = useState(fr);
  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState('tripDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const history = useNavigate();
  const [isCloseReport, setIsCloseReport] = useState(true);

  const [optionsPeriod, setOptionsPeriod] = useState<any[]>([]);
  const [optionSelectedPeriod, setOptionSelectedPeriod] = useState<any>({ label: t('common.today'), value: 'day' });
  const [totalTrip, setTotalTrip] = useState(0);
  const [inProgessTrip, setInProgressTrip] = useState(0);
  const [terminatedTrip, setTerminatedTrip] = useState(0);
  const [optionsZones, setOptionsZones] = useState<any[]>([]);
  const [optionsZonesProcess, setOptionsZonesProcess] = useState<any[]>([]);
  const [tripFilter, setTripFilter] = useState<any>({});
  const [filteredItems, setFilterereditems] = useState<Trip[]>([]);
  const [filterText, setFilterText] = useState('');
  const [isHiddenFilter, setIsHiddenFilter] = useState(false);

  // Variables pour filtres :

  const [optionFilteredProcessingZone, setOptionFilteredProcessingZone] = useState<any>(null);
  const [optionFilteredCurrentZone, setOptionFilteredCurrentZone] = useState<any>(null);
  const [filteredDateRange, setFilteredDateRange] = useState<DateRange | undefined>(undefined);
  const [filterHasBeenRefused, setFilterHasBeenRefused] = useState<boolean>(false);
  const [toggleDatePicker, setToggleDatePicker] = useState(true);
  const [checkboxStatusDatePicker, setCheckboxStatusDatePicker] = useState(false);
  const [toggleSelectTripType, setToggleSelectTripType] = useState(true);
  const [checkboxStatusTripType, setCheckboxStatusTripType] = useState(false);
  const [toggleSelectCustomer, setToggleSelectCustomer] = useState(true);
  const [checkboxStatusCustomer, setCheckboxStatusCustomer] = useState(false);
  const [toggleSelectProcessingZone, setToggleSelectProcessingZone] = useState(true);
  const [checkboxStatusProcessingZone, setCheckboxStatusProcessingZone] = useState(false);
  const [toggleSelectCurrentZone, setToggleSelectCurrentZone] = useState(true);
  const [checkboxStatusCurrentZone, setCheckboxStatusCurrentZone] = useState(false);
  const [optionsTripTypes, setOptionsTripTypes] = useState<any[]>([]);
  const [optionSelectedTripType, setOptionSelectedTripType] = useState<any>();
  const [optionSelectedCustomer, setOptionSelectedCustomer] = useState<any>();
  const [otherFilter, setOtherFilter] = useState<any>(
    (localStorage.getItem('otherFilterTrip') ?? '') !== ''
      ? JSON.parse(localStorage.getItem('otherFilterTrip') as string)
      : {},
  );

  const [searchRegistrationPlate, setSearchRegistrationPlate] = useState<string | undefined>(undefined);
  const [checkboxStatusRegistrationPlate, setCheckboxStatusRegistrationPlate] = useState<boolean>(false);
  const [toggleInputregistrationPlate, setToggleInputregistrationPlate] = useState(true);

  const [searchCustomerOrderNumber, setSearchCustomerOrderNumber] = useState<string | undefined>(undefined);
  const [checkboxStatusCustomerOrderNumber, setCheckboxStatusCustomerOrderNumber] = useState<boolean>(false);
  const [toggleInputCustomerOrderNumber, setToggleInputCustomerOrderNumber] = useState(true);

  const [searchTripNumber, setSearchTripNumber] = useState<string | undefined>(undefined);
  const [checkboxStatusTripNumber, setCheckboxStatusTripNumber] = useState<boolean>(false);
  const [toggleInputTripNumber, setToggleInputTripNumber] = useState(true);

  const [searchExternalReference, setSearchExternalReference] = useState<string | undefined>(undefined);
  const [checkboxStatusExternalReference, setCheckboxStatusExternalReference] = useState<boolean>(false);
  const [toggleInputExternalReference, setToggleInputExternalReference] = useState(true);

  const tabFilterOptions = [
    { tabFilter: 'Tous', tabLabelTranslate: t('common.all') },
    { tabFilter: TripFilters.WAITING.toString(), tabLabelTranslate: t('common.waiting') },
    { tabFilter: TripFilters.IN_PROGRESS.toString(), tabLabelTranslate: t('common.inProgress') },
    { tabFilter: TripFilters.FINISHED.toString(), tabLabelTranslate: t('common.finished') },
    { tabFilter: TripFilters.EXITED.toString(), tabLabelTranslate: t('common.exited') },
  ];

  const onChangeRegistrationPlateCheckbox = (e: any) => {
    setCheckboxStatusRegistrationPlate(e.target.checked);
    setSearchRegistrationPlate(undefined);
    setToggleInputregistrationPlate(!toggleInputregistrationPlate);
  };

  const onChangeRegistrationPlateInput = (e: any) => {
    setSearchRegistrationPlate(e.target.value);
  };

  const onChangeExternalReferenceCheckbox = (e: any) => {
    setCheckboxStatusExternalReference(e.target.checked);
    setSearchExternalReference(undefined);
    setToggleInputExternalReference(!toggleInputExternalReference);
  };

  const onChangeExternalReferenceInput = (e: any) => {
    setSearchExternalReference(e.target.value);
  };

  const onChangeTripNumberCheckbox = (e: any) => {
    setCheckboxStatusTripNumber(e.target.checked);
    setSearchTripNumber(undefined);
    setToggleInputTripNumber(!toggleInputTripNumber);
  };

  const onChangeTripNumberInput = (e: any) => {
    setSearchTripNumber(e.target.value);
  };

  const onChangeCustomerOrderNumberCheckbox = (e: any) => {
    setCheckboxStatusCustomerOrderNumber(e.target.checked);
    setSearchCustomerOrderNumber(undefined);
    setToggleInputCustomerOrderNumber(!toggleInputCustomerOrderNumber);
  };

  const onChangeCustomerOrderNumberInput = (e: any) => {
    setSearchCustomerOrderNumber(e.target.value);
  };

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  useEffect(() => {
    setOptionsPeriod([
      { value: 'day', label: t('common.today') },
      { value: 'week', label: t('common.thisWeek') },
      { value: 'last-week', label: t('common.lastWeek') },
      { value: 'month', label: t('common.thisMonth') },
      { value: 'last-month', label: t('common.lastMonth') },
      { value: 'year', label: t('common.thisYear') },
    ]);
    setFilterText('');
    fetchTrip(currentPage, perPage, filterText, sortColumn, sortDirection, filtersTab, otherFilter);
    fetchZone();
    fetchZoneProcess();

    switch (currentLanguage) {
      case Language.FR:
        setlocalPicker(fr);
        break;
      case Language.EN:
        setlocalPicker(enUS);
        break;
      default:
        setlocalPicker(fr);
        break;
    }

    if (otherFilter) {
      if (otherFilter.dateRange) {
        setFilteredDateRange(otherFilter.dateRange);
      }
      if (otherFilter.tripType) {
        setOptionSelectedTripType(otherFilter.tripType);
      }
      if (otherFilter.customer) {
        setOptionSelectedCustomer({
          value: otherFilter.customer.id,
          label: otherFilter.customer.label,
          data: otherFilter.customer,
        });
      }
      if (otherFilter.processingZone) {
        setOptionFilteredProcessingZone(otherFilter.processingZone);
      }
      if (otherFilter.currentZone) {
        setOptionFilteredCurrentZone(otherFilter.currentZone);
      }
      if (otherFilter.registrationPlate) {
        setCheckboxStatusRegistrationPlate(true);
        setSearchRegistrationPlate(otherFilter.registrationPlate);
        setToggleInputregistrationPlate(false);
      }
      if (otherFilter.customerOrderNumber) {
        setCheckboxStatusCustomerOrderNumber(true);
        setSearchCustomerOrderNumber(otherFilter.customerOrderNumber);
        setToggleInputCustomerOrderNumber(false);
      }
      if (otherFilter.tripNumber) {
        setCheckboxStatusTripNumber(true);
        setSearchTripNumber(otherFilter.tripNumber);
        setToggleInputTripNumber(false);
      }
      if (otherFilter.externalReference) {
        setCheckboxStatusExternalReference(true);
        setSearchExternalReference(otherFilter.externalReference);
        setToggleInputExternalReference(false);
      }
      if (otherFilter.hasBeenRefused) {
        setFilterHasBeenRefused(true);
      }
    }

    const handleUpdateTrip = (data: any) => {
      if ((data?.detail?.message ?? null) !== null) {
        addToast(data.detail.message, ToastTypes.success);
      }
    };

    eventBus.on('update_trip', handleUpdateTrip);
    return () => {
      // cleanup events on unmount
      eventBus.remove('update_trip', handleUpdateTrip);
    };
  }, []);

  const fetchTripType = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = orgid + '/trip-type/get?page=' + 1 + '&limit=' + 100;

    const tripTypes: any[] = [];

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { isActive: true, genericTripType: [GenericTripType.LOAD, GenericTripType.UNLOAD] },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        if (res.data.content.items && res.data.content.items.length > 0) {
          for (const item of res.data.content.items) {
            tripTypes.push({ label: item.label, value: item.id });
          }

          setOptionsTripTypes(tripTypes);
        } else setOptionsTripTypes([]);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const fetchZone = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = orgid + '/zone/get?page=' + 1 + '&limit=' + 100;

    const zones: any[] = [];

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { isActive: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        if (res.data.content.items && res.data.content.items.length > 0) {
          for (const item of res.data.content.items) {
            zones.push({ label: item.label, value: item.id });
          }
          setOptionsZones(zones);
        } else setOptionsZones([]);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const fetchZoneProcess = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = orgid + '/zone/get?page=' + 1 + '&limit=' + 100;

    const zones: any[] = [];

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { isActive: true, zoneType: ZoneType.PROCESS },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        if (res.data.content.items && res.data.content.items.length > 0) {
          for (const item of res.data.content.items) {
            zones.push({ label: item.label, value: item.id });
          }
          setOptionsZonesProcess(zones);
        } else setOptionsZonesProcess([]);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          'Une erreur est intervenue lors de la récupération des zones. Merci de réessayer. code : ' +
            (error?.response?.data?.code ?? error?.response?.status),
          ToastTypes.error,
        );
      });
  };

  const PopulatedOptionsZone = (idTrip: string): any[] => {
    const options: any[] = [];
    if (optionsZones && optionsZones.length > 0) {
      for (const option of optionsZones) {
        options.push({
          id: idTrip,
          value: option.value,
          label: option.label,
        });
      }
    }

    return options;
  };

  const PopulatedOptionsZoneProcess = (idTrip: string): any[] => {
    const options: any[] = [];
    if (optionsZonesProcess && optionsZonesProcess.length > 0) {
      for (const option of optionsZonesProcess) {
        options.push({
          id: idTrip,
          value: option.value,
          label: option.label,
        });
      }
    }

    return options;
  };

  const PopulatedOptionsStatus = (idTrip: string): any[] => {
    const options: any[] = [];
    for (const [key, value] of Object.entries(TripStatusTranslateView)) {
      options.push({
        id: idTrip,
        value: key,
        label: value,
      });
    }
    return options;
  };

  const seeDetails = (row: Trip) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'trips';
    return `/${url}/${row.id}/update`;
  };

  const updatePeriod = (item: any) => {
    setOptionSelectedPeriod(item);
    fetchReport(item.value);
  };

  const toggleReport = () => {
    setIsCloseReport(!isCloseReport);
    fetchReport(optionSelectedPeriod.value);
  };

  const fetchTrip = (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    otherFilters: any = {},
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/trip/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&search=' +
      newFilterText +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection;

    setIsLoading(true);
    setFilterereditems([]);

    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    const dataToSend: SearchStatus = { status: TabFilter && TabFilter.length > 0 ? TabFilter : undefined };

    setTripFilter(dataToSend);

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...dataToSend, ...otherFilters },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        setFilterereditems(res.data.content.items);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const fetchReport = (period: string) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/trip/report?period=' + period;

    axios
      .get(Config.getApiExtranetUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        if (res.data.content.items.length > 0) {
          reportProcessing(res.data.content.items);
        } else {
          setTerminatedTrip(0);
          setInProgressTrip(0);
          setTotalTrip(0);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          'Une erreur est intervenue lors de la récupération des données de reporting. Merci de réessayer. code : ' +
            (error?.response?.data?.code ?? error?.response?.status),
          ToastTypes.error,
        );
      });
  };

  const reportProcessing = (data: reportData[]) => {
    let terminated = 0;
    let inProgress = 0;
    for (const item of data) {
      switch (item.status) {
        case TripStatus.EXITED:
          terminated += item.count;
          break;
        case TripStatus.CANCELED:
          break;
        case TripStatus.DELETED:
          break;
        case TripStatus.REFUSED:
          break;
        default:
          inProgress += item.count;
          break;
      }
    }

    setTerminatedTrip(terminated);
    setInProgressTrip(inProgress);
    setTotalTrip(terminated + inProgress);
  };

  const handlePageChange = (pageNumber: number) => {
    paginationSetValues(pageNumber, perPage);
    fetchTrip(pageNumber, perPage, filterText, sortColumn, sortDirection, filtersTab, otherFilter);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    paginationSetValues(1, newPerPage);
    fetchTrip(currentPage, newPerPage, filterText, sortColumn, sortDirection, filtersTab, otherFilter);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchTrip(1, perPage, filterText, data.id, newSortOrder, [], otherFilter);
  };

  const handleSetFiltersStatus = (newfilter: string[], tabOption: string) => {
    const { page: newPage, perPage: newPerPage } = changePaginationTabValue(tabOption);
    fetchTrip(newPage, newPerPage, filterText, sortColumn, sortDirection, newfilter, otherFilter);
  };

  const customStyles = (color = '#FCFCFC') => {
    return {
      control: (base: any) => ({
        ...base,
        background: color,
        // Overwrittes the different states of border
        borderColor: color,
      }),
      menuPortal: (base: any) => ({
        ...base,
      }),
      singleValue: (base: any) => ({
        ...base,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      }),
    };
  };

  const customStylesSelectFilter = (color = '#f4f4f4') => {
    return {
      control: (base: any) => ({
        ...base,
        background: color,
        // Overwrittes the different states of border
        borderColor: color,
        color: '#333b59',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
      }),
      menuPortal: (base: any) => ({
        ...base,
        color: '#333b59',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
      }),
      menuList: (base: any) => ({
        ...base,
        color: '#333b59',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
      }),
    };
  };

  const updateProcessingZone = (option: any) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = `${orgid}/trip/${option.id}/change-processing-zone`;

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { processingZoneId: option.value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async () => {
        fetchTrip(currentPage, perPage, filterText, sortColumn, sortDirection, filtersTab, otherFilter);
        addToast('Mise à jour de la zone affectée', ToastTypes.success);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          'Une erreur est intervenue lors de la mise à jour de la zone affectée. Merci de réessayer. code : ' +
            (error?.response?.data?.code ?? error?.response?.status),
          ToastTypes.error,
        );
      });
  };

  const updateDestination = (option: any) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = `${orgid}/trip/${option.id}/change-destination`;

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { destinationZoneId: option.value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async () => {
        fetchTrip(currentPage, perPage, filterText, sortColumn, sortDirection, filtersTab, otherFilter);
        addToast('Mise à jour de la destination', ToastTypes.success);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          'Une erreur est survenue lors de la mise à jour de la destination. Merci de réessayer. code : ' +
            (error?.response?.data?.code ?? error?.response?.status),
          ToastTypes.error,
        );
      });
  };

  const updateStatus = (option: any) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = `${orgid}/trip/${option.id}/change-status`;

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { status: option.value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async () => {
        fetchTrip(currentPage, perPage, filterText, sortColumn, sortDirection, filtersTab, otherFilter);
        addToast('Mise à jour du statut', ToastTypes.success);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          'Une erreur est survenue lors de la mise à jour du statut. Merci de réessayer. code : ' +
            (error?.response?.data?.code ?? error?.response?.status),
          ToastTypes.error,
        );
      });
  };

  const toggleFilter = () => {
    setIsHiddenFilter(!isHiddenFilter);
    setToggleDatePicker(true);
    setToggleSelectCurrentZone(true);
    setToggleSelectProcessingZone(true);
    setToggleSelectTripType(true);
    setToggleInputregistrationPlate(true);
    setToggleInputCustomerOrderNumber(true);
    setToggleInputTripNumber(true);
    setToggleInputExternalReference(true);
    setCheckboxStatusCurrentZone(false);
    setCheckboxStatusProcessingZone(false);
    setCheckboxStatusTripType(false);
    setCheckboxStatusDatePicker(false);
    setCheckboxStatusRegistrationPlate(false);
    setCheckboxStatusTripNumber(false);
    setCheckboxStatusCustomerOrderNumber(false);
    setCheckboxStatusExternalReference(false);
    setOptionFilteredProcessingZone(null);
    setOptionFilteredCurrentZone(null);
    setOptionSelectedTripType(null);
    setOptionSelectedCustomer(null);
    setFilteredDateRange(undefined);
    setFilterHasBeenRefused(false);
    setOtherFilter(null);
    localStorage.setItem('otherFilterTrip', '');
    setSearchRegistrationPlate(undefined);
    setSearchTripNumber(undefined);
    setSearchCustomerOrderNumber(undefined);
    setSearchExternalReference(undefined);
    paginationSetValues(1, perPage);

    fetchTrip(1, perPage);
  };

  const openCloseAdvancedFilter = () => {
    setIsHiddenFilter(!isHiddenFilter);
  };

  const searchWithAdvancedFilters = () => {
    const tripType = {} as any;
    const customer = {} as any;
    const currentZone = {} as any;
    const processingZone = {} as any;
    const dateRange = {} as any;
    const tripOptions = {} as any;
    const hasBeenRefused = {} as any;

    if (optionSelectedTripType) {
      tripType.tripType = [optionSelectedTripType.value];
    } else {
      tripType.tripType = undefined;
    }

    if (optionSelectedCustomer) {
      customer.customerId = optionSelectedCustomer.value;
      customer.customer = optionSelectedCustomer.data;
    } else {
      customer.customerId = undefined;
      customer.customer = undefined;
    }

    if ((searchRegistrationPlate ?? null) !== null && searchRegistrationPlate !== '') {
      tripOptions.registrationPlate = searchRegistrationPlate;
    } else {
      tripOptions.registrationPlate = undefined;
    }

    if ((searchCustomerOrderNumber ?? null) !== null && searchCustomerOrderNumber !== '') {
      tripOptions.customerOrderNumber = searchCustomerOrderNumber;
    } else {
      tripOptions.customerOrderNumber = undefined;
    }

    if ((searchTripNumber ?? null) !== null && searchTripNumber !== '') {
      tripOptions.tripNumber = searchTripNumber;
    } else {
      tripOptions.tripNumber = undefined;
    }

    if ((searchExternalReference ?? null) !== null && searchExternalReference !== '') {
      tripOptions.tripExternalReference = searchExternalReference;
    } else {
      tripOptions.tripExternalReference = undefined;
    }

    if (optionFilteredCurrentZone) {
      currentZone.currentZone = optionFilteredCurrentZone.value;
    } else {
      currentZone.currentZone = undefined;
    }

    if (optionFilteredProcessingZone) {
      processingZone.processingZone = optionFilteredProcessingZone.value;
    } else {
      processingZone.processingZone = undefined;
    }

    hasBeenRefused.hasBeenRefused = filterHasBeenRefused;

    if (filteredDateRange) {
      if (moment(filteredDateRange.from).isSameOrBefore(moment(filteredDateRange.to))) {
        dateRange.startDate = moment(filteredDateRange.from).format('YYYY-MM-DD');
        dateRange.endDate = moment(filteredDateRange.to).format('YYYY-MM-DD');
      } else {
        dateRange.startDate = moment(filteredDateRange.from).format('YYYY-MM-DD');
        dateRange.endDate = undefined;
      }
    } else {
      dateRange.startDate = undefined;
      dateRange.endDate = undefined;
    }

    const myOtherFilter = {
      ...otherFilter,
      ...tripType,
      ...customer,
      ...currentZone,
      ...processingZone,
      ...dateRange,
      ...tripOptions,
      ...hasBeenRefused,
    };
    setIsHiddenFilter(!isHiddenFilter);

    setOtherFilter(myOtherFilter);
    localStorage.setItem('otherFilterTrip', JSON.stringify(myOtherFilter));
    paginationSetValues(1, perPage);

    fetchTrip(1, perPage, filterText, sortColumn, sortDirection, filtersTab, myOtherFilter);
  };

  const downloadCsv = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = `${orgid}/trip/export-csv?page=${currentPage}&limit=${perPage}&search=${filterText}&sortBy=${sortColumn}&orderBy=${sortDirection}`;

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...tripFilter, ...otherFilter },
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        fileDownload(res.data, 'export.csv');
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          "Une erreur est survenue lors du telechatgement d'export CSV. Merci de réessayer. code : " +
            (error?.response?.data?.code ?? error?.response?.status),
          ToastTypes.error,
        );
      });
  };

  const updateFilteredProcessingZone = (option: any) => {
    setOptionFilteredProcessingZone(option);
  };

  const updateFilteredCurrentZone = (option: any) => {
    setOptionFilteredCurrentZone(option);
  };

  const onChangeDateCheckbox = (e: any) => {
    setToggleDatePicker(!e.target.checked);
    setCheckboxStatusDatePicker(e.target.checked);
    setFilteredDateRange(undefined);
  };

  const onChangeProcessingZoneCheckbox = (e: any) => {
    setToggleSelectProcessingZone(!e.target.checked);
    setOptionFilteredProcessingZone(null);
    setCheckboxStatusProcessingZone(e.target.checked);
  };

  const onChangeCurrentZoneCheckbox = (e: any) => {
    setToggleSelectCurrentZone(!e.target.checked);
    setOptionFilteredCurrentZone(null);
    setCheckboxStatusCurrentZone(e.target.checked);
  };

  const onChangeTripTypeCheckbox = (e: any) => {
    setToggleSelectTripType(!e.target.checked);
    setCheckboxStatusTripType(e.target.checked);
    if (e.target.checked) {
      fetchTripType();
    } else {
      setOptionSelectedTripType(null);
    }
  };

  const onChangeCustomerCheckbox = (e: any) => {
    setToggleSelectCustomer(!e.target.checked);
    setCheckboxStatusCustomer(e.target.checked);
    if (!e.target.checked) {
      setOptionSelectedCustomer(null);
    }
  };

  const updateFilteredTripType = (option: any) => {
    setOptionSelectedTripType(option);
  };

  const updateFilteredCustomer = (option: any) => {
    setOptionSelectedCustomer(option);
  };

  const columns: any[] = [
    {
      name: t('common.details'),
      selector: (row: Trip) => `${row.externalReference ?? row.tripNumber} - ${row.tripType.label}`,
      cell: (row: Trip) => (
        <Link to={seeDetails(row)}>
          <div className='firstColumn' data-tag='allowRowEvents'>
            <div data-tag='allowRowEvents'>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row.externalReference ?? row.tripNumber} - {row.tripType.label}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {moment(row.tripDate).isValid()
                  ? moment(row.tripDate ?? null)
                      .tz(siteTimeZone)
                      .format('DD/MM/YYYY, HH:mm')
                  : moment(row.createdAt).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm')}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: false,
      right: false,
      id: 'details',
      grow: 2,
    },
    {
      name: t('common.vehicle'),
      selector: (row: Trip) => row.vehicle?.registrationPlate ?? row.registrationPlateRead,
      cell: (row: Trip) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <div className='firstRow' data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.vehicle?.registrationPlate ?? row.registrationPlateRead}
            </strong>
          </div>
          <div className='firstRow' data-tag='allowRowEvents'>
            <i className='caption2medium' data-tag='allowRowEvents'>
              {row.carrier?.label ?? ''}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'vehicle',
      grow: 1,
    },
    {
      name: t('common.order'),
      selector: (row: Trip) => row.tripNumber,
      cell: (row: Trip) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <div>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.order?.externalReference}
            </strong>
          </div>
          <div data-tag='allowRowEvents'>
            <i className='caption2medium' data-tag='allowRowEvents'>
              {row.order?.customerOrderNumber}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'commande',
      grow: 1,
    },
    {
      name: t('common.customer'),
      selector: (row: Trip) => row.tripNumber,
      cell: (row: Trip) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <div className='firstRow' data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.customer?.label}
            </strong>
          </div>
          <div className='firstRow' data-tag='allowRowEvents'>
            <i className='caption2medium' data-tag='allowRowEvents'>
              {row.buildingSite?.label}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'client',
      grow: 1,
    },
    {
      name: t('common.position'),
      selector: (row: any) => row.currentZone,
      cell: (row: any) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <strong className='caption2medium' data-tag='allowRowEvents'>
            {row.tracking?.currentZone?.label ?? '-'}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'currentZone',
      grow: 1,
    },
    {
      name: t('common.destination'),
      selector: (row: any) => row.tracking?.destinationZone,
      cell: (row: any) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <Select
            name='destinationZone'
            className='select-input'
            styles={customStyles()}
            isMulti={false}
            menuPlacement={'auto'}
            menuPosition={'fixed'}
            options={PopulatedOptionsZone(row.id)}
            placeholder={'Choisir une zone'}
            onChange={(input) => {
              input != null && input.value != null ? updateDestination(input) : null;
            }}
            value={
              row.tracking?.destinationZone
                ? { label: row.tracking?.destinationZone?.label, value: row.tracking?.destinationZone?.id }
                : null
            }
          />
        </div>
      ),
      sortable: false,
      right: false,
      id: 'destinationZone',
      grow: 1,
    },
    {
      name: t('common.processingZone'),
      selector: (row: Trip) => row.processingZone?.label,
      cell: (row: Trip) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <Select
            className='select-input'
            styles={customStyles()}
            name='processingZone'
            isMulti={false}
            menuPlacement={'auto'}
            menuPosition={'fixed'}
            options={PopulatedOptionsZoneProcess(row.id)}
            placeholder={'Choisir une option'}
            onChange={(input) => {
              input != null && input.value != null ? updateProcessingZone(input) : null;
            }}
            value={row.processingZone ? { label: row.processingZone?.label, value: row.processingZone?.id } : null}
          />
        </div>
      ),
      sortable: false,
      right: false,
      id: 'processingZone',
      grow: 1,
    },
    {
      name: t('common.status'),
      selector: (row: Trip) => Object.values(TripStatusTranslate)[Object.keys(TripStatusTranslate).indexOf(row.status)],
      cell: (row: Trip) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <Select
            name='status'
            id={`status-${row.id}`}
            className='select-input'
            menuPlacement={'auto'}
            menuPosition={'fixed'}
            styles={customStyles(
              `${
                row.status === TripStatus.EXITED ||
                row.status === TripStatus.CANCELED ||
                row.status === TripStatus.DELETED ||
                row.status === TripStatus.REFUSED
                  ? '#E6F5E6'
                  : ''
              }
              ${
                row.status === TripStatus.WAITING_FOR_SPACE_ENTRY ||
                row.status === TripStatus.WAITING_FOR_MANAGER_ENTRY ||
                row.status === TripStatus.WAITING_FOR_ERP_ENTRY ||
                row.status === TripStatus.WAITING_FOR_ERP_PROCESSING ||
                row.status === TripStatus.WAITING_FOR_MANAGER_PROCESSING ||
                row.status === TripStatus.WAITING_FOR_APP_PROCESSING ||
                row.status === TripStatus.WAITING_FOR_SPACE_PROCESSING ||
                row.status === TripStatus.WAITING_FOR_SPACE_EXIT ||
                row.status === TripStatus.WAITING_FOR_MANAGER_EXIT ||
                row.status === TripStatus.WAITING_FOR_ERP_EXIT ||
                row.status === TripStatus.ASKING_FOR_EXIT
                  ? '#FDE8DF'
                  : ''
              }
              ${
                row.status === TripStatus.CREATED ||
                row.status === TripStatus.PLANNED ||
                row.status === TripStatus.STARTED ||
                row.status === TripStatus.WAITING_FOR_EQUIPMENT_ENTRY ||
                row.status === TripStatus.WAITING_FOR_DRIVER_ENTRY ||
                row.status === TripStatus.ON_SITE ||
                row.status === TripStatus.WAITING_FOR_EQUIPMENT_PROCESSING ||
                row.status === TripStatus.PROCESSING ||
                row.status === TripStatus.PROCESSED ||
                row.status === TripStatus.WAITING_FOR_EQUIPMENT_EXIT ||
                row.status === TripStatus.WAITING_FOR_DRIVER_EXIT ||
                row.status === TripStatus.CAN_EXIT ||
                row.status === TripStatus.ANOMALY ||
                row.status === TripStatus.CAN_ENTER ||
                row.status === TripStatus.GO_TO_RECEPTION
                  ? '#DDEBFF'
                  : ''
              }`,
            )}
            isMulti={false}
            options={PopulatedOptionsStatus(row.id)}
            placeholder={'Choisir une zone'}
            onChange={(a) => {
              updateStatus(a);
            }}
            value={
              row.status
                ? {
                    label: Object.values(TripStatusTranslate)[Object.keys(TripStatusTranslate).indexOf(row.status)],
                    value: row.status,
                  }
                : null
            }
          />
        </div>
      ),
      sortable: false,
      right: false,
      id: 'status',
      grow: 1,
    },
  ];

  return (
    <div className='voyages'>
      <Menu selectedPage='trips'></Menu>

      <div className={'right table-container'}>
        <Head title={t('common.trips')} back={false} />

        <div className='table-report'>
          <div className='table-report-header'>
            <div className='table-report-header-title'>
              <h2 className='base2'>Stats</h2>
            </div>
            <div className='table-report-header-select'>
              <Select
                name='period'
                isMulti={false}
                options={optionsPeriod}
                placeholder={'Choisir une option'}
                onChange={(input) => {
                  input != null && input.value != null ? updatePeriod(input) : null;
                }}
                value={optionSelectedPeriod}
              />
              <div className='svgBox primary2'>
                {!isCloseReport ? (
                  <div onClick={toggleReport}>
                    <Icon icon='chevronUp' size='medium' />
                  </div>
                ) : (
                  <div onClick={toggleReport}>
                    <Icon icon='chevronDown' size='medium' />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div hidden={isCloseReport} style={{ width: '100%' }}>
            <div className='table-report-body' hidden={isCloseReport}>
              <div className='table-report-body-tile tertiary2'>
                <div className='svgBox primary2'>
                  <Icon icon={'eye'} style='primary' />
                </div>
                <div className='dataBox'>
                  <div className='dataBox-total'>
                    <h2 className='base2'>{t('common.total')}</h2>
                  </div>
                  <div className='dataBox-data'>
                    <h1 className='base2'>{totalTrip}</h1>
                  </div>
                </div>
              </div>
              <div className='table-report-body-tile tertiary1'>
                <div className='svgBox primary1'>
                  <Icon icon={'progress'} style='primary' />
                </div>
                <div className='dataBox'>
                  <div className='dataBox-total'>
                    <h2 className='base2'>{t('common.inProgress')}</h2>
                  </div>
                  <div className='dataBox-data'>
                    <h1 className='base2'>{inProgessTrip}</h1>
                  </div>
                </div>
              </div>
              <div className='table-report-body-tile tertiary3'>
                <div className='svgBox primary3'>
                  <Icon icon={'checkPaper'} style='primary' />
                </div>
                <div className='dataBox'>
                  <div className='dataBox-total'>
                    <h2 className='base2'>{t('common.exited')}</h2>
                  </div>
                  <div className='dataBox-data'>
                    <h1 className='base2'>{terminatedTrip}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='tabHeader'>
          <div className='tabHeaderTitle title1SemiBold'>{t('common.tripsList')}</div>
          <div className='tabHeaderBtn'>
            {isHiddenFilter ? (
              <div className='filterMenu'>
                <div className='filterMenu_cat'>
                  <div className='svgCloseBox primary2'>
                    <div onClick={openCloseAdvancedFilter}>
                      <Icon icon='chevronUp' style='neutral4' size='medium' />
                    </div>
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input
                      type='checkbox'
                      onChange={onChangeTripTypeCheckbox}
                      checked={checkboxStatusTripType || (optionSelectedTripType?.length ?? 0) > 0}
                    />
                    <h2 className='base2'>Type de voyage</h2>
                  </div>
                  <div hidden={toggleSelectTripType}>
                    <div className='filterMenu_cat_select'>
                      <div className='svgBox primary2'>
                        <Select
                          name='filteredTripType'
                          className='select-input'
                          styles={customStylesSelectFilter()}
                          options={optionsTripTypes}
                          value={optionSelectedTripType}
                          onChange={(input) => {
                            input != null && input.value != null ? updateFilteredTripType(input) : null;
                          }}
                          isMulti={false}
                          placeholder={'Choisir une option'}
                        />
                        <div onClick={() => setOptionSelectedTripType(null)}>
                          <Icon icon={'close'} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input
                      type='checkbox'
                      onChange={onChangeCustomerCheckbox}
                      checked={checkboxStatusCustomer || (optionSelectedCustomer?.length ?? 0) > 0}
                    />
                    <h2 className='base2'>Client</h2>
                  </div>
                  <div hidden={toggleSelectCustomer}>
                    <div className='filterMenu_cat_select'>
                      <div className='svgBox primary2'>
                        <SelectCustomer
                          registerName={''}
                          error={''}
                          isSelectable={true}
                          selectedOptionChanged={(props) => {
                            updateFilteredCustomer(props);
                          }}
                          initialOption={optionSelectedCustomer}
                          titleSize={'none'}
                          dependsOnSearchParent={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input
                      type='checkbox'
                      onChange={onChangeRegistrationPlateCheckbox}
                      checked={checkboxStatusRegistrationPlate}
                    />
                    <h2 className='base2'>Immatriculation</h2>
                  </div>
                  <div hidden={toggleInputregistrationPlate}>
                    <div className='filterMenu_cat_input'>
                      <div className='svgBox primary2'>
                        <input
                          type='text'
                          placeholder='AA111BB'
                          onChange={onChangeRegistrationPlateInput}
                          value={searchRegistrationPlate}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              searchWithAdvancedFilters();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input
                      type='checkbox'
                      onChange={onChangeCustomerOrderNumberCheckbox}
                      checked={checkboxStatusCustomerOrderNumber}
                    />
                    <h2 className='base2'>Commande Client</h2>
                  </div>
                  <div hidden={toggleInputCustomerOrderNumber}>
                    <div className='filterMenu_cat_input'>
                      <div className='svgBox primary2'>
                        <input
                          type='text'
                          placeholder='Num. Commande Client'
                          onChange={onChangeCustomerOrderNumberInput}
                          value={searchCustomerOrderNumber}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              searchWithAdvancedFilters();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input
                      type='checkbox'
                      onChange={onChangeExternalReferenceCheckbox}
                      checked={checkboxStatusExternalReference}
                    />
                    <h2 className='base2'>Référence Externe</h2>
                  </div>
                  <div hidden={toggleInputExternalReference}>
                    <div className='filterMenu_cat_input'>
                      <div className='svgBox primary2'>
                        <input
                          type='text'
                          placeholder='Ext. Ref.'
                          onChange={onChangeExternalReferenceInput}
                          value={searchExternalReference}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              searchWithAdvancedFilters();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input type='checkbox' onChange={onChangeTripNumberCheckbox} checked={checkboxStatusTripNumber} />
                    <h2 className='base2'>Numéro de Voyage</h2>
                  </div>
                  <div hidden={toggleInputTripNumber}>
                    <div className='filterMenu_cat_input'>
                      <div className='svgBox primary2'>
                        <input
                          type='text'
                          placeholder='275'
                          onChange={onChangeTripNumberInput}
                          value={searchTripNumber}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              searchWithAdvancedFilters();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input type='checkbox' onChange={onChangeCurrentZoneCheckbox} checked={checkboxStatusCurrentZone} />
                    <h2 className='base2'>Zone actuelle</h2>
                  </div>
                  <div hidden={toggleSelectCurrentZone}>
                    <div className='filterMenu_cat_select'>
                      <div className='svgBox primary2'>
                        <Select
                          name='filteredCurrentZone'
                          className='select-input'
                          styles={customStylesSelectFilter()}
                          isMulti={false}
                          options={PopulatedOptionsZone('all')}
                          placeholder={'Choisir une zone'}
                          onChange={(input) => {
                            input != null && input.value != null ? updateFilteredCurrentZone(input) : null;
                          }}
                          value={optionFilteredCurrentZone}
                        />
                        <div onClick={() => setOptionFilteredCurrentZone(null)}>
                          <Icon icon={'close'} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input
                      type='checkbox'
                      onChange={onChangeProcessingZoneCheckbox}
                      checked={checkboxStatusProcessingZone}
                    />
                    <h2 className='base2'>Zone Affectée</h2>
                  </div>
                  <div hidden={toggleSelectProcessingZone}>
                    <div className='filterMenu_cat_select'>
                      <div className='svgBox primary2'>
                        <Select
                          name='FilteredProcessingZone'
                          className='select-input'
                          styles={customStylesSelectFilter()}
                          isMulti={false}
                          options={PopulatedOptionsZoneProcess('all')}
                          placeholder={'Choisir une zone'}
                          onChange={(input) => {
                            input != null && input.value != null ? updateFilteredProcessingZone(input) : null;
                          }}
                          value={optionFilteredProcessingZone}
                        />
                        <div onClick={() => setOptionFilteredProcessingZone(null)}>
                          <Icon icon={'close'} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input type='checkbox' onChange={onChangeDateCheckbox} checked={checkboxStatusDatePicker} />
                    <h2 className='base2'>Date</h2>
                  </div>
                  <div className='filterMenu_cat_content' hidden={toggleDatePicker}>
                    <DayPicker
                      locale={localPicker}
                      mode='range'
                      defaultMonth={moment().toDate()}
                      selected={filteredDateRange}
                      onSelect={setFilteredDateRange}
                    />
                  </div>
                </div>
                <div className='filterMenu_cat'>
                  <div className='filterMenu_cat_title'>
                    <input
                      type='checkbox'
                      onChange={() => setFilterHasBeenRefused((prevValue) => !prevValue)}
                      checked={filterHasBeenRefused}
                    />
                    <h2 className='base2'>A été refusé</h2>
                  </div>
                </div>
                <div className='filterMenu_footer'>
                  <div className='addBtn' onClick={searchWithAdvancedFilters}>
                    <div className='base2'>Valider</div>
                  </div>
                  <div className='cancelBtn' onClick={toggleFilter}>
                    <div className='base2'>Annuler</div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={
                  optionSelectedTripType ||
                  optionFilteredCurrentZone ||
                  optionFilteredProcessingZone ||
                  filteredDateRange ||
                  filterHasBeenRefused ||
                  searchRegistrationPlate ||
                  (localStorage.getItem('otherFilterTrip') ?? '') !== ''
                    ? 'filterBtn active'
                    : 'filterBtn'
                }
                onClick={openCloseAdvancedFilter}
              >
                <Icon icon='filter' />
                <div className='base2'>{t('common.filters')}</div>
              </div>
            )}

            <div className='importExportBtn' onClick={downloadCsv}>
              <div className='base2'>{t('common.export')}</div>
            </div>

            <Link className='addBtn' to={'/trips/add'}>
              <Icon icon='add' style='primary' />
              <div className='base2'>{t('common.addTrip')}</div>
            </Link>
          </div>
        </div>
        <TabFilter tabArray={tabFilterOptions} filterSet={handleSetFiltersStatus} defaultTab={defaultTab.toString()} />

        {isLoading ? (
          <div>{t('common.loading')}</div>
        ) : (
          <DataTable
            className='table'
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            onRowClicked={(row) => history(seeDetails(row))}
            highlightOnHover
            pointerOnHover
            data={filteredItems}
            noDataComponent={<div className='noResults'>{'Aucun voyage'}</div>}
            defaultSortAsc={sortDirection == 'asc'}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            conditionalRowStyles={[{ when: (row: any) => row.hasBeenRefused, style: { backgroundColor: '#F6DFDF' } }]}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t('common.perPage'),
              rangeSeparatorText: t('common.onPage'),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: 'All',
            }}
          />
        )}
      </div>
    </div>
  );
});

class SearchStatus {
  status: string[] | undefined;
}

Trips.displayName = 'Trips';
export default Trips;
