import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import { GetCarrier, GetCarrierParams } from '../../models/Carrier';
import DetailCarrier from '../pages/Carriers/DetailCarrier';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import useToast from '../../hooks/useToast';
import useAuthGuard from '../../hooks/useAuthGuard';
import { handleApiError } from '../../utils/apiErrorHandler';
import { fetchCarrierDetails, fetchCarriers } from '../../services/carrier';

export interface SelectCarrierOption extends SelectDataOption {
  data: GetCarrier;
}

const generateCarrierOptions = (carriers: GetCarrier[]) => {
  const options =
    carriers.map((carrier) => ({
      value: carrier.id,
      label: carrier.label ?? '',
      data: carrier,
    })) || [];

  return options;
};

export const SelectCarrier = ({
  setValue,
  register,
  registerName = 'client',
  error,
  selectedOptionChanged,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const history = useNavigate();
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectCarrierOption[]>([]);
  const [createdOption, setCreatedOption] = useState<SelectCarrierOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const fetchCarriersByQuery = async (filterText: string) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setFilterText(filterText);
      if (filterText.length <= 0 && (searchParent ?? null) === null) {
        setOptions([]);
        return;
      }
      setIsLoading(true);

      const params: GetCarrierParams = {
        page: 1,
        limit: 200,
        onlyActive: true,
        search: filterText,
      };

      const { items } = await fetchCarriers({
        accessToken: token,
        orgid,
        params,
      });

      const options = generateCarrierOptions(items);
      setOptions(options);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const [showAddPopup, setShowAddPopup] = useState(false);

  const addPopupClosedHandler = async (createdId?: string) => {
    setShowAddPopup(false);

    try {
      if (!token || !orgid || !createdId) {
        return;
      }

      setIsLoading(true);

      const fetchedCarrierDetails = await fetchCarrierDetails({
        accessToken: token,
        orgid,
        carrierId: createdId,
      });

      const { id, label, externalReference } = fetchedCarrierDetails;

      setCreatedOption({
        value: id,
        label: `${label}${externalReference ? ` - ${externalReference}` : ''}`,
        data: fetchedCarrierDetails,
      });
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
      generateCarrierOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showAddPopup && <DetailCarrier mode='add' isPopup={true} onClosePopup={addPopupClosedHandler} />}
      <SelectData
        title={props.titleOverride ?? t('common.carrier')}
        placeholder={t('common.carrierChoose')}
        titleSize={props.titleSize ?? 'large'}
        setValue={setValue}
        register={register}
        registerName={registerName}
        error={error}
        isSelectable={props.isSelectable}
        selectedOptionChanged={selectedOptionChanged}
        clearOnSelect={props.clearOnSelect}
        isLoading={isLoading}
        options={options}
        searchParent={searchParent}
        dependsOnSearchParent={dependsOnSearchParent}
        initialOption={initialOption}
        forceSelectedOption={createdOption ?? forceSelectedOption}
        filterText={filterText}
        fetch={fetchCarriersByQuery}
        onClickNoResult={() => {
          setShowAddPopup(true);
        }}
        isPopup={props.isPopup}
        onClosePopup={props.onClosePopup}
        noResultText={props.noResultTextOverride}
      ></SelectData>
    </>
  );
};
