import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useToast from '../../../hooks/useToast';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import { useTabFilters } from '../../../hooks/useTabFilters';
import TabFilter from '../TabFilter';
import { QuotationStatus, GetQuotation, GetQuotationPayload, GetQuotationParams } from '../../../models/Quotation';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import moment from 'moment';
import { SelectStatus } from '../../forms/SelectStatus';
import { Button } from '../../../stories/dune/atoms/Button';
import { GlobalParameter } from '../../../models/GlobalParameter';
import { fetchQuotations } from '../../../services/quotation';
import { handleApiError } from '../../../utils/apiErrorHandler';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { t } from 'i18next';
import { usePagination } from '../../../hooks/usePagination';

interface ListQuotationsProps {
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
  quotationType: 'Quotation' | 'Contract';
}

const ListQuotations = React.memo((props: ListQuotationsProps) => {
  // #region intializing constants

  // #region generic
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const { currentPage, perPage, paginationSetValues, changePaginationTabValue } = usePagination();

  const defaultTab =
    props.defaultStatus ??
    QuotationStatus.CREATED +
      ';' +
      QuotationStatus.UPDATED +
      ';' +
      QuotationStatus.SENT +
      ';' +
      QuotationStatus.GENERATED +
      ';' +
      QuotationStatus.IN_ERROR;
  const { filtersTab } = useTabFilters(defaultTab, defaultTab.split(';'));
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);

  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalRows, setTotalRows] = useState(0);

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  // #endregion generic

  // #region specific

  const [filteredItems, setFilteredItems] = useState<GetQuotation[]>([]);

  const fetchItems = async (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setIsLoading(true);
      setFilteredItems([]);

      if (newSortColumn != sortColumn) {
        setSortColumn(newSortColumn);
      }

      if (newSortDirection != sortDirection) {
        setSortDirection(newSortDirection);
      }

      const params: GetQuotationParams = {
        page,
        limit: newPerPage,
        sortBy: newSortColumn,
        orderBy: newSortDirection,
      };

      const { items, total } = await fetchQuotations({
        accessToken: token,
        orgid,
        params,
        payload: generatePayload(TabFilter, AdvancedFilters),
      });

      setTotalRows(total);
      setFilteredItems(items);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoading(false);
    }
  };

  function generatePayload(tabFilters: string[], advancedFilters: AdvancedFilter[]) {
    const payload: GetQuotationPayload = {
      quotationType: props.quotationType,
    };

    if (tabFilters?.length > 0) payload.status = tabFilters;

    if (advancedFilters.length > 0) {
      advancedFilters.map((filter) => {
        if (filter.filterType === 'search') payload.search = filter.filterData;
        if (filter.filterType === 'buildingSite') payload.buildingSiteId = filter.filterData?.value;
        if (filter.filterType === 'customer') payload.customerId = filter.filterData?.value;
        if (filter.filterType === 'externalReference') payload.externalReference = filter.filterData;
        if (filter.filterType === 'number') payload.quotationNumber = filter.filterData;
      });
    }

    return payload;
  }

  const tabFilterOptions = [
    { tabFilter: 'Tous', tabLabelTranslate: t('common.all') },
    {
      tabFilter:
        QuotationStatus.CREATED +
        ';' +
        QuotationStatus.UPDATED +
        ';' +
        QuotationStatus.SENT +
        ';' +
        QuotationStatus.GENERATED +
        ';' +
        QuotationStatus.IN_ERROR,
      tabLabelTranslate: t('status.InProgress'),
    },
    { tabFilter: QuotationStatus.VALIDATED, tabLabelTranslate: t('status.' + QuotationStatus.VALIDATED) },
    { tabFilter: QuotationStatus.REFUSED, tabLabelTranslate: t('status.' + QuotationStatus.REFUSED) },
    { tabFilter: QuotationStatus.EXPIRED, tabLabelTranslate: t('status.' + QuotationStatus.EXPIRED) },
  ];

  const seeDetails = (row: GetQuotation) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = props.quotationType.toLowerCase() + 's';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.number'),
      selector: (row: GetQuotation) => row.quotationNumber,
      cell: (row: GetQuotation) => (
        <Link to={seeDetails(row)}>
          <div className='column-align-left' data-tag='allowRowEvents'>
            <div data-tag='allowRowEvents'>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row.quotationNumber + (row.quotationSubject ? ' - ' + row.quotationSubject : '')}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {row.externalReference}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'quotationNumber',
    },
    {
      name: t('common.customer'),
      selector: (row: GetQuotation) => row.customer?.label,
      cell: (row: GetQuotation) => (
        <Link to={seeDetails(row)}>
          <div className='column-align-left' data-tag='allowRowEvents'>
            <div data-tag='allowRowEvents'>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row.customer?.label}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {row.customer?.externalReference}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: false,
      right: false,
      id: 'customer',
    },
    {
      name: t('common.buildingSite'),
      selector: (row: GetQuotation) => row.buildingSite?.id,
      cell: (row: GetQuotation) => (
        <div className='column-align-left' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.buildingSite?.label}
            </strong>
          </div>
          <div data-tag='allowRowEvents'>
            <i className='caption2medium' data-tag='allowRowEvents'>
              {row.buildingSite?.externalReference}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'buildingSite',
    },
    {
      name: t('common.creationDate'),
      selector: (row: GetQuotation) => row.createdAt,
      cell: (row: GetQuotation) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <div className='caption2medium' data-tag='allowRowEvents'>
              {moment(row.createdAt).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm')}
            </div>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'createdAt',
    },
    {
      name: t('common.status'),
      selector: (row: GetQuotation) => row.status,
      cell: (row: GetQuotation) => (
        <div className='column' data-tag='allowRowEvents'>
          <SelectStatus
            registerName={''}
            error={''}
            titleSize='none'
            forceSelectedOption={row.status}
            statusType={'quotation'}
            isSelectable={false}
            data-tag='allowRowEvents'
          />
        </div>
      ),

      sortable: true,
      right: false,
      id: 'status',
      grow: 1,
    },
  ];

  // #endregion specific

  // #endregion intializing constants

  // #region event handling

  const handlePageChange = (pageNumber: number) => {
    paginationSetValues(pageNumber, perPage);
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    paginationSetValues(1, newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[], resetPagination: boolean) {
    setFiltersAdvanced(newFilters);
    fetchItems(resetPagination ? 1 : currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  function handleSetFiltersStatus(newFilters: string[], tabOption: string) {
    const { page: newPage, perPage: newPerPage } = changePaginationTabValue(tabOption);
    fetchItems(newPage, newPerPage, sortColumn, sortDirection, newFilters, filtersAdvanced);
  }

  // #endregion event handling

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={['search', 'number', 'externalReference', 'customer', 'buildingSite']}
        showAddNew={true}
        addNewText={t('common.new' + props.quotationType)}
        addNew={() => {
          history('./add');
        }}
        dropdown={true}
        listDropdown={[
          <Button
            key='customer'
            onClick={() => history('./add/customer')}
            label={t('common.customer')}
            style='white'
            size='thin'
          />,
          <Button
            key='jobSite'
            onClick={() => history('./add/buildingSite')}
            label={t('common.jobSite')}
            style='white'
            size='thin'
          />,
        ]}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['search']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />
      <TabFilter tabArray={tabFilterOptions} filterSet={handleSetFiltersStatus} defaultTab={defaultTab} />
      {isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          className='table'
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => history(seeDetails(row))}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noResult')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

ListQuotations.displayName = 'ListQuotations';
export default ListQuotations;
