export interface TabFiltersData {
  tabOption: string;
  tabFilters: string[];
}

export const useTabFilters = (
  defaultTabOption: string | undefined = undefined,
  defaultTabFilter: string[] | undefined = undefined,
) => {
  const newState: TabFiltersData = {
    tabOption: defaultTabOption ?? '',
    tabFilters:
      defaultTabFilter ?? ((defaultTabOption && defaultTabOption != '' && defaultTabOption != 'Tous') ? defaultTabOption?.split(';') : []) ?? [],
  };
  const tabFiltersId = 'tabFilters' + window.location.href;

  const { tabOption, tabFilters: filtersTab } =
    (localStorage.getItem(tabFiltersId) ?? '') !== ''
      ? (JSON.parse(localStorage.getItem(tabFiltersId) ?? '') as TabFiltersData)
      : newState;

  const tabFiltersReset = () => {
    localStorage.setItem(tabFiltersId, JSON.stringify(newState));
  };

  const setFiltersTab = (newTabFilters: string[] | null, newTabOption: string | null = null) => {
    if (newTabFilters && (!newTabOption || newTabOption == '')) {
      // si l'option n'est pas définie, on la construit à partir des filtres
      newTabFilters.map((x) => (newTabOption += x + ';'));
      if (newTabOption?.endsWith(';')) newTabOption = newTabOption.substring(0, newTabOption.length - 1);
    } else if (newTabOption && (!newTabFilters || newTabFilters.length == 0) && newTabOption.includes(';')) {
      newTabFilters = newTabOption.split(';');
    }
    localStorage.setItem(tabFiltersId, JSON.stringify({ tabOption: newTabOption, tabFilters: newTabFilters }));
  };

  return { tabOption, filtersTab, tabFiltersReset, setFiltersTab };
};
