import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../../stories/dune/atoms/FormRow';
import { Button } from '../../stories/dune/atoms/Button';
import './SubMenu.scss';
import { useQuery } from '../../hooks/useQuery';
import { useNavigate } from 'react-router-dom';

interface SubMenuProps {
  tab: string;
  updateTab: any;
  tabs: { key: string; label: string }[];
  inForm?: boolean;
}

const SubMenu = React.memo((props: SubMenuProps) => {
  const { t } = useTranslation();
  const { queryParams, hash } = useQuery();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    props.updateTab(hash.replace('#', '') || props.tab);
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (props.tab && isLoaded && !props.inForm) {
      if (!hash.includes(props.tab)) {
        updateTabData(props.tab);
      }
    }
  }, [props]);

  function updateTabData(mode: string) {
    queryParams.delete('page');
    queryParams.delete('perPage');
    navigate(`?${queryParams.toString()}#${mode}`, { replace: true });
  }

  function handleOnClickTab(mode: string) {
    updateTabData(mode);
    props.updateTab(mode);
  }

  const content = (
    <>
      <FormRow>
        {props.tabs.map((val) => (
          <Button
            label={t(val.label)}
            size='wide'
            style={props.tab === val.key ? 'primary' : 'white'}
            onClick={() => handleOnClickTab(val.key)}
            key={val.key}
          />
        ))}
      </FormRow>
    </>
  );

  return props.inForm ? content : <div className='submenu'>{content}</div>;
});

SubMenu.displayName = 'SubMenu';
export default SubMenu;
