import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import ListCustomersToInvoice from '../../lists/Invoices/ListCustomersToInvoice';
import ListInvoices from '../../lists/Invoices/ListInvoices';
import SubMenu from '../../forms/SubMenu';

const Invoicing = React.memo(() => {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState('toInvoice');

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  return (
    <div className='main-container'>
      <Menu selectedPage='invoicing' />
      <div className='right detail-container'>
        <Head title={t('common.invoicing')} back={false} />
        <SubMenu
          tab={currentTab}
          updateTab={(mode: string) => updateCurrentTab(mode)}
          tabs={[
            { key: 'toInvoice', label: t('common.toInvoice') },
            { key: 'invoices', label: t('common.invoices') },
          ]}
        />
        <>
          {currentTab === 'toInvoice' && <ListCustomersToInvoice onSuccess={() => setCurrentTab('invoices')} />}
          {currentTab === 'invoices' && <ListInvoices />}
        </>
      </div>
    </div>
  );
});

Invoicing.displayName = 'Invoicing';
export default Invoicing;
