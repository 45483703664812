import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { search, hash } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  return { queryParams, hash };
};
