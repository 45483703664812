import { SelectData, SelectProps, SelectDataOption } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import BuildingSite, {
  GetBuildingSite,
  GetBuildingSiteParams,
  GetBuildingSitePayload,
} from '../../models/BuildingSite';
import stringUtils from '../../utils/stringUtils';
import DetailBuildingSite from '../pages/BuildingSites/DetailBuildingSite';
import { t } from 'i18next';
import useAuthGuard from '../../hooks/useAuthGuard';
import { fetchBuildingSiteDetails, fetchBuildingSites } from '../../services/buildingSite';
import { handleApiError } from '../../utils/apiErrorHandler';
import { useNavigate } from 'react-router-dom';
import useToast from '../../hooks/useToast';

export interface SelectBuildingSiteOption extends SelectDataOption {
  data: BuildingSite;
}

const generateBuildingSiteOptions = (buildingSites: GetBuildingSite[]) => {
  const options =
    buildingSites.map((buildingSite) => ({
      value: buildingSite.id,
      label: buildingSite.label ?? '',
      searchParentRef: buildingSite.customerId,
      data: buildingSite,
    })) || [];

  return options;
};

export const SelectBuildingSite = ({
  setValue,
  register,
  registerName = 'chantier',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = true,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectDataOption[]>([]);
  const [createdOption, setCreatedOption] = useState<SelectBuildingSiteOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const fetchBuildingSitesByQuery = async (filterText: string) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setFilterText(filterText);
      if (filterText.length <= 0 && (searchParent ?? null) === null) {
        setOptions([]);
        return;
      }
      setIsLoading(true);

      const params: GetBuildingSiteParams = {
        page: 1,
        limit: 200,
        search: filterText,
      };

      const payload: GetBuildingSitePayload = stringUtils.formatFieldsForPost({
        onlyActive: true,
        customerId: (searchParent ?? null) === null ? undefined : searchParent,
      });

      const { items } = await fetchBuildingSites({
        accessToken: token,
        orgid,
        params,
        payload,
      });
      const options = generateBuildingSiteOptions(items);
      setOptions(options);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const [showAddPopup, setShowAddPopup] = useState(false);

  const addPopupClosedHandler = async (createdId?: string) => {
    setShowAddPopup(false);

    try {
      if (!token || !orgid || !createdId) {
        return;
      }

      setIsLoading(true);

      const fetchedBuildingSiteDetails = await fetchBuildingSiteDetails({
        accessToken: token,
        orgid,
        buildingSiteId: createdId,
      });

      const { id, label, externalReference } = fetchedBuildingSiteDetails;

      setCreatedOption({
        value: id,
        label: `${label}${externalReference ? ` - ${externalReference}` : ''}`,
        data: fetchedBuildingSiteDetails,
      });
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
      generateBuildingSiteOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showAddPopup && (
        <DetailBuildingSite mode='add' isPopup={true} onClosePopup={addPopupClosedHandler} dataParent={searchParent} />
      )}
      <SelectData
        title={props.titleOverride ?? t('common.buildingSite')}
        placeholder={props.placeholder ?? t('common.buildingSiteChoose')}
        hoverDescription={props.hoverDescription ?? t('common.buildingSiteDesc')}
        titleSize={props.titleSize ?? 'large'}
        setValue={setValue}
        register={register}
        registerName={registerName}
        error={error}
        isSelectable={props.isSelectable}
        selectedOptionChanged={selectedOptionChanged}
        clearOnSelect={props.clearOnSelect}
        isLoading={isLoading}
        options={options}
        searchParent={searchParent}
        dependsOnSearchParent={dependsOnSearchParent}
        initialOption={initialOption}
        forceSelectedOption={createdOption ?? forceSelectedOption}
        filterText={filterText}
        fetch={fetchBuildingSitesByQuery}
        fetchAtLoad={searchParent ? true : false}
        onClickNoResult={() => {
          setShowAddPopup(true);
        }}
        noResultText={props.noResultTextOverride}
        isPopup={isPopup}
        onClosePopup={onClosePopup}
      />
    </>
  );
};
