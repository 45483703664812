export enum GenericTripType {
  LOAD = 'load',
  UNLOAD = 'unload',
  AUTHORIZATION = 'authorization',
  UNKNOWN = 'unknown',
}

export default class TripType {
  id: string | undefined;
  label: string | undefined;
  externalReference: string | undefined;
  genericType: GenericTripType | undefined;
}
