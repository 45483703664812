import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import DetailCustomer from '../pages/Customers/DetailCustomer';
import Customer, { GetCustomer, GetCustomerParams } from '../../models/Customer';
import { t } from 'i18next';
import useAuthGuard from '../../hooks/useAuthGuard';
import { fetchCustomerDetails, fetchCustomers } from '../../services/customer';
import { handleApiError } from '../../utils/apiErrorHandler';
import useToast from '../../hooks/useToast';
import { useNavigate } from 'react-router-dom';

export interface SelectCustomerOption extends SelectDataOption {
  data: Customer;
}

export const SelectCustomer = ({
  setValue,
  register,
  registerName = 'client',
  error,
  selectedOptionChanged,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const history = useNavigate();
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);

  const [customerOptions, setCustomerOptions] = useState<SelectDataOption[]>([]);
  const [createdOption, setCreatedOption] = useState<SelectCustomerOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const fetchCustomersByQuery = async (filterText: string) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setFilterText(filterText);
      if (filterText.length <= 0 && (searchParent ?? null) === null) {
        setCustomerOptions([]);
        return;
      }
      setIsLoadingCustomers(true);

      const params: GetCustomerParams = {
        page: 1,
        limit: 200,
        onlyActive: true,
        search: filterText,
      };

      const { items } = await fetchCustomers({
        accessToken: token,
        orgid,
        params,
      });

      const options = generateCustomerOptions(items);
      setCustomerOptions(options);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  const generateCustomerOptions = (customers: GetCustomer[]) => {
    const options =
      customers.map((customer) => ({
        value: customer.id,
        label: customer.label ?? '',
        data: customer,
      })) || [];

    return options;
  };

  const [showAddPopup, setShowAddPopup] = useState(false);

  const addPopupClosedHandler = async (createdId?: string) => {
    setShowAddPopup(false);

    try {
      if (!token || !orgid || !createdId) {
        return;
      }

      setIsLoadingCustomers(true);

      const fetchedCustomerDetails = await fetchCustomerDetails({
        accessToken: token,
        orgid,
        customerId: createdId,
      });

      const { id, label, externalReference } = fetchedCustomerDetails;

      setCreatedOption({
        value: id,
        label: `${label}${externalReference ? ` - ${externalReference}` : ''}`,
        data: fetchedCustomerDetails,
      });
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
      setCustomerOptions([]);
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  return (
    <>
      {showAddPopup && <DetailCustomer mode='add' isPopup={true} onClosePopup={addPopupClosedHandler} />}
      <SelectData
        title={props.titleOverride ?? t('common.customer')}
        placeholder={t('common.customerChoose')}
        titleSize={props.titleSize ?? 'large'}
        setValue={setValue}
        register={register}
        registerName={registerName}
        error={error}
        isSelectable={props.isSelectable}
        selectedOptionChanged={selectedOptionChanged}
        clearOnSelect={props.clearOnSelect}
        isLoading={isLoadingCustomers}
        options={customerOptions}
        searchParent={searchParent}
        dependsOnSearchParent={dependsOnSearchParent}
        initialOption={initialOption}
        forceSelectedOption={createdOption ?? forceSelectedOption}
        filterText={filterText}
        fetch={fetchCustomersByQuery}
        onClickNoResult={() => {
          setShowAddPopup(true);
        }}
        isPopup={props.isPopup}
        onClosePopup={props.onClosePopup}
        noResultText={props.noResultTextOverride}
      ></SelectData>
    </>
  );
};
