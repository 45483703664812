import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../stories/dune/atoms/Button';
import AdditionalDoc, { AdditionalDocSave, GetAdditionalDoc } from '../../models/AdditionalDoc';
import { useSelector } from 'react-redux';
import { StaticDataState } from '../../store/staticData-slice';
import axios from 'axios';
import Config from '../../Config';
import { ToastTypes } from '../../models/ToastTypes';
import useToast from '../../hooks/useToast';
import { useNavigate } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { DataBox } from '../../stories/dune/molecules/DataBox';

interface ManageDocumentProps {
  label?: string;
  hoverDescription?: string;
  docType: 'RepCustomerExemption' | 'RepBuildingSiteExemption' | 'ZonePlan' | 'Signature' | 'OtherDocument';
  entityType:
    | 'quotation'
    | 'order'
    | 'trip'
    | 'customer'
    | 'building_site'
    | 'carrier'
    | 'vehicle'
    | 'product'
    | 'zone';
  entityId: string;
  additionalDoc?: GetAdditionalDoc;
  onAddDocument: (additionalDoc: AdditionalDoc) => void;
}

export const ManageDocument = React.memo((props: ManageDocumentProps) => {
  const { t } = useTranslation();
  const { addToast, addFixedToast } = useToast();
  const history = useNavigate();

  const [additionalDoc, setAdditionalDoc] = useState<GetAdditionalDoc | undefined>(props.additionalDoc);

  const inputFile = useRef<HTMLInputElement>(null);

  const targetDocType = useSelector((state: { staticData: StaticDataState }) =>
    state.staticData.additionalDocTypes.find((x) => x.label === props.docType),
  );

  const addDocument = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const allowedExtensions = targetDocType
        ? targetDocType.extensions?.split(',')
        : ['.pdf', '.png', '.jpg', '.jpeg', '.gif', '.bmp', '.tiff', '.tif'];
      const fileExtension = '.' + (file ? file.name.split('.').pop()?.toLowerCase() : '');

      if (allowedExtensions && !allowedExtensions.includes(fileExtension)) {
        // Show an error message
        alert('Invalid file extension');
        // Clear the file input
        event.target.files = null;
      } else {
        await onAddFileOk(event.target.files[0]); // object fichier
      }
    }
  };

  const onAddFileOk = async (doc: any) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const dataToSend: AdditionalDocSave = {
      fileName: doc.name,
      additionalDocTypeId: targetDocType ? targetDocType.id : '1baf5bec-064f-4d79-987a-ae4f3e7f40f4',
      autoLinkToTrip: false,
      autoLinkAtTripStart: false,
      autoLinkAtTripEnd: false,
      displayAtTripStart: false,
      displayAtTripEnd: false,
      additionalDocEntity: props.entityType,
      entityId: props.entityId,
      origin: 'extranet',
      fileBody: await toBase64(doc),
    };

    const url = orgid + '/additional-doc/add';
    axios
      .post(Config.getApiExtranetUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        addToast(t('common.fileAdded'), ToastTypes.success);
        props.onAddDocument(dataToSend);
        fetchDocument();
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const toBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // you could also read images and other binaries
      reader.onload = () => resolve((reader.result as string).split(',')[1]); // prendre le Base64
      reader.onerror = (error) => reject(error);
    });

  async function onClickDownloadDoc(row?: GetAdditionalDoc) {
    if (!row) return;
    const data = await axios({
      url: row.url, // your url
      method: 'GET',
      responseType: 'blob', // important
    });

    fileDownload(data.data, row.label);
    return data;
  }

  useEffect(() => {
    if (props.additionalDoc) setAdditionalDoc(props.additionalDoc);
    else {
      fetchDocument();
    }
  }, [props.additionalDoc, targetDocType]);

  function fetchDocument() {
    if (props.entityId && props.entityType && props.docType) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const url = orgid + '/additional-doc/get';
      axios
        .post(
          Config.getApiExtranetUrl(url),
          { ...{ additionalDocEntity: props.entityType, entityId: props.entityId } },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(async (res) => {
          const doc = res.data.content
            ?.sort((a: GetAdditionalDoc, b: GetAdditionalDoc) => {
              // Convert the dates to timestamps for comparison
              const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
              const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;

              // Sort in descending order
              return dateB - dateA;
            })
            .find((doc: GetAdditionalDoc) => doc.additionalDocTypeId === targetDocType?.id);
          setAdditionalDoc(doc);
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  }

  return (
    <>
      <DataBox
        label={props.label}
        hoverDescription={props.hoverDescription}
        value={additionalDoc?.label ?? ''}
        placeholder={t('common.noDocument')}
        suffixText={additionalDoc ? t('common.replace') : t('common.add')}
        suffixIcon='upload'
        suffixStyle='primary'
        onSuffixClick={addDocument}
        onClick={() => onClickDownloadDoc(additionalDoc)}
      />
      <input
        style={{ display: 'none' }}
        accept={targetDocType ? targetDocType.extensions : '.pdf,.png,.jpg,.jpeg,.gif,.bmp,.tiff,.tif'}
        ref={inputFile}
        onChange={handleFileUpload}
        type='file'
      />
    </>
  );
});

ManageDocument.displayName = 'ManageDocument';
