import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Product } from '@dune-manager/backend-core/dist/models';
import { useTabFilters } from '../../../hooks/useTabFilters';
import TabFilter from '../TabFilter';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import { GetProduct, GetProductParams, GetProductPayload } from '../../../models/Product';
import Switch from 'react-switch';
import useToast from '../../../hooks/useToast';
import { ToastTypes } from '../../../models/ToastTypes';
import { isActiveList } from '../../../models';
import { usePagination } from '../../../hooks/usePagination';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { changeProductStatus, fetchProducts } from '../../../services/product';

interface ListProductsProps {
  canAddNew: boolean;
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
}

const ListProducts = React.memo((props: ListProductsProps) => {
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const { currentPage, perPage, paginationSetValues, changePaginationTabValue } = usePagination();
  const defaultTab = props.defaultStatus ?? 'Tous';
  const { filtersTab } = useTabFilters(defaultTab);
  const history = useNavigate();

  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('label');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);

  const [filteredItems, setFilteredItems] = useState<GetProduct[]>([]);
  const [isActive, setIsActive] = useState<isActiveList>({});

  const fetchItems = async (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    tabFilters: string[] = filtersTab,
    advancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setIsLoading(true);
      setFilteredItems([]);

      if (newSortColumn != sortColumn) {
        setSortColumn(newSortColumn);
      }

      if (newSortDirection != sortDirection) {
        setSortDirection(newSortDirection);
      }

      const { params, payload } = generateFilters({
        page,
        limit: newPerPage,
        sortBy: newSortColumn,
        orderBy: newSortDirection,
        tabFilters,
        advancedFilters,
      });

      const { items, total } = await fetchProducts({
        accessToken: token,
        orgid,
        params,
        payload,
      });
      setTotalRows(total);
      setFilteredItems(items);

      const activeList = items.reduce<isActiveList>((acc, product) => {
        acc[product.id] = product.isActive;
        return acc;
      }, {});
      setIsActive(activeList);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const generateFilters = ({
    tabFilters,
    advancedFilters,
    page,
    limit,
    sortBy,
    orderBy,
  }: {
    page: number;
    limit: number;
    sortBy: string;
    orderBy: string;
    tabFilters: string[];
    advancedFilters: AdvancedFilter[];
  }) => {
    const payload: GetProductPayload = {};
    const params: GetProductParams = {
      page,
      limit,
      sortBy,
      orderBy,
    };

    if (tabFilters && tabFilters?.length > 0) payload.isActive = tabFilters[0] === 'Actif';

    if (advancedFilters.length > 0) {
      advancedFilters.map((filter) => {
        if (filter.filterType === 'search') params.search = filter.filterData;
        if (filter.filterType === 'productType') payload.productTypeId = filter.filterData?.value;
        if (filter.filterType === 'labelAndReference') {
          payload.externalReference = filter.filterData;
          payload.label = filter.filterData;
        }
      });
    }

    return { payload, params };
  };

  const handlePageChange = (pageNumber: number) => {
    paginationSetValues(pageNumber, perPage);
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    paginationSetValues(1, newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortProduct: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortProduct = 'desc';
      } else {
        newSortProduct = 'asc';
      }
    } else {
      newSortProduct = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortProduct);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[], resetPagination: boolean) {
    setFiltersAdvanced(newFilters);
    fetchItems(resetPagination ? 1 : currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  const seeDetails = (row: GetProduct) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'products';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.label'),
      selector: (row: GetProduct) => row.label,
      cell: (row: GetProduct) => (
        <Link to={seeDetails(row)}>
          <div className='column-align-left' data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.label}
            </strong>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'label',
      grow: 1,
    },
    {
      name: t('common.reference'),
      selector: (row: Product) => `${row.externalReference}`,
      cell: (row: Product) => (
        <div className='column-align-left' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.externalReference}
            </strong>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'externalReference',
      grow: 1,
    },
    {
      name: t('common.type'),
      selector: (row: Product) => row.productType,
      cell: (row: Product) => (
        <div className='column-align-left' data-tag='allowRowEvents'>
          <strong className='caption1' data-tag='allowRowEvents'>
            {row.productType}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'productType',
    },
    {
      name: t('common.unit'),
      selector: (row: Product) => row.unit,
      cell: (row: Product) => (
        <div className='produit-column-label' data-tag='allowRowEvents'>
          <strong className='caption1' data-tag='allowRowEvents'>
            {t('unit.' + row.unit)}
          </strong>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'unit',
    },
    {
      name: t('common.active'),
      selector: (row: Product) => row.isActive,
      cell: (row: Product) => (
        <Switch onChange={onChangeSwitch} checked={isActive[row.id] ?? false} id={row.id} onColor={'#2a85ff'} />
      ),
      sortable: true,
      right: false,
      id: 'isActive',
    },
  ];

  const onChangeSwitch = async (
    checked: boolean,
    _: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string,
  ) => {
    try {
      if (!token || !orgid) {
        return;
      }
      await changeProductStatus({ accessToken: token, orgid, carrierId: id });
      addToast(t('common.updatedProduct'), ToastTypes.success);
      setIsActive((prevState) => ({ ...prevState, [id]: checked }));
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  };

  function handleSetFiltersStatus(newFilters: string[], tabOption: string) {
    const { page: newPage, perPage: newPerPage } = changePaginationTabValue(tabOption);
    fetchItems(newPage, newPerPage, sortColumn, sortDirection, newFilters, filtersAdvanced);
  }

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={['labelAndReference', 'productType']}
        showAddNew={true}
        addNewText={t('common.newProduct')}
        addNew={() => {
          history('./add');
        }}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['labelAndReference']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />

      <TabFilter
        tabArray={[
          { tabFilter: 'Tous', tabLabelTranslate: t('common.all') },
          { tabFilter: 'Actif', tabLabelTranslate: t('common.active') },
        ]}
        filterSet={handleSetFiltersStatus}
        defaultTab={defaultTab}
      />

      {isLoading ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => history(seeDetails(row))}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noProduct')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

ListProducts.displayName = 'ListProducts';
export default ListProducts;
