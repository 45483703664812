import { createInstance } from '../Config';
import { GetCarrierParams, GetCarrier, CarrierSave } from '../models/Carrier';

const instance = createInstance({ isExtranet: true });

export const fetchCarriers = async ({
  accessToken,
  orgid,
  params,
}: {
  accessToken: string;
  orgid: string;
  params: GetCarrierParams;
}): Promise<{ items: GetCarrier[]; total: number }> => {
  const response = await instance.get(`${orgid}/carrier/get`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params,
  });

  return { items: response.data.content.items, total: response.data.meta.total };
};

export const fetchCarrierDetails = async ({
  accessToken,
  orgid,
  carrierId,
}: {
  accessToken: string;
  orgid: string;
  carrierId: string;
}): Promise<GetCarrier> => {
  const response = await instance.get(`${orgid}/carrier/detail/${carrierId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content;
};

export const changeCarrierStatus = async ({
  accessToken,
  orgid,
  carrierId,
}: {
  accessToken: string;
  orgid: string;
  carrierId: string;
}): Promise<void> => {
  await instance.get(`${orgid}/carrier/change-activation/${carrierId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const createCarrier = async ({
  accessToken,
  orgid,
  payload,
}: {
  accessToken: string;
  orgid: string;
  payload: CarrierSave;
}): Promise<{ uuid: string }> => {
  const response = await instance.post(
    `${orgid}/carrier/add`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};

export const updateCarrier = async ({
  accessToken,
  orgid,
  carrierId,
  payload,
}: {
  accessToken: string;
  orgid: string;
  carrierId: string;
  payload: CarrierSave;
}): Promise<void> => {
  await instance.post(
    `${orgid}/carrier/edit/${carrierId}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};
