import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/useToast';
import Config from '../../../Config';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import moment from 'moment';
import { Icon } from '../../../stories/dune/atoms/Icon';
import fileDownload from 'js-file-download';
import {
  AdditionalDocRemove as AdditionalDocRemove,
  AdditionalDocSave,
  GetAdditionalDoc,
  GetAdditionalDocParameters,
} from '../../../models/AdditionalDoc';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import TabFilter from '../TabFilter';
import { AdditionalDocType, DocTypeIds } from '../../../models/AdditionalDocType';
import { useSelector } from 'react-redux';
import { StaticDataState } from '../../../store/staticData-slice';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import { GlobalParameter } from '../../../models/GlobalParameter';
interface ListAdditionalDocsProps {
  entityType:
    | 'quotation'
    | 'order'
    | 'invoice'
    | 'trip'
    | 'customer'
    | 'building_site'
    | 'carrier'
    | 'vehicle'
    | 'product'
    | 'zone';
  entityId: string;
  canAddNew: boolean;
  canDeleteAny?: boolean;
  forcedFilters?: AdvancedFilter[];
}

const ListAdditionalDocs = React.memo((props: ListAdditionalDocsProps) => {
  // #region intializing constants

  // #region generic
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const history = useNavigate();
  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);

  const [sortColumn, setSortColumn] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('asc');

  // #endregion generic

  // #region specific
  const [filteredItems, setFilteredItems] = useState<GetAdditionalDoc[]>([]);
  const inputFile = useRef<HTMLInputElement>(null);

  const docTypes = useSelector((state: { staticData: StaticDataState }) => state.staticData.additionalDocTypes);

  const [isOpenConfirmDeleteFile, setIsOpenConfirmDeleteFile] = useState(false);
  const [currentFileToDelete, setCurrentFileToDelete] = useState<GetAdditionalDoc>();

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
    fetchItems();
  }, []);

  const fetchItems = (AdvancedFilters: AdvancedFilter[] = filtersAdvanced) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/additional-doc/get';

    setIsLoading(true);
    setFilteredItems([]);

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...getFiltersToSend(AdvancedFilters) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        const itemsWithDetail = res.data.content.map((x: GetAdditionalDoc) => {
          x.additionalDocType = docTypes.find((y) => y.id === x.additionalDocTypeId) as AdditionalDocType;
          return x;
        });
        setFilteredItems(itemsWithDetail);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function getFiltersToSend(advancedFilters: AdvancedFilter[]) {
    const toSend: GetAdditionalDocParameters = { entityId: props.entityId, additionalDocEntity: props.entityType };

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'additionalDocType') toSend.additionalDocTypeId = x.filterData?.value;
      });
    }

    return toSend;
  }

  const columns: any[] = [
    {
      name: t('common.updatedAt'),
      selector: (row: GetAdditionalDoc) => row.lastModificationAt,
      cell: (row: GetAdditionalDoc) => (
        <div className='column'>
          <div>
            <div className='caption2medium'>
              {moment(row.lastModificationAt).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm')}
            </div>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'updatedAt',
    },
    {
      name: t('common.updatedBy'),
      selector: (row: GetAdditionalDoc) => row.lastModificationBy,
      cell: (row: GetAdditionalDoc) => (
        <div className='column'>
          <div>
            <strong className='caption2medium'>
              {row.lastModificationBy !== row.origin
                ? row.lastModificationBy + ' - ' + row.origin
                : row.lastModificationBy}
            </strong>
          </div>
        </div>
      ),
      sortable: false,
      left: true,
      id: 'updatedBy',
      grow: 2,
    },
    {
      name: t('common.label'),
      selector: (row: GetAdditionalDoc) => row.label,
      cell: (row: GetAdditionalDoc) => (
        <div className='column-align-left'>
          <div>
            <strong className='caption2medium'>{row.label}</strong>
          </div>
        </div>
      ),
      sortable: false,
      center: false,
      id: 'label',
      grow: 2,
    },
    {
      name: t('common.documentType'),
      selector: (row: GetAdditionalDoc) => row.additionalDocType,
      cell: (row: GetAdditionalDoc) => (
        <div className='column'>
          <div>
            <strong className='caption2medium'>{row.additionalDocType?.description}</strong>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'additionalDocType',
    },
    {
      name: '',
      sortable: false,
      cell: (row: GetAdditionalDoc) => (
        <div
          className='column-last'
          onClick={() => {
            onClickDownloadDoc(row);
          }}
        >
          <Icon icon={'download'} style={'secondary'} />
        </div>
      ),
      button: true,
      right: true,
      id: 'download',
      minWidth: '3%',
      grow: 1,
    },
    {
      name: '',
      sortable: false,
      cell: (
        row: GetAdditionalDoc, // only 'autre document' can be deleted
      ) => (
        <>
          {(props.canDeleteAny || row.additionalDocTypeId === DocTypeIds.OTHER_DOCUMENT) && (
            <div
              className='column-last'
              onClick={() => {
                onClickDeleteDoc(row);
              }}
            >
              <Icon icon={'delete'} style={'secondary'} />
            </div>
          )}
        </>
      ),
      button: true,
      right: true,
      id: 'delete',
      minWidth: '3%',
      grow: 1,
    },
  ];

  // #endregion specific

  // #endregion intializing constants

  // #region event handling

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems();
  };

  async function onClickDownloadDoc(row: GetAdditionalDoc) {
    const data = await axios({
      url: row.url, // your url
      method: 'GET',
      responseType: 'blob', // important
    });

    fileDownload(data.data, row.label);
    return data;
  }

  async function onClickDeleteDoc(row: GetAdditionalDoc) {
    setIsOpenConfirmDeleteFile(true);
    setCurrentFileToDelete(row);
  }

  const onDeleteFileOk = (doc: GetAdditionalDoc) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const dataToSend: AdditionalDocRemove = {
      entityId: props.entityId,
      additionalDocEntity: props.entityType,
    };

    const url = orgid + '/additional-doc' + `/${doc.id}/remove`;
    axios
      .post(Config.getApiExtranetUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async () => {
        fetchItems();
        addToast(t('common.fileRemoved'), ToastTypes.success);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const OpenAddFile = () => {
    inputFile?.current?.click();
  };

  const handleFileUpload = async (e: { target: { files: any } }) => {
    const { files } = e.target;
    if (files && files.length) {
      const allowedExtensions =
        filtersAdvanced && filtersAdvanced.length > 0
          ? filtersAdvanced[0].filterData?.data?.extensions.split(',')
          : ['.pdf', '.png', '.jpg', '.jpeg', '.gif', '.bmp', '.tiff', '.tif'];
      const fileExtension = '.' + files[0].name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Show an error message
        alert('Invalid file extension');
        // Clear the file input
        e.target.files = '';
      } else {
        await onAddFileOk(files[0]); // object fichier
      }
    }
  };

  const toBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // you could also read images and other binaries
      reader.onload = () => resolve((reader.result as string).split(',')[1]); // prendre le Base64
      reader.onerror = (error) => reject(error);
    });

  const onAddFileOk = async (doc: any) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const dataToSend: AdditionalDocSave = {
      fileName: doc.name,
      additionalDocTypeId:
        filtersAdvanced && filtersAdvanced.length > 0
          ? filtersAdvanced[0].filterData?.value
          : '1baf5bec-064f-4d79-987a-ae4f3e7f40f4', // TODO INTEGRATION mettre id type document ** Il faudra faire par type ** (pour l'instant 'autre document')
      autoLinkToTrip: false,
      autoLinkAtTripStart: false,
      autoLinkAtTripEnd: false,
      displayAtTripStart: false,
      displayAtTripEnd: false,
      additionalDocEntity: props.entityType,
      entityId: props.entityId,
      origin: 'extranet',
      fileBody: await toBase64(doc),
    };

    const url = orgid + '/additional-doc/add';
    axios
      .post(Config.getApiExtranetUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async () => {
        fetchItems();
        addToast(t('common.fileAdded'), ToastTypes.success);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[], resetPagination: boolean) {
    setFiltersAdvanced(newFilters);
  }

  // #endregion event handling

  let messagePopup = <></>;
  if (isOpenConfirmDeleteFile) {
    messagePopup = (
      <PopupContainer
        message={`${t('common.confirmDelete')} '${currentFileToDelete?.label}' ?`}
        onCancel={function (): void {
          setIsOpenConfirmDeleteFile(false);
        }}
        onConfirm={function (): void {
          setIsOpenConfirmDeleteFile(false);
          if (currentFileToDelete) onDeleteFileOk(currentFileToDelete);
        }}
        confirmLabel={t('common.yes')}
        confirmIcon='delete'
        cancelLabel={t('common.no')}
      />
    );
  }

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={false} // always hide
        allowAdvancedSearch={false} // always hide
        advancedSearchOptions={['additionalDocType']}
        showAddNew={props.canAddNew}
        addNewText={t('common.addDoc')}
        addNew={() => {
          OpenAddFile();
        }}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['additionalDocType']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />
      <input
        style={{ display: 'none' }}
        accept={
          filtersAdvanced && filtersAdvanced.length > 0
            ? filtersAdvanced[0].filterData?.data?.extensions
            : '.pdf,.png,.jpg,.jpeg,.gif,.bmp,.tiff,.tif'
        }
        ref={inputFile}
        onChange={handleFileUpload}
        type='file'
      />
      {isLoading ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noResult')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
        />
      )}
      {messagePopup}
    </>
  );
});

ListAdditionalDocs.displayName = 'ListAdditionalDocs';
export default ListAdditionalDocs;
