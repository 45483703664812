import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useToast from '../../../hooks/useToast';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralProduct from './GeneralProduct';
import { GetProduct } from '../../../models/Product';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import SubMenu from '../../forms/SubMenu';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { fetchProductDetails } from '../../../services/product';
import { handleApiError } from '../../../utils/apiErrorHandler';

interface DetailProductProps {
  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailProduct = React.memo((props: DetailProductProps) => {
  // #region generic

  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
  };

  const { id, mode } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [productDetails, setProductDetails] = useState<GetProduct>();

  useEffect(() => {
    const fetchCurrentProductDetails = async (id?: string) => {
      if (!id || getMode() !== 'update' || !token || !orgid) {
        return;
      }

      try {
        setIsLoading(true);

        const fetchedProductDetails = await fetchProductDetails({
          accessToken: token,
          orgid,
          productId: id,
        });
        setProductDetails(fetchedProductDetails);
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrentProductDetails(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralProduct
              mode={getMode()}
              productData={productDetails}
              onCancel={onCanceled}
              onConfirm={onConfirmed}
            />
          )}

          {getMode() !== 'add' && (
            <>
              <SubMenu
                tab={currentTab}
                updateTab={(mode: string) => updateCurrentTab(mode)}
                tabs={[
                  { key: 'general', label: t('common.generalInformations') },
                  { key: 'documents', label: t('common.documents') },
                ]}
              />
              {currentTab === 'general' && (
                <GeneralProduct
                  productId={id}
                  mode={getMode()}
                  productData={productDetails}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}
              {currentTab === 'documents' && productDetails && (
                <ListAdditionalDocs entityType='product' entityId={productDetails.id ?? ''} canAddNew={true} />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/products');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/products');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newProduct')
              : t('common.product') +
                ' ' +
                (!productDetails
                  ? ''
                  : productDetails.label +
                    (productDetails.externalReference ? '-' + productDetails.externalReference : ''))
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='products'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newProduct')
                  : t('common.product') +
                    ' ' +
                    (!productDetails
                      ? ''
                      : productDetails.label +
                        (productDetails.externalReference ? '-' + productDetails.externalReference : ''))
              }
              back={true}
              Back={function (): void {
                history('/products');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

// #region // TODO zone produits priorité

DetailProduct.displayName = 'DetailProduct';
export default DetailProduct;
