import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useToast from '../../../hooks/useToast';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralBuildingSite from './GeneralBuildingSite';
import { GetBuildingSite } from '../../../models/BuildingSite';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListTrips from '../../lists/Trips/ListTrips';
import ListOrders from '../../lists/Orders/ListOrders';
import ListContacts from '../../lists/Contacts/ListContacts';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import BuildingSiteContract from './BuildingSiteContract';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { fetchBuildingSiteDetails } from '../../../services/buildingSite';
import { handleApiError } from '../../../utils/apiErrorHandler';
import SubMenu from '../../forms/SubMenu';

interface DetailBuildingSiteProps {
  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
  dataParent?: string;
}

const DetailBuildingSite = React.memo((props: DetailBuildingSiteProps) => {
  // #region generic

  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    tab?: string;
  };

  const { id, mode } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [buildingSiteDetails, setBuildingSiteDetails] = useState<GetBuildingSite>();

  useEffect(() => {
    const fetchCurrentBuildingSite = async (id?: string) => {
      if (!id || getMode() !== 'update' || !token || !orgid) {
        return;
      }

      try {
        setIsLoading(true);

        const fetchedBuildingSiteDetails = await fetchBuildingSiteDetails({
          accessToken: token,
          orgid,
          buildingSiteId: id,
        });
        setBuildingSiteDetails(fetchedBuildingSiteDetails);
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrentBuildingSite(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralBuildingSite
              mode={getMode()}
              buildingSiteData={buildingSiteDetails}
              onCancel={onCanceled}
              onConfirm={onConfirmed}
              dataParent={props.dataParent}
            />
          )}

          {getMode() !== 'add' && (
            <>
              <SubMenu
                tab={currentTab}
                updateTab={(mode: string) => updateCurrentTab(mode)}
                tabs={[
                  { key: 'general', label: t('common.generalInformations') },
                  { key: 'contract', label: t('common.contracts') },
                  { key: 'order', label: t('common.orders') },
                  { key: 'trip', label: t('common.trips') },
                  { key: 'documents', label: t('common.documents') },
                  { key: 'contact', label: t('common.contacts') },
                ]}
              />
              {currentTab === 'general' && (
                <GeneralBuildingSite
                  buildingSiteId={id}
                  mode={getMode()}
                  buildingSiteData={buildingSiteDetails}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}
              {currentTab === 'contract' && id && (
                <BuildingSiteContract buildingSite={buildingSiteDetails} onCancel={onCanceled} />
              )}
              {currentTab === 'order' && id && (
                <ListOrders
                  canAddNew={true}
                  forcedFilters={[
                    {
                      filterType: 'buildingSite',
                      filterData: {
                        value: buildingSiteDetails?.id,
                        label:
                          buildingSiteDetails?.label +
                          (buildingSiteDetails?.externalReference
                            ? ' - ' + buildingSiteDetails?.externalReference
                            : ''),
                        data: buildingSiteDetails,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'trip' && id && (
                <ListTrips
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'buildingSite',
                      filterData: {
                        value: buildingSiteDetails?.id,
                        label:
                          buildingSiteDetails?.label +
                          (buildingSiteDetails?.externalReference
                            ? ' - ' + buildingSiteDetails?.externalReference
                            : ''),
                        data: buildingSiteDetails,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'documents' && buildingSiteDetails && (
                <ListAdditionalDocs
                  entityType='building_site'
                  entityId={buildingSiteDetails.id ?? ''}
                  canAddNew={true}
                />
              )}
              {currentTab === 'contact' && id && (
                <ListContacts
                  contactEntity={ContactEntity.BUILDING_SITE}
                  entityId={id}
                  canAdd={true}
                  canEdit={true}
                  canRemove={true}
                  canSelect={false}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/buildingSites');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/buildingSites');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newJobSite')
              : t('common.buildingSite') +
                ' ' +
                (!buildingSiteDetails
                  ? ''
                  : buildingSiteDetails.label +
                    (buildingSiteDetails.externalReference ? '-' + buildingSiteDetails.externalReference : ''))
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='buildingSites'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newJobSite')
                  : t('common.jobSite') +
                    ' ' +
                    (!buildingSiteDetails
                      ? ''
                      : buildingSiteDetails.label +
                        (buildingSiteDetails.externalReference ? '-' + buildingSiteDetails.externalReference : ''))
              }
              back={true}
              Back={function (): void {
                history('/buildingSites');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

DetailBuildingSite.displayName = 'DetailBuildingSite';
export default DetailBuildingSite;
