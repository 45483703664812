import { createInstance } from '../Config';
import { ContactSave } from '../models/Contact';

const instance = createInstance({ isExtranet: true });

export const createContact = async ({
  accessToken,
  orgid,
  payload,
}: {
  accessToken: string;
  orgid: string;
  payload: ContactSave;
}): Promise<{ uuid: string }> => {
  const response = await instance.post(
    `${orgid}/contact/add`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};
