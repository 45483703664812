import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import { Title } from '../../../stories/dune/atoms/Title';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/useToast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import Switch from 'react-switch';
import stringUtils from '../../../utils/stringUtils';
import { GetVehicle, GetVehicleInfo, VehicleInfoSave, VehicleSave } from '../../../models/Vehicle';
import { SelectAxleNumber, SelectAxleNumberOption } from '../../forms/SelectAxleNumber';
import { SelectCarrier, SelectCarrierOption } from '../../forms/SelectCarrier';
import { SelectVehicleType, SelectVehicleTypeOption } from '../../forms/SelectVehicleType';
import moment from 'moment';
import { GlobalParameter } from '../../../models/GlobalParameter';
import { GetCarrier } from '../../../models/Carrier';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { handleApiError } from '../../../utils/apiErrorHandler';
import {
  createVehicle,
  createVehicleInfo,
  fetchVehicleDetailsByRegistrationPlate,
  updateVehicle,
  updateVehicleInfo,
} from '../../../services/vehicle';

interface GeneralVehicleProps {
  vehicleId?: string;
  vehicleData?: GetVehicle;
  vehicleInfoData?: GetVehicleInfo;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  mode: string;
}

interface IFormInputs {
  registrationPlate?: string;
  trailerPlate?: string;
  vehicleType?: {
    value: string;
    label: string;
  };
  ptacMax?: number;
  maxOfPTAC?: number;
  tareWeight?: number;
  maxWeight?: number;
  dryWeight?: number;
  carrier?: {
    value: string;
    label: string;
  };
  axleNumber?: number;
  sixthAxleAdditionalWeight?: number;
  hasInductionBreak?: boolean;
  inductionBreakAdditionalWeight?: number;
  hasAlternativeMotorSystem?: boolean;
  alternativeMotorSystemAdditionalWeight?: number;
}

const GeneralVehicle = React.memo(
  ({ vehicleId, mode, vehicleData, vehicleInfoData, onConfirm, onCancel }: GeneralVehicleProps) => {
    const history = useNavigate();
    const { addToast } = useToast();
    const { token, orgid } = useAuthGuard();
    const isEditMode = mode === 'update';

    const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
    useEffect(() => {
      const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
      setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
    }, []);

    const [saveVehicle, setSaveVehicle] = useState<VehicleSave>();
    const [saveVehicleInfo, setSaveVehicleInfo] = useState<VehicleInfoSave>();

    const [isActive, setIsActive] = useState<boolean>(true);
    const [carrierOption, setCarrierOption] = useState<SelectCarrierOption>();

    const [registrationPlate, setRegistrationPlate] = useState<string>();
    const [trailerPlate, setTrailerPlate] = useState<string>();

    const [ptacMax, setPtacMax] = useState<number>();
    const [maxWeight, setMaxWeight] = useState<number>();
    const [dryWeight, setDryWeight] = useState<number>();
    const [tareWeight, setTareWeight] = useState<string>();
    const [lastTareDate, setLastTareDate] = useState<Date>();
    const [dsdTare, setDsdTare] = useState<string>();

    const [vehicleTypeOption, setVehicleTypeOption] = useState<SelectVehicleTypeOption>();

    const [inductionBreakAdditionalWeight, setInductionBreakAdditionalWeight] = useState<number>();
    const [hasInductionBreak, setHasInductionBreak] = useState<boolean>(false);

    const [sixthAxleAdditionalWeight, setSixthAxleAdditionalWeight] = useState<number>();

    const [alternativeMotorSystemAdditionalWeight, setAlternativeMotorSystemAdditionalWeight] = useState<number>();
    const [hasAlternativeMotorSystem, setHasAlternativeMotorSystem] = useState<boolean>(false);

    const [selectedOptionAxleNumber, setSelectedOptionAxleNumber] = useState<SelectAxleNumberOption>();
    const [optionsAxleNumber, setOptionsAxleNumber] = useState<any[]>([]);

    const schema = yup.object().shape({
      registrationPlate: yup
        .string()
        .required(t('common.requiredRegistrationPlate'))
        .max(50, t('common.lengthRegistrationPlate'))
        .matches(/^[a-zA-Z0-9]+$/, { message: t('common.onlyAlphanumeric') }),
      inductionBreakAdditionalWeight: yup
        .number()
        .nullable()
        .notRequired()
        .min(0, t('common.valueBetween') + '0 ' + t('common.and') + ' 0,5')
        .max(0.5, t('common.valueBetween') + '0 ' + t('common.and') + ' 0,5'),
      alternativeMotorSystemAdditionalWeight: yup
        .number()
        .nullable()
        .notRequired()
        .min(0, t('common.valueBetween') + '0 ' + t('common.and') + ' 1')
        .max(1, t('common.valueBetween') + '0 ' + t('common.and') + ' 1'),
      sixthAxleAdditionalWeight: yup
        .number()
        .nullable()
        .notRequired()
        .min(0, t('common.valueBetween') + '0 ' + t('common.and') + ' 1')
        .max(1, t('common.valueBetween') + '0 ' + t('common.and') + ' 1')
        .transform((curr, orig) => (orig === '' ? null : curr)),
      trailerPlate: yup
        .string()
        .nullable()
        .notRequired()
        .matches(/^[a-zA-Z0-9]+$/, { message: t('common.onlyAlphanumeric'), excludeEmptyString: true }),
      vehicleType: yup.object().shape({
        value: yup.string().required(t('common.requiredTypeVehicle')),
        label: yup.string().required(t('common.requiredTypeVehicle')),
      }),
      ptacMax: yup
        .number()
        .min(0, t('common.positiveValuePTAC'))
        .max(yup.ref('maxOfPTAC'), t('common.highValuePTAC'))
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
      tareWeight: yup
        .number()
        .notRequired()
        .min(0, t('common.positiveValueTare'))
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr)),
      dryWeight: yup
        .number()
        .notRequired()
        .min(0, t('common.positiveValueDryWeight'))
        .nullable()
        .max(yup.ref('ptacMax'), t('common.dryWeightLowerThanPTAC'))
        .transform((curr, orig) => (orig === '' ? null : curr)),
    });

    const {
      setValue,
      register,
      formState: { errors },
      handleSubmit,
    } = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      reValidateMode: 'onBlur',
    });

    useEffect(() => {
      if (vehicleData) {
        // on initialise SaveCustomer pour ne pas perdre les données non-gérées lors de la sauvegarde
        setSaveVehicle({
          ...vehicleData,
        });

        setIsActive(vehicleData?.isActive ?? true);
        if (vehicleData?.carrier) {
          setCarrierOption({
            value: vehicleData?.carrier.id ?? '',
            label: vehicleData?.carrier.label ?? '',
            data: vehicleData?.carrier as GetCarrier, // Temp to fix type before refacto
          });
          setValue('carrier', {
            value: vehicleData?.carrier.id ?? '',
            label: vehicleData?.carrier.label ?? '',
          });
        }

        setRegistrationPlate(vehicleData?.registrationPlate);
        setValue('registrationPlate', vehicleData?.registrationPlate);
        setTrailerPlate(vehicleData?.trailerPlate);
        setValue('trailerPlate', vehicleData?.trailerPlate);

        // setRegistrationPlate((vehicleData?.registrationPlate ?? '-').split('-')[0]);
        // setValue('registrationPlate', (vehicleData?.registrationPlate ?? '-').split('-')[0]);
        // setTrailerPlate((vehicleData?.registrationPlate ?? '-').split('-')[1]);
        // setValue('trailerPlate', (vehicleData?.registrationPlate ?? '-').split('-')[1]);

        setTareWeight(vehicleData?.tareWeight ? vehicleData?.tareWeight.toString() : '');
        setValue('tareWeight', vehicleData?.tareWeight);
        setLastTareDate(vehicleData?.lastTareDate);
        setDsdTare(vehicleData?.dsdTare);
      }

      if (vehicleInfoData) {
        setSaveVehicleInfo({
          ...vehicleInfoData,
        });
        if (vehicleInfoData?.vehicleType) {
          setVehicleTypeOption({
            value: vehicleInfoData?.vehicleType.id ?? '',
            label: vehicleInfoData?.vehicleType.label ?? '',
            data: vehicleInfoData?.vehicleType,
          });
          setValue('vehicleType', {
            value: vehicleInfoData?.vehicleType.id ?? '',
            label: vehicleInfoData?.vehicleType.label ?? '',
          });
        }

        setPtacMax(vehicleInfoData?.ptacMax);
        setValue('ptacMax', vehicleInfoData?.ptacMax);
        setDryWeight(vehicleInfoData?.dryWeight);
        setValue('dryWeight', vehicleInfoData?.dryWeight);

        setMaxWeight(vehicleInfoData?.vehicleType?.maxWeight);
        setValue('maxWeight', vehicleInfoData?.vehicleType?.maxWeight);
        setSelectedOptionAxleNumber({
          value: (vehicleInfoData?.axleNumber ?? 0).toString(),
          label: (vehicleInfoData?.axleNumber ?? 0).toString(),
        });
        setValue('axleNumber', vehicleInfoData?.axleNumber);
        setSixthAxleAdditionalWeight(vehicleInfoData?.sixthAxleAditionalWeight);
        setValue('sixthAxleAdditionalWeight', vehicleInfoData?.sixthAxleAditionalWeight);

        setHasInductionBreak(vehicleInfoData?.hasInductionBreak ?? false);
        setValue('hasInductionBreak', vehicleInfoData?.hasInductionBreak);
        setInductionBreakAdditionalWeight(vehicleInfoData?.inductionBreakAdditionalWeight);
        setValue('inductionBreakAdditionalWeight', vehicleInfoData?.inductionBreakAdditionalWeight);
        setHasAlternativeMotorSystem(vehicleInfoData?.hasAlternativeMotorSystem ?? false);
        setValue('hasAlternativeMotorSystem', vehicleInfoData?.hasAlternativeMotorSystem);
        setAlternativeMotorSystemAdditionalWeight(vehicleInfoData?.alternativeMotorSystemAdditionalWeight);
        setValue('alternativeMotorSystemAdditionalWeight', vehicleInfoData?.alternativeMotorSystemAdditionalWeight);
      }
    }, [vehicleData, vehicleInfoData]);

    useEffect(() => {
      // set SelectedOptionAxleNumber to first value to prevent empty value by mistake
      if (optionsAxleNumber?.length > 0)
        setSelectedOptionAxleNumber({
          value: optionsAxleNumber[0].toString(),
          label: optionsAxleNumber[0].toString(),
        });
    }, [optionsAxleNumber]);

    const onSubmit = async () => {
      try {
        if (!token || !orgid) {
          return;
        }

        const payloadVehicle: VehicleSave = stringUtils.formatFieldsForPost({
          ...saveVehicle,
          registrationPlate: registrationPlate,
          // registrationPlate: registrationPlate + (trailerPlate ? '-' + trailerPlate : ''),
          trailerPlate,
          tareWeight: parseFloat(tareWeight ?? '0'),
          lastTareDate,
          dsdTare,
          carrierId: saveVehicle?.carrierId,
          isActive,
        });

        const payloadVehicleInfo: VehicleInfoSave = stringUtils.formatFieldsForPost({
          ...saveVehicleInfo,
          registrationPlate: registrationPlate,
          // registrationPlate: registrationPlate + (trailerPlate ? '-' + trailerPlate : ''),
          dryWeight,
          ptacMax,
          hasInductionBreak,
          inductionBreakAdditionalWeight,
          hasAlternativeMotorSystem,
          sixthAxleAditionalWeight: saveVehicleInfo?.axleNumber === 6 ? sixthAxleAdditionalWeight : undefined,
          vehicleTypeId: saveVehicleInfo?.vehicleTypeId,
        });

        if (isEditMode && vehicleId) {
          await updateVehicle({
            orgid,
            accessToken: token,
            vehicleId,
            payload: payloadVehicle,
          });

          if (vehicleInfoData?.id) {
            await updateVehicleInfo({
              orgid,
              accessToken: token,
              vehicleInfoId: vehicleInfoData.id,
              payload: payloadVehicleInfo,
            });
          }
        } else {
          const { uuid: vehicleUuid } = await createVehicle({ orgid, accessToken: token, payload: payloadVehicle });

          const fetchedVehicleInfo = await fetchVehicleDetailsByRegistrationPlate({
            accessToken: token,
            orgid,
            registrationPlate: registrationPlate ?? '',
          });

          if (!fetchedVehicleInfo) {
            await createVehicleInfo({
              orgid,
              accessToken: token,
              payload: payloadVehicleInfo,
            });
          }

          onConfirm(vehicleUuid);
        }
        addToast(t('common.vehicle' + (isEditMode ? 'Updated' : 'Created')), ToastTypes.success);
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      }
    };

    const setAxleNumberOptions = (cont: any[]) => {
      const optionsAxleNumber: any = [];
      cont.map((value) => {
        let intValue: string | number = value;
        if (typeof value !== 'number') {
          intValue = parseInt(value);
        }
        optionsAxleNumber.push({
          value: intValue,
          label: intValue,
        });
      });

      setOptionsAxleNumber(optionsAxleNumber);
      if (!optionsAxleNumber.find((x: any) => x.value === (saveVehicleInfo?.axleNumber ?? 0)))
        setSelectedOptionAxleNumber(optionsAxleNumber[0]);
    };

    const calculatePTACMAX = () => {
      setValue(
        'maxOfPTAC',
        (maxWeight ?? 0) +
          (inductionBreakAdditionalWeight ?? 0) +
          (alternativeMotorSystemAdditionalWeight ?? 0) +
          ((saveVehicleInfo?.axleNumber ?? 0).toString() === '6' ? sixthAxleAdditionalWeight ?? 0 : 0) +
          0.0001,
      );

      return (
        (maxWeight ?? 0) +
        (inductionBreakAdditionalWeight ?? 0) +
        (alternativeMotorSystemAdditionalWeight ?? 0) +
        ((saveVehicleInfo?.axleNumber ?? 0).toString() === '6' ? sixthAxleAdditionalWeight ?? 0 : 0)
      );
    };

    return !vehicleData && mode === 'update' ? (
      <div>Loading...</div>
    ) : (
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <CardDetail>
          <div className='form-section'>
            <FormRow>
              <Title label={t('common.generalInformations')} type='title1' />

              {mode === 'update' && (
                <FormRow align='right' alignVert='center'>
                  <h3 className='base2'>{t('common.active')}</h3>
                  <Switch
                    onChange={function () {
                      setIsActive((prevValue) => !prevValue);
                    }}
                    checked={isActive ?? false}
                    onColor={'#2a85ff'}
                  />
                </FormRow>
              )}
            </FormRow>
            <FormRow>
              <Input
                {...register('registrationPlate')}
                error={errors.registrationPlate?.message ?? ''}
                label={t('common.registrationPlate')}
                type='text'
                value={registrationPlate ?? ''}
                placeholder=''
                disabled={mode === 'update'}
                onChange={function (e): void {
                  setValue('registrationPlate', e.toString());
                  setRegistrationPlate(e.toString());
                }}
              />
              <Input
                {...register('trailerPlate')}
                error={errors.trailerPlate?.message ?? ''}
                label={t('common.trailerPlate')}
                type='text'
                value={trailerPlate ?? ''}
                placeholder=''
                disabled={mode === 'update'}
                onChange={function (e): void {
                  setValue('trailerPlate', e.toString());
                  setTrailerPlate(e.toString());
                }}
              />
              <SelectCarrier
                titleSize='normal'
                register={register}
                registerName='carrier'
                setValue={setValue}
                error={errors.carrier?.value?.message ?? errors.carrier?.label?.message ?? ''}
                isSelectable={true}
                selectedOptionChanged={(e: SelectCarrierOption) => {
                  if (e && e.value) {
                    setValue('carrier', {
                      value: e?.value,
                      label: e?.label,
                    });

                    setSaveVehicle((prevValue) => {
                      return { ...prevValue, carrierId: e?.value };
                    });
                  } else {
                    setValue('carrier', {
                      value: '',
                      label: '',
                    });

                    setSaveVehicle((prevValue) => {
                      return { ...prevValue, carrierId: undefined };
                    });
                  }
                }}
                forceSelectedOption={carrierOption}
              />
            </FormRow>
            <FormRow>
              <SelectVehicleType
                titleSize='normal'
                register={register}
                registerName='vehicleType'
                setValue={setValue}
                error={errors.vehicleType?.value?.message ?? errors.vehicleType?.label?.message ?? ''}
                isSelectable={true}
                selectedOptionChanged={(e: SelectVehicleTypeOption) => {
                  if (e && e.value) {
                    setValue('vehicleType', {
                      value: e?.value,
                      label: e?.label,
                    });

                    if (e?.data?.axles === null || e?.data?.axles === undefined) {
                      setAxleNumberOptions([2, 3, 4, 5, 6]);
                    } else {
                      setAxleNumberOptions(e.data.axles.split(';'));
                    }

                    if (e?.data?.maxWeight !== null && e?.data?.maxWeight !== undefined) {
                      setMaxWeight(e.data.maxWeight);
                      setValue('maxWeight', e.data.maxWeight);
                    } else {
                      setMaxWeight(undefined);
                      setValue('maxWeight', undefined);
                    }

                    if (e?.data?.defaultDryWeight !== null && e?.data?.defaultDryWeight) {
                      setDryWeight(e.data.defaultDryWeight);
                      setValue('dryWeight', e.data.defaultDryWeight);
                    }

                    setSaveVehicleInfo((prevValue) => {
                      return { ...prevValue, vehicleTypeId: e?.value };
                    });
                  } else {
                    setValue('vehicleType', {
                      value: '',
                      label: '',
                    });

                    setSaveVehicleInfo((prevValue) => {
                      return { ...prevValue, vehicleTypeId: undefined };
                    });
                  }

                  setOptionsAxleNumber(e?.data?.axles?.split(';') ?? vehicleTypeOption?.data?.axles?.split(';') ?? []);
                }}
                forceSelectedOption={vehicleTypeOption}
              />
              <Input
                {...register('ptacMax')}
                error={errors.ptacMax?.message ?? ''}
                label={
                  t('common.ptac') +
                  (calculatePTACMAX ? ' ( max. ' + Number(calculatePTACMAX().toFixed(2)) + ' T )' : '')
                }
                type='number'
                number={{ min: 0, max: calculatePTACMAX() ?? 0 + 0.0000001, step: 0.01 }}
                prefix='T'
                value={ptacMax ?? ''}
                placeholder='0'
                disabled={false}
                onChange={function (e): void {
                  if (e.toString() !== '') {
                    setValue('ptacMax', parseFloat(e?.toString() ?? '0'));
                    setPtacMax(parseFloat(e.toString() ?? '0'));
                  } else {
                    setValue('ptacMax', undefined);
                    setPtacMax(undefined);
                  }
                }}
              />
              <Input
                {...register('dryWeight')}
                error={errors.dryWeight?.message ?? ''}
                label={t('common.dryWeight')}
                type='number'
                number={{ min: 0, max: maxWeight ?? null, step: 0.01 }}
                prefix='T'
                value={dryWeight ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  if (e.toString() !== '') {
                    setDryWeight(parseFloat(e.toString() ?? '0'));
                  } else {
                    setDryWeight(undefined);
                  }
                }}
              />
            </FormRow>
            <FormRow>
              <Input
                {...register('tareWeight')}
                error={errors.tareWeight?.message ?? ''}
                label={t('common.lastTare')}
                hoverDescription={t('common.lastTareDesc')}
                type='number'
                number={{ min: 0, max: maxWeight ?? null, step: 0.01 }}
                prefix='T'
                value={tareWeight ?? ''}
                placeholder='0'
                disabled={false}
                onChange={function (e): void {
                  if (e.toString() !== '') {
                    setValue('tareWeight', parseFloat(e?.toString() ?? '0'));
                    setTareWeight(e.toString());
                    setLastTareDate(undefined);
                    setDsdTare(undefined);
                  } else {
                    setValue('tareWeight', undefined);
                    setTareWeight(undefined);
                    setLastTareDate(undefined);
                    setDsdTare(undefined);
                  }
                }}
              />
              <Input
                error={''}
                label={t('common.lastTareDate')}
                hoverDescription={t('common.lastTareDateDesc')}
                type={lastTareDate ? 'datetime-local' : 'text'}
                value={lastTareDate ? moment(lastTareDate).tz(siteTimeZone).format('YYYY-MM-DDTHH:mm') : ''}
                placeholder=''
                disabled={true}
                onChange={undefined}
              />
              <Input
                error={''}
                label={t('common.lastTareDSD')}
                hoverDescription={t('common.lastTareDSDDesc')}
                type={'text'}
                value={dsdTare ?? ''}
                placeholder=''
                disabled={true}
                onChange={undefined}
              />
            </FormRow>
          </div>

          <div className='form-section'>
            <FormRow>
              <Title label={t('common.maxWeightCalculation')} type='title1' />
            </FormRow>
            <FormRow>
              <Input
                {...register('maxWeight')}
                error={errors.maxWeight?.message ?? ''}
                label={t('common.theoreticalMaxWeight')}
                type='number'
                prefix='T'
                value={maxWeight ?? ''}
                placeholder=''
                disabled={true}
                onChange={function (e): void {
                  setMaxWeight(parseFloat(e.toString() ?? '0'));
                }}
              />
              <SelectAxleNumber
                titleSize='normal'
                titleOverride={t('common.axleNumber')}
                register={register}
                registerName='axleNumber'
                setValue={setValue}
                error={errors.axleNumber?.message ?? ''}
                isSelectable={true}
                selectedOptionChanged={(e: any) => {
                  if (e && e.value) {
                    setValue('axleNumber', e?.value);

                    setSaveVehicleInfo((prevValue) => {
                      return { ...prevValue, axleNumber: parseFloat(e?.value as string) };
                    });
                  } else {
                    setValue('axleNumber', 0);

                    setSaveVehicleInfo((prevValue) => {
                      return { ...prevValue, axleNumber: undefined };
                    });
                  }
                }}
                forceSelectedOption={selectedOptionAxleNumber}
                searchParent={optionsAxleNumber}
                dependsOnSearchParent={true}
              />

              {(saveVehicleInfo?.axleNumber ?? 0).toString() === '6' && (
                <Input
                  {...register('sixthAxleAdditionalWeight')}
                  error={errors.sixthAxleAdditionalWeight?.message ?? ''}
                  label={t('common.sixthAxleAdditionalWeight') + ' ( max. +1T )'}
                  type='number'
                  number={{ min: 0, max: 1, step: 0.01 }}
                  prefix='T'
                  value={sixthAxleAdditionalWeight ?? ''}
                  placeholder='0'
                  disabled={false}
                  onChange={function (e): void {
                    setValue('sixthAxleAdditionalWeight', parseFloat(e?.toString() ?? '0'));
                    setSixthAxleAdditionalWeight(parseFloat(e.toString() ?? '0'));
                  }}
                />
              )}
            </FormRow>
            <FormRow>
              <div className='dune-content-input dune-content-input-wide'>
                <div className='input__title'>
                  <input
                    type='checkbox'
                    {...register('hasAlternativeMotorSystem', {})}
                    checked={hasAlternativeMotorSystem === true}
                    onChange={(e) => setHasAlternativeMotorSystem(e?.target.checked)}
                  />
                  {t('common.alternativeMotor')}
                  <i>{' ( max. +1T )'}</i>
                </div>

                {hasAlternativeMotorSystem && (
                  <Input
                    {...register('alternativeMotorSystemAdditionalWeight')}
                    error={errors.alternativeMotorSystemAdditionalWeight?.message ?? ''}
                    type='number'
                    number={{ min: 0, max: 1, step: 0.01 }}
                    prefix='T'
                    value={alternativeMotorSystemAdditionalWeight ?? ''}
                    placeholder='0'
                    disabled={false}
                    onChange={function (e): void {
                      setValue('alternativeMotorSystemAdditionalWeight', parseFloat(e?.toString() ?? '0'));
                      setAlternativeMotorSystemAdditionalWeight(parseFloat(e.toString() ?? '0'));
                    }}
                  />
                )}
              </div>

              <div className='dune-content-input dune-content-input-wide'>
                <div className='input__title'>
                  <input
                    type='checkbox'
                    {...register('hasInductionBreak', {})}
                    checked={hasInductionBreak === true}
                    onChange={(e) => setHasInductionBreak(e?.target.checked)}
                  />
                  {t('common.inductionBreak')}
                  <i>{' ( max. +0.5T )'}</i>
                </div>

                {hasInductionBreak && (
                  <Input
                    {...register('inductionBreakAdditionalWeight')}
                    error={errors.inductionBreakAdditionalWeight?.message ?? ''}
                    type='number'
                    number={{ min: 0, max: 0.5, step: 0.01 }}
                    prefix='T'
                    value={inductionBreakAdditionalWeight ?? ''}
                    placeholder='0'
                    disabled={false}
                    onChange={function (e): void {
                      setValue('inductionBreakAdditionalWeight', parseFloat(e?.toString() ?? '0'));
                      setInductionBreakAdditionalWeight(parseFloat(e.toString() ?? '0'));
                    }}
                  />
                )}
              </div>
            </FormRow>
          </div>
          <FixBar>
            <Button label={t('common.return')} style='white' onClick={onCancel} />
            <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
          </FixBar>
        </CardDetail>
      </form>
    );
  },
);

GeneralVehicle.displayName = 'GeneralVehicle';
export default GeneralVehicle;
