import { createInstance } from '../Config';
import {
  GetQuotation,
  GetQuotationHistory,
  GetQuotationParams,
  GetQuotationPayload,
  QuotationStatus,
} from '../models/Quotation';

const instance = createInstance({ isExtranet: true });

export const fetchQuotations = async ({
  accessToken,
  orgid,
  params,
  payload,
}: {
  accessToken: string;
  orgid: string;
  params: GetQuotationParams;
  payload: GetQuotationPayload;
}): Promise<{ items: GetQuotation[]; total: number }> => {
  const response = await instance.post(
    `${orgid}/quotation/get`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    },
  );
  return { items: response.data.content.items, total: response.data.meta.total };
};

export const fetchQuotation = async ({
  accessToken,
  orgid,
  quotationId,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
}): Promise<GetQuotation> => {
  const response = await instance.get(`${orgid}/quotation/detail/${quotationId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content;
};

export const fetchQuotationHistories = async ({
  accessToken,
  orgid,
  quotationId,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
}): Promise<GetQuotationHistory[]> => {
  const response = await instance.get(`${orgid}/quotation/${quotationId}/quotation-histories/get`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content.items;
};

export const changeQuotationStatus = async ({
  accessToken,
  orgid,
  quotationId,
  status,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
  status: QuotationStatus;
}): Promise<void> => {
  const response = await instance.post(
    `${orgid}/quotation/${quotationId}/change-status`,
    {
      status,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};

export const generateQuotationPdf = async ({
  accessToken,
  orgid,
  quotationId,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
}): Promise<void> => {
  await instance.post(
    `${orgid}/quotation/${quotationId}/generate-pdf`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};
