import { createInstance } from '../Config';
import {
  BuildingSiteSave,
  GetBuildingSite,
  GetBuildingSiteParams,
  GetBuildingSitePayload,
} from '../models/BuildingSite';

const instance = createInstance({ isExtranet: true });

export const fetchBuildingSites = async ({
  accessToken,
  orgid,
  params,
  payload,
}: {
  accessToken: string;
  orgid: string;
  params: GetBuildingSiteParams;
  payload: GetBuildingSitePayload;
}): Promise<{ items: GetBuildingSite[]; total: number }> => {
  const response = await instance.post(
    `${orgid}/building-site/get`,
    {
      ...payload,
      onlyActive: payload.onlyActive ? 'true' : 'false',
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    },
  );
  return { items: response.data.content.items, total: response.data.meta.total };
};

export const fetchBuildingSiteDetails = async ({
  accessToken,
  orgid,
  buildingSiteId,
}: {
  accessToken: string;
  orgid: string;
  buildingSiteId: string;
}): Promise<GetBuildingSite> => {
  const response = await instance.get(`${orgid}/building-site/detail/${buildingSiteId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data.content;
};

export const changeBuildingSiteStatus = async ({
  accessToken,
  orgid,
  buildingSiteId,
}: {
  accessToken: string;
  orgid: string;
  buildingSiteId: string;
}): Promise<void> => {
  await instance.get(`${orgid}/building-site/change-activation/${buildingSiteId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const createBuildingSite = async ({
  accessToken,
  orgid,
  payload,
}: {
  accessToken: string;
  orgid: string;
  payload: BuildingSiteSave;
}): Promise<{ uuid: string }> => {
  const response = await instance.post(
    `${orgid}/building-site/add`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};

export const updateBuildingSite = async ({
  accessToken,
  orgid,
  buildingSiteId,
  payload,
}: {
  accessToken: string;
  orgid: string;
  buildingSiteId: string;
  payload: BuildingSiteSave;
}): Promise<void> => {
  await instance.post(
    `${orgid}/building-site/edit/${buildingSiteId}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};
