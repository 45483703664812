import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import { CustomerSave, GetCustomer } from '../../../models/Customer';
import { Title } from '../../../stories/dune/atoms/Title';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { SelectCountry, SelectCountryOption } from '../../forms/SelectCountry';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/useToast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import countryList from '../../Countries';
import Switch from 'react-switch';
import isEmail from 'validator/lib/isEmail';
import stringUtils from '../../../utils/stringUtils';
import { ManageDocument } from '../../forms/ManageDocument';
import moment from 'moment';
import { SelectCustomerType, SelectCustomerTypeOption } from '../../forms/SelectCustomerType';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { createCustomer, fetchCustomerUnpaidAmount, updateCustomer } from '../../../services/customer';
import { roundNumber } from '../../../utils/number';
import { t } from 'i18next';
import useAddContact from '../../../hooks/useAddContact';

interface GeneralCustomerProps {
  customerId?: string;
  customerData?: GetCustomer;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  mode: string;
}

interface IFormInputs {
  label: string;
  externalReference?: string;
  country?: {
    value: string;
    label: string;
  };
  postalCode?: string;
  locality?: string;
  streetAddress: string;
  tvaNumber?: string;
  sendTripToSxd?: boolean;
  customerType: {
    label: string;
    value: string;
  };
  allBuildingSites?: boolean;
  isCashCustomer?: boolean;
  maxUnpaidAmount?: number;
  supplier?: boolean;
  firstname?: string;
  lastname?: string;
  telephone?: string;
  email?: string;
  repExemptionExpiration?: Date;
}

const GeneralCustomer = React.memo(({ customerId, mode, customerData, onConfirm, onCancel }: GeneralCustomerProps) => {
  const history = useNavigate();
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const isEditMode = mode === 'update';

  const [saveCustomer, setSaveCustomer] = useState<CustomerSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [label, setLabel] = useState<string>();
  const [externalReference, setExternalReference] = useState<string>();
  const [tvaNumber, setTvaNumber] = useState<string>();

  const [streetAddress, setStreetAddress] = useState<string>();
  const [locality, setLocality] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [countryOption, setCountryOption] = useState<SelectCountryOption | null>(
    customerData?.address?.country
      ? {
          value: customerData.address.country,
          label: countryList[customerData.address.country],
          data: { value: customerData.address.country, label: countryList[customerData.address.country] },
        }
      : {
          value: 'FR',
          label: countryList['FR'],
          data: { value: 'FR', label: countryList['FR'] },
        },
  );

  const [selectedOptionCustomerType, setSelectedOptionCustomerType] = useState<SelectCustomerTypeOption | null>(
    customerData?.customerType
      ? {
          value: customerData.customerType.id,
          label: customerData.customerType.label,
          data: {
            value: customerData.customerType.id,
            label: customerData.customerType.label,
            data: customerData.customerType,
          },
        }
      : null,
  );
  const [allBuildingSites, setAllBuildingSites] = useState<boolean>(false);
  const [isCashCustomer, setIsCashCustomer] = useState<boolean>(false);

  const [maxUnpaidAmount, setMaxUnpaidAmount] = useState<number>();
  const [unpaidAmount, setUnpaidAmount] = useState<number>();

  const [isExemptFromRepTax, setIsExemptFromRepTax] = useState<boolean>(false);
  const [repExemptionExpiration, setRepExemptionExpiration] = useState<Date>();

  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [telephone, setTelephone] = useState<string>();
  const [email, setEmail] = useState<string>();

  const [customerDataCustom, setCustomerDataCutom] = useState<DataCustomItem>({});

  const schema = yup.object().shape({
    label: yup.string().required('Le nom est obligatoire'),
    streetAddress: yup.string().required("L'adresse est obligatoire"),
    firstname: yup.string().when(['lastname', 'telephone', 'email'], ([lastname, telephone, email], schema) => {
      if (lastname?.length > 0 || telephone?.length > 0 || email?.length > 0) {
        return schema.required('Le prénom doit également être rempli');
      }
      return schema.notRequired();
    }),
    email: yup.string().test('email-validator', 'Doit être une adresse email valide', (value) => {
      return (value ?? '').length === 0 || isEmail(value ?? '');
    }),
  }); // on utilise un validateur de 'validator.js' pour correspondre au back

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (customerData) {
      // on initialise SaveCustomer pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveCustomer({
        ...customerData,
      });

      setIsActive(customerData?.isActive ?? true);

      setLabel(customerData?.label);
      setValue('label', customerData?.label ?? '');
      setExternalReference(customerData?.externalReference);
      setTvaNumber(customerData?.tvaNumber);

      setStreetAddress(customerData?.address?.streetAddress);
      setValue('streetAddress', customerData?.address?.streetAddress ?? '');
      setLocality(customerData?.address?.locality);
      setPostalCode(customerData?.address?.postalCode);
      if (customerData?.address?.country)
        setCountryOption({
          value: customerData?.address?.country,
          label: countryList[customerData?.address?.country],
        });

      if (customerData?.customerType)
        setSelectedOptionCustomerType({
          value: customerData?.customerType.id,
          label: customerData?.customerType.label,
        });
      setAllBuildingSites(customerData?.allBuildingSites ?? false);
      setIsCashCustomer(customerData?.isCashCustomer ?? false);

      setMaxUnpaidAmount(customerData?.maxUnpaidAmount);

      setIsExemptFromRepTax(customerData?.isExemptFromRepTax ?? false);
      setRepExemptionExpiration(customerData?.repExemptionExpiration);

      setCustomerDataCutom(customerData?.dataCustom as any);

      fetchUnpaidAmount();
    }
  }, [customerData]);

  const onSubmit = async () => {
    try {
      if (!token || !orgid) {
        return;
      }

      const payload: CustomerSave = stringUtils.formatFieldsForPost({
        ...saveCustomer,
        label,
        allBuildingSites,
        isCashCustomer,
        maxUnpaidAmount,
        externalReference,
        tvaNumber,
        locality,
        postalCode,
        streetAddress,
        isExemptFromRepTax,
        repExemptionExpiration,
        isActive,
      });

      if (isEditMode && customerId) {
        await updateCustomer({
          orgid,
          accessToken: token,
          customerId,
          payload,
        });
      } else {
        const { uuid } = await createCustomer({ orgid, accessToken: token, payload });
        addContact(uuid);
        onConfirm(uuid);
      }

      addToast(t('common.customer' + (isEditMode ? 'Updated' : 'Created')), ToastTypes.success);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  };

  async function fetchUnpaidAmount() {
    try {
      if (!token || !orgid || !isEditMode || !customerId) {
        return;
      }

      const unpaidAmount = await fetchCustomerUnpaidAmount({ accessToken: token, orgid, customerId });
      setUnpaidAmount(unpaidAmount.unpaidAmountVat ?? 0);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  }

  const addContact = useAddContact({ email, firstname, lastname, telephone });

  function handleRepDocumentUpdated() {
    if (!repExemptionExpiration) {
      const newDate = new Date();
      newDate.setFullYear(newDate.getFullYear() + 1);
      setRepExemptionExpiration(newDate);
    }
  }

  return !customerData && mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.generalInformations')} type='title1' />

            {mode === 'update' && (
              <FormRow align='right' alignVert='center'>
                <Switch
                  onChange={function () {
                    setIsActive((prevValue) => !prevValue);
                  }}
                  checked={isActive ?? false}
                  onColor={'#2a85ff'}
                />
                <h3 className='base2'>{t('common.active')}</h3>
              </FormRow>
            )}
          </FormRow>
          <FormRow>
            <Input
              {...register('label')}
              error={errors.label?.message ?? ''}
              label={t('common.companyName')}
              type='text'
              value={label ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('label', e.toString());
                setLabel(e.toString());
              }}
            />
            <Input
              {...register('externalReference')}
              error={errors.externalReference?.message ?? ''}
              label={t('common.yourReference')}
              type='text'
              value={externalReference ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setExternalReference(e.toString());
              }}
            />
            <Input
              {...register('tvaNumber')}
              error={errors.tvaNumber?.message ?? ''}
              label={t('common.tvaNumber')}
              type='text'
              value={tvaNumber ?? ''}
              placeholder='FRXX999999999'
              disabled={false}
              onChange={function (e): void {
                setTvaNumber(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('streetAddress')}
              error={errors.streetAddress?.message ?? ''}
              label={t('common.address')}
              type='text'
              value={streetAddress ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('streetAddress', e.toString());
                setStreetAddress(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('locality')}
              error={errors.locality?.message ?? ''}
              label={t('common.locality')}
              type='text'
              value={locality ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setLocality(e.toString());
              }}
            />
            <Input
              {...register('postalCode')}
              error={errors.postalCode?.message ?? ''}
              label={t('common.postalCode')}
              type='text'
              value={postalCode ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setPostalCode(e.toString());
              }}
            />
            <SelectCountry
              register={register}
              registerName='country'
              setValue={setValue}
              error={errors.country?.value?.message ?? errors.country?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCountryOption) => {
                setSaveCustomer((prevValue) => {
                  return { ...prevValue, country: e?.value };
                });
              }}
              forceSelectedOption={countryOption}
              titleSize='normal'
            />
          </FormRow>

          <FormRow alignVert='center'>
            <SelectCustomerType
              titleSize='normal'
              titleOverride={t('common.customerType')}
              register={register}
              registerName='customerType'
              setValue={setValue}
              error={errors.customerType?.value?.message ?? errors.customerType?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCustomerTypeOption) => {
                if (e && e.value) {
                  setValue('customerType', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveCustomer((prevValue) => {
                    return { ...prevValue, customerTypeId: e?.value };
                  });
                } else {
                  setValue('customerType', {
                    value: '',
                    label: '',
                  });

                  setSaveCustomer((prevValue) => {
                    return { ...prevValue, customerTypeId: '' };
                  });
                }
              }}
              forceSelectedOption={selectedOptionCustomerType}
            />
            {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
            <div className='panelInput' title={t('common.isCashCustomerDesc')}>
              <div>
                <Title type={'label'} align='left' label={t('common.isCashCustomer')} />
                <Switch
                  className='base2'
                  type='text'
                  checked={isCashCustomer}
                  onChange={(checked: boolean) => setIsCashCustomer(checked)}
                  onColor={'#2a85ff'}
                />
              </div>
            </div>
            {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
            <div className='panelInput' title={t('common.allBuildingSitesDesc')}>
              <div>
                <Title type={'label'} align='left' label={t('common.allBuildingSites')} />
                <Switch
                  className='base2'
                  type='text'
                  checked={allBuildingSites}
                  onChange={(checked: boolean) => setAllBuildingSites(checked)}
                  onColor={'#2a85ff'}
                />
              </div>
            </div>
          </FormRow>
        </div>

        <div className='form-section'>
          <FormRow>
            <Title label={t('common.embankment')} type='title1' />
          </FormRow>

          <FormRow>
            <Input
              {...register('maxUnpaidAmount')}
              error={errors.maxUnpaidAmount?.message ?? ''}
              label={t('common.maxUnpaidAmount')}
              hoverDescription={t('common.maxUnpaidAmountDesc')}
              type='number'
              value={roundNumber(maxUnpaidAmount ?? 0, 2)}
              placeholder=''
              disabled={false}
              onChange={function (newValue: string | number): void {
                setValue('maxUnpaidAmount', roundNumber((newValue as number) ?? 0, 2));
                setMaxUnpaidAmount(roundNumber((newValue as number) ?? 0, 2));
              }}
              suffix={'€'}
              number={{ min: 0, max: 10000000, step: 10 }}
            />
            <Input
              error={''}
              label={t('common.unpaidAmount')}
              hoverDescription={t('common.unpaidAmountDesc')}
              type={'number'}
              value={unpaidAmount ? roundNumber(unpaidAmount ?? 0, 2) : ''}
              placeholder=''
              disabled={true}
              onChange={() => {
                return undefined;
              }}
              suffix={'€'}
              number={{ min: 0, max: 10000000, step: 10 }}
            />
          </FormRow>
        </div>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.repTax')} type='title1' />
          </FormRow>
          <FormRow>
            {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
            <div className='panelInput' title={t('common.customerIsExemptFromRepTaxDesc')}>
              <Switch
                className='base2'
                type='text'
                checked={isExemptFromRepTax}
                onChange={(checked: boolean) => setIsExemptFromRepTax(checked)}
                onColor={'#2a85ff'}
              />
              <h3 className='base2' style={{ margin: '0px 10px' }}>
                {t('common.isExemptFromRepTax')}
              </h3>
            </div>
            {isExemptFromRepTax && (
              <div
                title={t('common.clickHereForRepExemptionTemplateDesc')}
                style={{
                  margin: '10px 20px',
                }}
              >
                <a
                  href={'http://download.synaxe.com/tmp/modele%2Dattestation%2Dexoneration%2Dclient%2Epdf'}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('common.clickHereForRepExemptionTemplate')}
                </a>
              </div>
            )}
          </FormRow>
          {isExemptFromRepTax && (
            <FormRow alignVert='center'>
              {mode !== 'add' && (
                // hide document management when adding because we don't have the entity id yet
                <ManageDocument
                  label={t('common.exemptionDocument')}
                  hoverDescription={t('common.exemptionDocumentDesc')}
                  docType={'RepCustomerExemption'}
                  entityType='customer'
                  entityId={customerId ?? ''}
                  onAddDocument={handleRepDocumentUpdated}
                />
              )}
              <Input
                {...register('repExemptionExpiration')}
                error={errors.repExemptionExpiration?.message ?? ''}
                label={t('common.repExemptionExpiration')}
                hoverDescription={t('common.repExemptionExpirationDesc')}
                type='date'
                size='fit'
                value={moment(repExemptionExpiration)?.toISOString()?.substring(0, 10) ?? ''}
                placeholder={new Date().toISOString().split('T')[0]}
                disabled={false}
                onChange={function (e): void {
                  setRepExemptionExpiration(new Date(e.toString()));
                }}
              />
            </FormRow>
          )}
        </div>
        {mode === 'add' && (
          <div className='form-section'>
            <FormRow>
              <Title label={t('common.contact')} type='title1' />
            </FormRow>
            <FormRow>
              <Input
                {...register('lastname')}
                error={errors?.lastname?.message ?? ''}
                label={t('common.lastname')}
                type='text'
                value={lastname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('lastname', e.toString());
                  setLastname(e.toString());
                }}
              />
              <Input
                {...register('firstname')}
                error={errors?.firstname?.message ?? ''}
                label={t('common.firstname')}
                type='text'
                value={firstname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('firstname', e.toString());
                  setFirstname(e.toString());
                }}
              />
            </FormRow>
            <FormRow>
              <Input
                {...register('telephone')}
                error={errors?.telephone?.message ?? ''}
                label={t('common.telephone')}
                type='text'
                value={telephone ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('telephone', e.toString());
                  setTelephone(e.toString());
                }}
              />
              <Input
                {...register('email')}
                error={errors?.email?.message ?? ''}
                label={t('common.email')}
                type='email'
                value={email ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('email', e.toString());
                  setEmail(e.toString());
                }}
              />
            </FormRow>
          </div>
        )}
        <div className='form-section'>
          <BuildDataCustom
            dataCustomType={'Customer'}
            dataCustom={customerDataCustom}
            dataCustomUpdated={(data: DataCustomItem) => {
              setSaveCustomer((prevValue) => {
                return { ...prevValue, dataCustom: data };
              });
            }}
          />
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralCustomer.displayName = 'GeneralCustomer';
export default GeneralCustomer;
