import DataTable from 'react-data-table-component';
import TableStyles from '../../styles/tableStyles';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Input } from '../../stories/dune/atoms/Input';
import { CustomerType, GetCustomerTypeProductPrice } from '../../models/CustomerType';
import { roundNumber } from '../../utils/number';

interface ProductPricesProps {
  product: { id: string; label: string; unit: string };
  priceData?: GetCustomerTypeProductPrice[];
  onChangePrice?: (customerType: CustomerType, price: number) => void;
}

export const ProductPrices = React.memo((props: ProductPricesProps) => {
  const { t } = useTranslation();

  const [priceTable, setPriceTable] = useState<GetCustomerTypeProductPrice[]>([]);

  useEffect(() => {
    // Get all sites from passed productId or get all sites from all products
    const prods: GetCustomerTypeProductPrice[] = props.priceData ?? [];

    const customerTypes: CustomerType[] = JSON.parse(localStorage.getItem('customerTypes') ?? '[]');

    customerTypes.forEach((x) => {
      if (prods.length === 0 || !prods.find((p) => p.customerType.id === x.id))
        prods.push({
          id: '',
          customerType: x,
          product: props.product,
          price: 0,
          vatRate: { label: '', vatRate: 0 },
          isActive: true,
        });
    });

    setPriceTable(
      prods.sort((a, b) => a.customerType.label.localeCompare(b.customerType.label)).sort((a, b) => a.price - b.price),
    );
  }, [props.priceData]);

  const columns: any[] = [
    {
      name: t('common.customerType'),
      selector: (row: GetCustomerTypeProductPrice) => `${row.customerType.label}`,
      cell: (row: GetCustomerTypeProductPrice) => (
        <div className='column-align-left' title={row.customerType.description}>
          <div>
            <strong className='caption2medium'>
              {row.customerType.label + (row.customerType.description ? ' - ' + row.customerType.description : '')}
            </strong>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      omit: false,
      id: 'customerType',
      grow: 1,
    },
    {
      name: t('common.price'),
      selector: (row: GetCustomerTypeProductPrice) => row.price,
      cell: (row: GetCustomerTypeProductPrice) => (
        <div className='column'>
          <div className='firstRow'>
            <Input
              onChange={function (newValue: string | number): void {
                props.onChangePrice?.(row.customerType, newValue as number);
              }}
              error={''}
              value={roundNumber(row.price ?? 0, 4)}
              suffix={'€' + ' / ' + props.product.unit}
              inRow={true}
              type='number'
              number={{ min: 0, max: 100000, step: 0.1 }}
              disabled={false}
              placeholder='3,89'
            />
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'price',
      grow: 2,
    },
  ];
  return (
    <DataTable
      className='table'
      customStyles={TableStyles}
      columns={columns}
      data={priceTable ?? []}
      noDataComponent={<div className='noResults'>{t('common.noCustomerTypes')}</div>}
      sortServer
      paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
      paginationComponentOptions={{
        rowsPerPageText: t('common.perPage'),
        rangeSeparatorText: t('common.onPage'),
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'All',
      }}
    />
  );
});

ProductPrices.displayName = 'ProductPrices';
