import { ProductItemRow } from '../store/productItems-slice';

export const currentCurrency = 'EUR';
export default class PriceUtils {
  constructor(
    private props: {
      showTotal: boolean;
      showOrderedQuantity: boolean;
      showDeliveredQuantity: boolean;
      hideRep: boolean;
    },
  ) { }

  discountValue(row: ProductItemRow, discountMode: '%' | '€') {
    const discPerc = row.discountPercent ?? 0;
    return discPerc * (discountMode === '%' ? 100.0 : this.productUnitPrice(row));
  }

  productQuantity(row: ProductItemRow) {
    let lRet = 0;
    if (this.props.showTotal) {
      if (this.props.showDeliveredQuantity) lRet = row.deliveredQuantity ?? 0;
      else if (this.props.showOrderedQuantity) lRet = row.orderedQuantity ?? 0;
    } else lRet = 1;
    return lRet;
  }

  productTaxableUnitPrice(row: ProductItemRow) {
    return row.itemUnitPrice
      ? row.itemUnitPrice + (this.props.hideRep ? 0 : row.repValue ?? 0) + (row.transportValue ?? 0)
      : 0;
  }

  productUnitPrice(row: ProductItemRow, onlyTaxable = false) {
    return row.itemUnitPrice ? this.productTaxableUnitPrice(row) + (onlyTaxable ? 0 : row.tgapValue ?? 0) : 0;
  }

  totalProductPrice(row: ProductItemRow, onlyTaxable = false) {
    const pricePerc = 1 - (row.discountPercent ?? 0);
    return pricePerc * (this.productUnitPrice(row, onlyTaxable) * this.productQuantity(row));
  }

  transportPrice() {
    return 0;
  }

  totalTransportPrice(row: ProductItemRow) {
    return this.transportPrice() * this.productQuantity(row);
  }

  exVatPrice(row: ProductItemRow) {
    // Le prix du transport est "FRANCO DE PORT", c'est à dire que le prix de transport est inclus dans le prix du produit https://comptabilite.pagesjaunes.fr/astuce/voir/598467/tva-sur-les-frais-de-port
    return (this.totalProductPrice(row) + this.totalTransportPrice(row));
  }

  vatPrice(row: ProductItemRow) {
    // get price only for taxable parts (no TGAP)
    return (
      (this.totalProductPrice(row, true) + this.totalTransportPrice(row)) * (row.vatRate ?? 0)
    );
  }

  inclVatPrice(row: ProductItemRow) {
    return this.exVatPrice(row) + this.vatPrice(row);
  }

  getTotalPrice(data: ProductItemRow[]) {
    return data.reduce((total, item) => total + Math.round(this.inclVatPrice(item) * 100) / 100, 0);
  }

  getTotalExVat(data: ProductItemRow[]) {
    return data.reduce((total, item) => total + Math.round(this.exVatPrice(item) * 100) / 100, 0);
  }

  getTotalVat(data: ProductItemRow[]) {
    return this.getTotalPrice(data) - this.getTotalExVat(data);
  }

  getTotalRepTax(data: ProductItemRow[]) {
    const total = data.reduce((total, item) => total + (item.repValue ?? 0) * (this.productQuantity(item) ?? 0), 0);

    const totalVat = data.reduce(
      (total, item) => total + (item.repValue ?? 0) * (this.productQuantity(item) ?? 0) * (item.vatRate ?? 0),
      0,
    );
    return total + totalVat;
  }

  getTotalTgap(data: ProductItemRow[]) {
    const total = data.reduce((total, item) => total + (item.tgapValue ?? 0) * (this.productQuantity(item) ?? 0), 0);

    const totalVat = 0; // no VAT for TGAP

    return total + totalVat;
  }

  getTotalTransportValue(data: ProductItemRow[]) {
    const total = data.reduce(
      (total, item) =>
        total +
        (item.transportValue ?? item.isTransport ? this.productUnitPrice(item) : 0) * (this.productQuantity(item) ?? 0),
      0,
    );

    const totalVat = data.reduce(
      (total, item) =>
        total +
        (item.transportValue ?? item.isTransport ? this.productUnitPrice(item, true) : 0) *
        (this.productQuantity(item) ?? 0) *
        (item.vatRate ?? 0),
      0,
    );

    return total + totalVat;
  }
}
