import { useState } from 'react';
import { useTabFilters } from './useTabFilters';

interface Pagination {
  page: number;
  perPage: number;
}

export const usePagination = () => {
  const { tabOption } = useTabFilters();
  const newState: Pagination = { page: 1, perPage: 20 };
  const [paginationId, setPaginationId] = useState<string>('pagination' + window.location.href + tabOption);

  const { page: currentPage, perPage }: Pagination =
    (localStorage.getItem(paginationId) ?? '') !== '' ? JSON.parse(localStorage.getItem(paginationId) ?? '') : newState;

  const paginationReset = () => {
    localStorage.setItem(paginationId, JSON.stringify(newState));
  };

  const paginationSetValues = (newPage: number, newPerPage: number) => {
    localStorage.setItem(paginationId, JSON.stringify({ page: newPage, perPage: newPerPage }));
  };

  const changePaginationTabValue = (tab: string): Pagination => {
    setPaginationId('pagination' + window.location.href + tab);

    const pagination: Pagination =
      (localStorage.getItem('pagination' + window.location.href + tab) ?? '') != ''
        ? JSON.parse(localStorage.getItem('pagination' + window.location.href + tab) ?? '')
        : newState;
    return pagination;
  };

  return { currentPage, perPage, paginationId, paginationReset, paginationSetValues, changePaginationTabValue };
};
