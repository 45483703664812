import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useToast from '../../../hooks/useToast';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralQuotation from './GeneralQuotation';
import HistQuotation from './HistQuotation';
import { GetQuotation } from '../../../models/Quotation';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import { useDispatch, useSelector } from 'react-redux';
import { DuplicationState, duplicationActions } from '../../../store/duplication-slice';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { fetchQuotation } from '../../../services/quotation';
import SubMenu from '../../forms/SubMenu';

interface DetailQuotationProps {
  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailQuotation = React.memo((props: DetailQuotationProps) => {
  // #region generic

  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  const dispatch = useDispatch();
  const quotationDuplicateData = useSelector((state: { duplication: DuplicationState }) => state.duplication.quotation);

  type Params = {
    id: string;
    mode: string;
    quotationMode: 'customer' | 'buildingSite';
  };

  const { id, mode, quotationMode: quotationMode } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [quotation, setQuotation] = useState<GetQuotation>();

  async function fetchCurrentQuotation(id?: string) {
    const mode = getMode();

    if (mode === 'add' && quotationDuplicateData) {
      setQuotation(quotationDuplicateData);
      dispatch(duplicationActions.clearDuplicatedData('Quotation'));
      return;
    }

    if (mode === 'update' && id && token && orgid) {
      try {
        setIsLoading(true);
        const quotation = await fetchQuotation({ accessToken: token, orgid, quotationId: id });
        setQuotation(quotation);
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    fetchCurrentQuotation(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <div className='main-container'>
      <Menu selectedPage='quotations'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
      <div className='right detail-container'>
        <Head
          title={
            getMode() === 'add'
              ? t('common.newQuotation')
              : t('common.quotation') +
                ' ' +
                (!quotation
                  ? ''
                  : quotation.quotationNumber +
                    (quotation.externalReference ? '-' + quotation.externalReference : '') +
                    (' - ' + t('status.' + quotation.status)))
          }
          back={true}
          Back={function (): void {
            history('/quotations');
          }}
        />

        {!isLoading && (
          <>
            {getMode() === 'add' && (
              <GeneralQuotation
                mode={getMode()}
                quotationMode={quotationMode}
                quotationType='Quotation'
                quotationData={quotation}
                onCancel={onCanceled}
                onConfirm={onConfirmed}
              />
            )}

            {getMode() !== 'add' && (
              <>
                <SubMenu
                  tab={currentTab}
                  updateTab={(mode: string) => updateCurrentTab(mode)}
                  tabs={[
                    { key: 'general', label: t('common.generalInformations') },
                    { key: 'history', label: t('common.history') },
                    { key: 'documents', label: t('common.documents') },
                  ]}
                />
                {currentTab === 'general' && (
                  <GeneralQuotation
                    id={id}
                    mode={getMode()}
                    quotationMode={quotationMode}
                    quotationType='Quotation'
                    quotationData={quotation}
                    onCancel={onCanceled}
                    onConfirm={onConfirmed}
                    onReload={() => {
                      fetchCurrentQuotation(id);
                    }}
                  />
                )}
                {currentTab === 'history' && (
                  <div className='detail-content'>
                    <HistQuotation quotationData={quotation} />
                  </div>
                )}
                {currentTab === 'documents' && quotation && (
                  <ListAdditionalDocs entityType='quotation' entityId={quotation.id} canAddNew={true} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      if (getMode() === 'add') history('/quotations/' + data + '/update');
      else history('/quotations');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/quotations');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer hideBottomBar={true} isLarge={true}>
          {content}
        </PopupContainer>
      ) : (
        content
      )}
    </>
  );
});

DetailQuotation.displayName = 'DetailQuotation';
export default DetailQuotation;
