import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import axios from 'axios';
import Config from '../../../Config';
import { Link, useNavigate } from 'react-router-dom';
import { Vehicle } from '@dune-manager/backend-core/dist/models';
import { useTabFilters } from '../../../hooks/useTabFilters';
import TabFilter from '../TabFilter';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import useToast from '../../../hooks/useToast';
import { ToastTypes } from '../../../models/ToastTypes';
import { isActiveList } from '../../../models';
import { GetVehicle, GetVehicleParams, GetVehiclePayload } from '../../../models/Vehicle';
import Switch from 'react-switch';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { changeVehicleStatus, fetchVehicles } from '../../../services/vehicle';
import { usePagination } from '../../../hooks/usePagination';

interface ListVehiclesProps {
  canAddNew: boolean;
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
}

const ListVehicles = React.memo((props: ListVehiclesProps) => {
  // #region intializing constants

  // #region generic
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const { currentPage, perPage, paginationSetValues, changePaginationTabValue } = usePagination();
  const defaultTab = props.defaultStatus ?? 'Tous';
  const { filtersTab } = useTabFilters(defaultTab);
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);

  const [sortColumn, setSortColumn] = useState('registrationPlate');
  const [sortDirection, setSortDirection] = useState('asc');
  const [totalRows, setTotalRows] = useState(0);
  const [isActive, setIsActive] = useState<isActiveList>({});
  const [vehicleType, setvehicleType] = useState<any[]>([]);

  // #endregion generic

  // #region specific

  const [filteredItems, setFilteredItems] = useState<GetVehicle[]>([]);
  const getVehicleType = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = orgid + '/vehicle-type/get/';
    axios
      .post(
        Config.getApiExtranetUrl(url),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setvehicleType(res.data.content.items);
      })
      .catch(() => {
        setvehicleType([]);
      });
  };

  const fetchItems = async (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    tabFilters: string[] = filtersTab,
    advancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setIsLoading(true);
      setFilteredItems([]);

      if (newSortColumn != sortColumn) {
        setSortColumn(newSortColumn);
      }

      if (newSortDirection != sortDirection) {
        setSortDirection(newSortDirection);
      }

      const payload = generatePayload({
        tabFilters,
        advancedFilters,
      });

      const params: GetVehicleParams = {
        limit: newPerPage,
        page,
        sortBy: newSortColumn,
        orderBy: newSortDirection,
      };

      const { items, total } = await fetchVehicles({
        accessToken: token,
        orgid,
        payload,
        params,
      });

      setTotalRows(total);
      setFilteredItems(items);

      const activeList = items.reduce<isActiveList>((acc, vehicle) => {
        acc[vehicle.id] = vehicle.isActive;
        return acc;
      }, {});
      setIsActive(activeList);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const generatePayload = ({
    tabFilters,
    advancedFilters,
  }: {
    tabFilters: string[];
    advancedFilters: AdvancedFilter[];
  }) => {
    const payload: GetVehiclePayload = {};

    if (tabFilters && tabFilters?.length > 0) payload.isActive = tabFilters[0] === 'Actif';

    if (advancedFilters.length > 0) {
      advancedFilters.map((filter) => {
        if (filter.filterType === 'number' && filter.filterData.length > 0) payload.search = filter.filterData;
        if (filter.filterType === 'registrationPlate') payload.registrationPlate = filter.filterData;
        if (filter.filterType === 'carrier') payload.carrierId = filter.filterData?.value;
      });
    }

    return payload;
  };

  const seeDetails = (row: GetVehicle) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'vehicles';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.registrationPlate'),
      selector: (row: Vehicle) =>
        (row.trailerPlate ?? null) !== null
          ? `${row.registrationPlate} - ${row.trailerPlate}`
          : `${row.registrationPlate}`,
      cell: (row: Vehicle) => (
        <Link to={seeDetails(row)}>
          <div className='firstColumn' data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.registrationPlate}
            </strong>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'registrationPlate',
    },
    {
      name: t('common.type'),
      selector: (row: any) => row.type?.id,
      cell: (row: any) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <strong className='caption2medium' data-tag='allowRowEvents'>
            {vehicleType.find((f: any) => f.id == row.type?.id)?.label}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'type',
    },
    {
      name: t('common.carrier'),
      selector: (row: any) => row.carrierLabel,
      cell: (row: any) => (
        <div className='column-align-left' data-tag='allowRowEvents'>
          <strong className='caption2medium' data-tag='allowRowEvents'>
            {row.carrierLabel}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'carrierLabel',
    },
    {
      name: t('common.active'),
      selector: (row: Vehicle) => row.isActive,
      cell: (row: Vehicle) => (
        <Switch onChange={onChangeSwitch} checked={isActive[row.id] ?? false} id={row.id} onColor={'#2a85ff'} />
      ),
      sortable: true,
      right: false,
      id: 'isActive',
    },
  ];

  // #endregion specific

  // #endregion intializing constants

  // #region event handling

  useEffect(() => {
    getVehicleType();
  }, []);

  const handlePageChange = (pageNumber: number) => {
    paginationSetValues(pageNumber, perPage);
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    paginationSetValues(1, newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  const onChangeSwitch = async (
    checked: boolean,
    _: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string,
  ) => {
    try {
      if (!token || !orgid) {
        return;
      }
      await changeVehicleStatus({ accessToken: token, orgid, vehicleId: id });
      addToast(t('common.vehicleUpdated'), ToastTypes.success);
      setIsActive((prevState) => ({ ...prevState, [id]: checked }));
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[], resetPagination: boolean) {
    setFiltersAdvanced(newFilters);
    fetchItems(resetPagination ? 1 : currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  function handleSetFiltersStatus(newFilters: string[], tabOption: string) {
    const { page: newPage, perPage: newPerPage } = changePaginationTabValue(tabOption);
    fetchItems(newPage, newPerPage, sortColumn, sortDirection, newFilters, filtersAdvanced);
  }

  // #endregion event handling

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={['registrationPlate', 'carrier']}
        showAddNew={props.canAddNew}
        addNewText={t('common.newVehicle')}
        addNew={() => {
          history('./add');
        }}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['registrationPlate']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />
      <TabFilter
        tabArray={[
          { tabFilter: 'Tous', tabLabelTranslate: t('common.all') },
          { tabFilter: 'Actif', tabLabelTranslate: t('common.active') },
        ]}
        filterSet={handleSetFiltersStatus}
        defaultTab={defaultTab}
      />
      {isLoading ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => history(seeDetails(row))}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noVehicle')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

ListVehicles.displayName = 'ListVehicles';
export default ListVehicles;
