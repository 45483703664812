import { createInstance } from '../Config';
import { GetProduct, GetProductParams, GetProductPayload, ProductSave } from '../models/Product';

const instance = createInstance({ isExtranet: true });

export const fetchProducts = async ({
  accessToken,
  orgid,
  params,
  payload,
}: {
  accessToken: string;
  orgid: string;
  params: GetProductParams;
  payload: GetProductPayload;
}): Promise<{ items: GetProduct[]; total: number }> => {
  const response = await instance.post(
    `${orgid}/product/get`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    },
  );

  return { items: response.data.content.items, total: response.data.meta.total };
};

export const fetchProductDetails = async ({
  accessToken,
  orgid,
  productId,
}: {
  accessToken: string;
  orgid: string;
  productId: string;
}): Promise<GetProduct> => {
  const response = await instance.get(`${orgid}/product/detail/${productId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data.content;
};

export const changeProductStatus = async ({
  accessToken,
  orgid,
  carrierId,
}: {
  accessToken: string;
  orgid: string;
  carrierId: string;
}): Promise<void> => {
  await instance.get(`${orgid}/product/change-activation/${carrierId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const createProduct = async ({
  accessToken,
  orgid,
  payload,
}: {
  accessToken: string;
  orgid: string;
  payload: ProductSave;
}): Promise<{ uuid: string }> => {
  const response = await instance.post(
    `${orgid}/product/add`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};

export const updateProduct = async ({
  accessToken,
  orgid,
  productId,
  payload,
}: {
  accessToken: string;
  orgid: string;
  productId: string;
  payload: ProductSave;
}): Promise<{ uuid: string }> => {
  const response = await instance.post(
    `${orgid}/product/edit/${productId}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};
