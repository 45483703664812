import { createInstance } from '../Config';
import { CustomerSave, GetCustomer, GetCustomerParams, GetCustomerUnpaidAmount } from '../models/Customer';

const instance = createInstance({ isExtranet: true });

export const fetchCustomers = async ({
  accessToken,
  orgid,
  params,
}: {
  accessToken: string;
  orgid: string;
  params: GetCustomerParams;
}): Promise<{ items: GetCustomer[]; total: number }> => {
  const response = await instance.get(`${orgid}/customer/get`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params,
  });

  return { items: response.data.content.items, total: response.data.meta.total };
};

export const fetchCustomerDetails = async ({
  accessToken,
  orgid,
  customerId,
}: {
  accessToken: string;
  orgid: string;
  customerId: string;
}): Promise<GetCustomer> => {
  const response = await instance.get(`${orgid}/customer/detail/${customerId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content;
};

export const changeCustomerStatus = async ({
  accessToken,
  orgid,
  customerId,
}: {
  accessToken: string;
  orgid: string;
  customerId: string;
}): Promise<void> => {
  await instance.get(`${orgid}/customer/change-activation/${customerId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const createCustomer = async ({
  accessToken,
  orgid,
  payload,
}: {
  accessToken: string;
  orgid: string;
  payload: CustomerSave;
}): Promise<{ uuid: string }> => {
  const response = await instance.post(
    `${orgid}/customer/add`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};

export const updateCustomer = async ({
  accessToken,
  orgid,
  customerId,
  payload,
}: {
  accessToken: string;
  orgid: string;
  customerId: string;
  payload: CustomerSave;
}): Promise<void> => {
  await instance.post(
    `${orgid}/customer/edit/${customerId}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const fetchCustomerUnpaidAmount = async ({
  accessToken,
  orgid,
  customerId,
}: {
  accessToken: string;
  orgid: string;
  customerId: string;
}): Promise<GetCustomerUnpaidAmount> => {
  const response = await instance.get(`${orgid}/customer/unpaid-amount/${customerId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content;
};
