import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useToast from '../../../hooks/useToast';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import { GetQuotation, GetQuotationHistory } from '../../../models/Quotation';
import moment from 'moment';
import { SelectStatus } from '../../forms/SelectStatus';
import { GlobalParameter } from '../../../models/GlobalParameter';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { fetchQuotationHistories } from '../../../services/quotation';

interface QuotationHistoriesProps {
  quotationData: GetQuotation;
}
const sortColumn = 'createdAt';
const sortDirection = 'asc';

const QuotationHistories = React.memo((props: QuotationHistoriesProps) => {
  // #region generic
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  // #endregion generic

  // #region specific
  const [filteredItems, setFilteredItems] = useState<GetQuotationHistory[]>([]);

  const columns: any[] = [
    {
      name: t('common.date'),
      selector: (row: GetQuotationHistory) => row.createdAt,
      cell: (row: GetQuotationHistory) => (
        <div className='column'>
          <div>
            <div className='caption2medium'>{moment(row.createdAt).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm')}</div>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'createdAt',
    },
    {
      name: t('common.status'),
      selector: (row: GetQuotationHistory) => row.status,
      cell: (row: GetQuotationHistory) => (
        <div className='column'>
          <SelectStatus
            registerName={''}
            error={''}
            titleSize='none'
            forceSelectedOption={row.status}
            statusType={'quotation'}
            isSelectable={false}
          />
        </div>
      ),
      sortable: false,
      right: false,
      id: 'status',
    },
    {
      name: t('common.updatedBy'),
      selector: (row: GetQuotationHistory) => row.createdBy,
      cell: (row: GetQuotationHistory) => (
        <div className='column'>
          <div>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.createdBy}
            </strong>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'createdBy',
    },
    {
      name: t('common.comment'),
      selector: (row: GetQuotationHistory) => row.comment,
      cell: (row: GetQuotationHistory) => (
        <div className='column'>
          <div>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.comment}
            </strong>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'comment',
    },
  ];

  // #endregion specific

  // #region event handling

  useEffect(() => {
    const fetchItems = async () => {
      try {
        if (!token || !orgid) {
          return;
        }

        setIsLoading(true);
        setFilteredItems([]);

        const items = await fetchQuotationHistories({
          accessToken: token,
          orgid,
          quotationId: props.quotationData.id,
        });

        setFilteredItems(items);
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      } finally {
        setIsLoading(false);
      }
    };

    fetchItems();
  }, []);

  // #endregion event handling

  // TODO DESIGN : revoir l'espacement quand pas de filtre ?

  return (
    <>
      {isLoading ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          className='table'
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noResult')}</div>}
          defaultSortAsc={sortDirection === 'asc'}
          defaultSortFieldId={sortColumn}
          sortServer
        />
      )}
    </>
  );
});

QuotationHistories.displayName = 'QuotationHistories';
export default QuotationHistories;
