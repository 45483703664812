import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import { CarrierSave, GetCarrier } from '../../../models/Carrier';
import { Title } from '../../../stories/dune/atoms/Title';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { SelectCountry, SelectCountryOption } from '../../forms/SelectCountry';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/useToast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import countryList from '../../Countries';
import Switch from 'react-switch';
import isEmail from 'validator/lib/isEmail';
import stringUtils from '../../../utils/stringUtils';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { t } from 'i18next';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { updateCarrier, createCarrier } from '../../../services/carrier';
import useAddContact from '../../../hooks/useAddContact';

interface GeneralCarrierProps {
  carrierId?: string;
  carrierData: GetCarrier | undefined;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  mode: string;
}

interface IFormInputs {
  label: string;
  externalReference?: string;
  country?: {
    value: string;
    label: string;
  };
  postalCode?: string;
  locality?: string;
  streetAddress: string;
  tvaNumber?: string;
  sendTripToSxd?: boolean;
  allBuildingSites?: boolean;
  supplier?: boolean;
  firstname?: string;
  lastname?: string;
  telephone?: string;
  email?: string;
}

const GeneralCarrier = React.memo(({ carrierId, mode, carrierData, onConfirm, onCancel }: GeneralCarrierProps) => {
  const history = useNavigate();
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const isEditMode = mode === 'update';

  const [saveCarrier, setSaveCarrier] = useState<CarrierSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [label, setLabel] = useState<string>();
  const [externalReference, setExternalReference] = useState<string>();
  const [tvaNumber, setTvaNumber] = useState<string>();

  const [streetAddress, setStreetAddress] = useState<string>();
  const [locality, setLocality] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [countryOption, setCountryOption] = useState<SelectCountryOption | null>(
    carrierData?.address?.country
      ? {
          value: carrierData.address.country,
          label: countryList[carrierData.address.country],
          data: { value: carrierData.address.country, label: countryList[carrierData.address.country] },
        }
      : {
          value: 'FR',
          label: countryList['FR'],
          data: { value: 'FR', label: countryList['FR'] },
        },
  );

  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [telephone, setTelephone] = useState<string>();
  const [email, setEmail] = useState<string>();

  const [carrierDataCustom, setCarrierDataCutom] = useState<DataCustomItem>({});

  const schema = yup.object().shape({
    label: yup.string().required('Le nom est obligatoire'),
    streetAddress: yup.string().required("L'adresse est obligatoire"),
    firstname: yup.string().when(['lastname', 'telephone', 'email'], ([lastname, telephone, email], schema) => {
      if (lastname?.length > 0 || telephone?.length > 0 || email?.length > 0) {
        return schema.required('Le prénom doit également être rempli');
      }
      return schema.notRequired();
    }),
    email: yup.string().test('email-validator', 'Doit être une adresse email valide', (value) => {
      return (value ?? '').length === 0 || isEmail(value ?? '');
    }),
  }); // on utilise un validateur de 'validator.js' pour correspondre au back

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (carrierData) {
      // on initialise SaveCarrier pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveCarrier({
        ...carrierData,
      });

      setIsActive(carrierData?.isActive ?? true);

      setLabel(carrierData?.label);
      setValue('label', carrierData?.label ?? '');
      setExternalReference(carrierData?.externalReference);
      setTvaNumber(carrierData?.tvaNumber);

      setStreetAddress(carrierData?.address?.streetAddress);
      setValue('streetAddress', carrierData?.address?.streetAddress ?? '');
      setLocality(carrierData?.address?.locality);
      setPostalCode(carrierData?.address?.postalCode);
      if (carrierData?.address?.country)
        setCountryOption({
          value: carrierData?.address?.country,
          label: countryList[carrierData?.address?.country],
        });
    }
  }, [carrierData]);

  const onSubmit = async () => {
    try {
      if (!token || !orgid) {
        return;
      }

      const payload: CarrierSave = stringUtils.formatFieldsForPost({
        ...saveCarrier,
        label,
        externalReference,
        tvaNumber,
        locality,
        postalCode,
        streetAddress,
        isActive,
      });

      if (isEditMode && carrierId) {
        await updateCarrier({
          orgid,
          accessToken: token,
          carrierId,
          payload,
        });
      } else {
        const { uuid } = await createCarrier({ orgid, accessToken: token, payload });
        addContact(uuid);
        onConfirm(uuid);
      }

      addToast(t('common.carrier' + (mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  };

  const addContact = useAddContact({ email, firstname, lastname, telephone });

  return !carrierData && mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.generalInformations')} type='title1' />

            {mode === 'update' && (
              <FormRow align='right' alignVert='center'>
                <h3 className='base2'>{t('common.active')}</h3>
                <Switch
                  onChange={function () {
                    setIsActive((prevValue) => !prevValue);
                  }}
                  checked={isActive ?? false}
                  onColor={'#2a85ff'}
                />
              </FormRow>
            )}
          </FormRow>
          <FormRow>
            <Input
              {...register('label')}
              error={errors.label?.message ?? ''}
              label={t('common.companyName')}
              type='text'
              value={label ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('label', e.toString());
                setLabel(e.toString());
              }}
            />
            <Input
              {...register('externalReference')}
              error={errors.externalReference?.message ?? ''}
              label={t('common.yourReference')}
              type='text'
              value={externalReference ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setExternalReference(e.toString());
              }}
            />
            <Input
              {...register('tvaNumber')}
              error={errors.tvaNumber?.message ?? ''}
              label={t('common.tvaNumber')}
              type='text'
              value={tvaNumber ?? ''}
              placeholder='FRXX999999999'
              disabled={false}
              onChange={function (e): void {
                setTvaNumber(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('streetAddress')}
              error={errors.streetAddress?.message ?? ''}
              label={t('common.address')}
              type='text'
              value={streetAddress ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('streetAddress', e.toString());
                setStreetAddress(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('locality')}
              error={errors.locality?.message ?? ''}
              label={t('common.locality')}
              type='text'
              value={locality ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setLocality(e.toString());
              }}
            />
            <Input
              {...register('postalCode')}
              error={errors.postalCode?.message ?? ''}
              label={t('common.postalCode')}
              type='text'
              value={postalCode ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setPostalCode(e.toString());
              }}
            />
            <SelectCountry
              register={register}
              registerName='country'
              setValue={setValue}
              error={errors.country?.value?.message ?? errors.country?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCountryOption) => {
                setSaveCarrier((prevValue) => {
                  return { ...prevValue, country: e?.value };
                });
              }}
              forceSelectedOption={countryOption}
              titleSize='normal'
            />
          </FormRow>
        </div>
        {mode === 'add' && (
          <div className='form-section'>
            <FormRow>
              <Title label={t('common.contact')} type='title1' />
            </FormRow>
            <FormRow>
              <Input
                {...register('lastname')}
                error={errors?.lastname?.message ?? ''}
                label={t('common.lastname')}
                type='text'
                value={lastname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('lastname', e.toString());
                  setLastname(e.toString());
                }}
              />
              <Input
                {...register('firstname')}
                error={errors?.firstname?.message ?? ''}
                label={t('common.firstname')}
                type='text'
                value={firstname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('firstname', e.toString());
                  setFirstname(e.toString());
                }}
              />
            </FormRow>
            <FormRow>
              <Input
                {...register('telephone')}
                error={errors?.telephone?.message ?? ''}
                label={t('common.telephone')}
                type='text'
                value={telephone ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('telephone', e.toString());
                  setTelephone(e.toString());
                }}
              />
              <Input
                {...register('email')}
                error={errors?.email?.message ?? ''}
                label={t('common.email')}
                type='email'
                value={email ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('email', e.toString());
                  setEmail(e.toString());
                }}
              />
            </FormRow>
          </div>
        )}
        <div className='form-section'>
          <BuildDataCustom
            dataCustomType={'Carrier'}
            dataCustom={carrierDataCustom}
            dataCustomUpdated={(data: DataCustomItem) => {
              setSaveCarrier((prevValue) => {
                return { ...prevValue, dataCustom: data };
              });
            }}
          />
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralCarrier.displayName = 'GeneralCarrier';
export default GeneralCarrier;
