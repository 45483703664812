import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VatRate } from '../../models/VatRate';
import { useSelector } from 'react-redux';
import { StaticDataState } from '../../store/staticData-slice';
import { GlobalParameter } from '../../models/GlobalParameter';
import { roundNumber } from '../../utils/number';

export interface SelectVatRateOption extends SelectDataOption {
  data: VatRate;
}

export const SelectVatRate = ({
  setValue,
  register,
  registerName = 'vatRate',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectVatRateOption[]>([]);

  const staticData = useSelector((state: { staticData: StaticDataState }) => state.staticData);

  const [defaultOption, setDefaultOption] = useState<SelectVatRateOption | undefined>(undefined);

  const fetchData = () => {
    setIsLoading(true);

    setFetchedOptions(staticData.vatRates);

    setIsLoading(false);
  };

  const setFetchedOptions = (fetchedOptions: VatRate[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label: roundNumber(s.vatRate * 100, 4) + '%' + ' - ' + s.label.replace('Taux ', '') + '',
          data: s,
        };

        options.push(option);
        const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
        const val =
          globalParametersSite.find((x) => x.label === 'siteIsDROM')?.value !== 'true'
            ? staticData.vatRates.find((x) => x.label === 'Taux Normal')
            : staticData.vatRates.find((x) => x.label === 'Taux Normal (DROM)');
        if (val) {
          setDefaultOption({
            value: val.id,
            label: roundNumber(val.vatRate * 100, 4) + '%' + ' - ' + val.label.replace('Taux ', '') + '',
            data: val,
          });
        }
      });
      setOptions(options);
    }
  };

  useEffect(() => {
    fetchData();
  }, [staticData.vatRates]);

  return (
    <SelectData
      title={props.titleOverride ?? t('common.vatRate')}
      placeholder={t('common.vatRateChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription ?? t('common.vatRateDesc')}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption ?? defaultOption}
      filterText={''}
      fetch={fetchData}
      fetchAtLoad={true}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};
