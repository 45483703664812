import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import DetailProduct from '../pages/Products/DetailProduct';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import useAuthGuard from '../../hooks/useAuthGuard';
import useToast from '../../hooks/useToast';
import { GetProduct, GetProductParams, GetProductPayload } from '../../models/Product';
import { fetchProductDetails, fetchProducts } from '../../services/product';
import { handleApiError } from '../../utils/apiErrorHandler';

export interface SelectProductOption extends SelectDataOption {
  data: GetProduct;
}

const generateProductOptions = (products: GetProduct[]) => {
  const options =
    products.map((product) => ({
      value: product.id,
      label: `${product.label}${product.externalReference ? ` - ${product.externalReference}` : ''}`,
      data: product,
    })) || [];

  return options;
};

export const SelectProduct = ({
  setValue,
  register,
  registerName = 'products',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectDataOption[]>([]);
  const [createdOption, setCreatedOption] = useState<SelectProductOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const fetchProductsByQuery = async (filterText: string) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setFilterText(filterText);
      if (filterText.length <= 0) {
        setOptions([]);
        return;
      }
      setIsLoading(true);

      const params: GetProductParams = {
        page: 1,
        limit: 200,
      };

      const payload: GetProductPayload = {
        isActive: true,
        label: filterText,
        externalReference: filterText,
      };

      const { items } = await fetchProducts({
        accessToken: token,
        orgid,
        params,
        payload,
      });

      const options = generateProductOptions(items);
      setOptions(options);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const [showAddPopup, setShowAddPopup] = useState(false);

  const addPopupClosedHandler = async (createdId?: string) => {
    setShowAddPopup(false);

    try {
      if (!token || !orgid || !createdId) {
        return;
      }

      setIsLoading(true);

      const fetchedProductDetails = await fetchProductDetails({
        accessToken: token,
        orgid,
        productId: createdId,
      });

      const { id, label, externalReference } = fetchedProductDetails;

      setCreatedOption({
        value: id,
        label: `${label}${externalReference ? ` - ${externalReference}` : ''}`,
        data: fetchedProductDetails,
      });
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
      generateProductOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showAddPopup && <DetailProduct mode='add' isPopup={true} onClosePopup={addPopupClosedHandler} />}
      <SelectData
        title={t('common.product')}
        placeholder={t('common.productChoose')}
        titleSize={props.titleSize ?? 'large'}
        hoverDescription={props.hoverDescription}
        setValue={setValue}
        register={register}
        registerName={registerName}
        error={error}
        isSelectable={props.isSelectable}
        selectedOptionChanged={selectedOptionChanged}
        clearOnSelect={props.clearOnSelect}
        isLoading={isLoading}
        options={options}
        searchParent={searchParent}
        dependsOnSearchParent={dependsOnSearchParent}
        initialOption={initialOption}
        forceSelectedOption={createdOption ?? forceSelectedOption}
        filterText={filterText}
        fetch={fetchProductsByQuery}
        onClickNoResult={function (): void {
          setShowAddPopup(true);
        }}
        isPopup={isPopup}
        onClosePopup={onClosePopup}
        inRow={props.inRow}
        isClearable={props.isClearable ?? true}
        noResultText={props.noResultTextOverride}
      ></SelectData>
    </>
  );
};
