import { createInstance } from '../Config';
import { CustomerTypeProductPrice, CustomerTypeProductPriceSave } from '../models/CustomerType';

const instance = createInstance({ isExtranet: true });

export const fetchCustomerTypeProductPrices = async ({
  accessToken,
  orgid,
  customerTypeId,
  productId,
}: {
  accessToken: string;
  orgid: string;
  customerTypeId: string | undefined;
  productId: string | undefined;
}): Promise<CustomerTypeProductPrice[]> => {
  const response = await instance.post(
    `${orgid}/customer-type-product-price/get`,
    {
      customerTypeId,
      productId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return response.data.content.items;
};

export const createCustomerTypeProductPrice = async ({
  accessToken,
  orgid,
  payload,
}: {
  accessToken: string;
  orgid: string;
  payload: CustomerTypeProductPriceSave;
}): Promise<CustomerTypeProductPrice[]> => {
  const response = await instance.post(
    `${orgid}/customer-type-product-price/add`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return response.data.content.items;
};

export const updateCustomerTypeProductPrice = async ({
  accessToken,
  orgid,
  priceId,
  payload,
}: {
  accessToken: string;
  orgid: string;
  priceId: string;
  payload: CustomerTypeProductPriceSave;
}): Promise<CustomerTypeProductPrice[]> => {
  const response = await instance.post(
    `${orgid}/customer-type-product-price/edit/${priceId}`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return response.data.content.items;
};
