import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './DetailVehicle.scss';
import Menu from '../../Menu';
import ListContacts from '../../lists/Contacts/ListContacts';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import ListTrips from '../../lists/Trips/ListTrips';
import ListOrders from '../../lists/Orders/ListOrders';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import GeneralVehicle from './GeneralVehicle';
import { Head } from '../../../stories/dune/molecules/Head';
import { GetVehicle, GetVehicleInfo } from '../../../models/Vehicle';
import useToast from '../../../hooks/useToast';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import SubMenu from '../../forms/SubMenu';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { fetchVehicleDetails, fetchVehicleDetailsByRegistrationPlate } from '../../../services/vehicle';
import { handleApiError } from '../../../utils/apiErrorHandler';

interface DetailVehicleProps {
  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailVehicle = React.memo((props: DetailVehicleProps) => {
  // #region generic

  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
  };

  const { id, mode } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [vehicleDetails, setVehicleDetails] = useState<GetVehicle>();
  const [vehicleInfo, setVehicleInfo] = useState<GetVehicleInfo>();

  useEffect(() => {
    const fetchCurrentVehicleDetails = async (id?: string) => {
      if (!id || getMode() !== 'update' || !token || !orgid) {
        return;
      }

      try {
        setIsLoading(true);

        const fetchedVehicleDetails = await fetchVehicleDetails({
          accessToken: token,
          orgid,
          vehicleId: id,
        });
        setVehicleDetails(fetchedVehicleDetails);

        if (!fetchedVehicleDetails || !fetchedVehicleDetails.registrationPlate) {
          return;
        }

        const fetchedVehicleInfo = await fetchVehicleDetailsByRegistrationPlate({
          accessToken: token,
          orgid,
          registrationPlate: fetchedVehicleDetails.registrationPlate ?? '',
        });
        setVehicleInfo(fetchedVehicleInfo);
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrentVehicleDetails(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralVehicle
              mode={getMode()}
              vehicleData={vehicleDetails}
              vehicleInfoData={vehicleInfo}
              onCancel={onCanceled}
              onConfirm={onConfirmed}
            />
          )}

          {getMode() !== 'add' && (
            <>
              <SubMenu
                tab={currentTab}
                updateTab={(mode: string) => updateCurrentTab(mode)}
                tabs={[
                  { key: 'general', label: t('common.generalInformations') },
                  { key: 'contact', label: t('common.contacts') },
                  { key: 'trip', label: t('common.trips') },
                  { key: 'documents', label: t('common.documents') },
                ]}
              />
              {currentTab === 'general' && (
                <GeneralVehicle
                  vehicleId={id}
                  mode={getMode()}
                  vehicleData={vehicleDetails}
                  vehicleInfoData={vehicleInfo}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}
              {currentTab === 'contact' && id && (
                <ListContacts
                  contactEntity={ContactEntity.VEHICLE}
                  entityId={id}
                  canAdd={true}
                  canEdit={true}
                  canRemove={true}
                  canSelect={false}
                />
              )}
              {currentTab === 'trip' && id && (
                <ListTrips
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'registrationPlate',
                      filterData: vehicleDetails?.registrationPlate,
                    },
                  ]}
                />
              )}
              {currentTab === 'order' && id && (
                <ListOrders
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'registrationPlate',
                      filterData: vehicleDetails?.registrationPlate,
                    },
                  ]}
                />
              )}
              {currentTab === 'documents' && vehicleDetails && (
                <ListAdditionalDocs entityType='vehicle' entityId={vehicleDetails.id ?? ''} canAddNew={true} />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/vehicles');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/vehicles');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newVehicle')
              : t('common.vehicle') +
                ' ' +
                (!vehicleDetails
                  ? ''
                  : vehicleDetails.registrationPlate +
                    (vehicleDetails.trailerPlate ? '-' + vehicleDetails.trailerPlate : ''))
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='vehicles'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newVehicle')
                  : t('common.vehicle') + ' ' + (!vehicleDetails ? '' : vehicleDetails.registrationPlate)
              }
              back={true}
              Back={function (): void {
                history('/vehicles');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

DetailVehicle.displayName = 'DetailVehicle';
export default DetailVehicle;
