import React, { useEffect } from 'react';
import './TabFilter.scss';
import { useTabFilters } from '../../hooks/useTabFilters';

export interface TabFilterProps {
  tabArray: {
    tabFilter: string;
    tabLabelTranslate: string;
  }[];
  filterSet?: (val: string[], tab: string) => void;
  defaultTab: string;
  overrideTab?: string;
}

const TabFilter = React.memo((props: TabFilterProps) => {
  const { tabOption, setFiltersTab } = useTabFilters(props.defaultTab);

  const onSendOptions = (option: string) => {
    const filtre = option === 'Tous' ? [] : option.split(';');
    setFiltersTab(filtre, option);
    props.filterSet?.(filtre, option);
  };

  useEffect(() => {
    if (props.overrideTab) onSendOptions(props.overrideTab);
  }, [props.overrideTab]);

  return (
    <div className='tabFilter'>
      <div className='tabFilter__tab'>
        {props.tabArray.map((tab, index) => {
          return (
            <div
              className={
                ((tabOption ?? '') === '' ? props.defaultTab : tabOption) === tab.tabFilter ? 'tab_active' : 'tab'
              }
              key={index}
            >
              <div className='base2' onClick={() => onSendOptions(tab.tabFilter)}>
                {tab.tabLabelTranslate}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

TabFilter.displayName = 'TabFilter';
export default TabFilter;
